import { ExpandItemsIcon } from '../../../../../../assets/icons/ExpandItemsIcon';
import React from 'react';
import Theme from '../../../../../../theme';
import styled from 'styled-components';

export const GroupsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExpandAndCollapseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;

export const ExpandItemsComponent: React.FC = () => {
  return <ExpandItemsIcon color={Theme.colors.expandCollapseButtons.hexCode} />;
};

export const ExpandAndCollapseButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${Theme.colors.expandCollapseButtons.hexCode};
  font-weight: 500;

  background: transparent;
  border: 0;
`;

export const CreateGroupButton = styled.button`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 10px;
  border: 0px;

  border-radius: 8px;
  background: ${Theme.colors.buttonDominant.hexCode};

  color: ${Theme.colors.white.hexCode};
  font-weight: 600;

  :hover {
    opacity: 90%;
  }
`;
