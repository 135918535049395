import {
  MembershipCreateWithoutUserInput,
  MembershipUpdateManyWithWhereWithoutUserInput,
  Role,
  StringFilter,
} from '../../../__generated__/graphql';
import {
  UserAccessType,
  UserAccessTypeEnum,
  UserFormInputs,
  UserFormMembershipType,
  UserFormMembershipTypeEnum,
} from '../../../components/UserForm/@types/UserFormInputs';
import UserFormMembershipMap, {
  UserFormMembership,
} from '../../../components/UserForm/@types/UserFormMembershipMap';

import { ActiveMembership } from '../../../redux/reducers/@types/MeReduxState';

export type GenerateMembershipInputsType = {
  create: MembershipCreateWithoutUserInput[];
  delete: Array<{ id: StringFilter }>;
  update: MembershipUpdateManyWithWhereWithoutUserInput[];
};

type GenerateMembershipInputsProps = {
  locationMemberships: UserFormInputs['locations']['map'];
  groupMemberships: UserFormInputs['groups']['map'];
  tenantMembership: UserFormInputs['tenantMembership'];
  activeMembership: Pick<ActiveMembership, 'tenantId'>;
  role: Role;
  userAccessType: UserAccessType;
};

type GenerateCreateTenantMembershipOptionsProps = Pick<
  GenerateMembershipInputsProps,
  'role' | 'tenantMembership' | 'activeMembership'
>;

function generateCreateTenantMembershipOptions({
  role,
  tenantMembership,
  activeMembership,
}: GenerateCreateTenantMembershipOptionsProps): MembershipCreateWithoutUserInput {
  return {
    tenant: { connect: { id: tenantMembership?.tenantId ?? activeMembership.tenantId } },
    role,
  };
}

type GenerateUpdateTenantMembershipOptionsProps = Pick<
  GenerateMembershipInputsProps,
  'role' | 'tenantMembership'
>;

function generateUpdateTenantMembershipOptions({
  role,
  tenantMembership,
}: GenerateUpdateTenantMembershipOptionsProps): MembershipUpdateManyWithWhereWithoutUserInput {
  return {
    data: {
      role: { set: role },
    },
    where: {
      id: { equals: tenantMembership?.id },
    },
  };
}

function generateLocationMembershipWithoutUserInput(
  membership: UserFormMembership,
  tenantId: string
): MembershipCreateWithoutUserInput {
  return {
    location: {
      connect: {
        id: membership.option.value,
      },
    },
    role: membership.role,
    tenant: {
      connect: {
        id: tenantId,
      },
    },
  };
}

function generateGroupMembershipWithoutUserInput(
  membership: UserFormMembership,
  tenantId: string
): MembershipCreateWithoutUserInput {
  return {
    locationGroup: {
      connect: {
        id: membership.option.value,
      },
    },
    role: membership.role,
    tenant: {
      connect: {
        id: tenantId,
      },
    },
  };
}

type ManageGroupAndLocationsMembershipsProps = {
  memberships: UserFormMembershipMap;
  membershipType: UserFormMembershipType;
  currentValues: GenerateMembershipInputsType;
  activeTenantId: string;
};

function manageGroupAndLocationsMemberships({
  currentValues,
  membershipType,
  memberships,
  activeTenantId,
}: ManageGroupAndLocationsMembershipsProps): void {
  const {
    create: membershipsToCreate,
    delete: membershipsToDelete,
    update: membershipsToUpdate,
  } = currentValues;

  Object.values(memberships).forEach((membership) => {
    const id = membership.membershipId;

    if (membership.shouldCreate) {
      if (membershipType === UserFormMembershipTypeEnum.locationsMembership) {
        membershipsToCreate.push(
          generateLocationMembershipWithoutUserInput(membership, activeTenantId)
        );
      } else if (membershipType === UserFormMembershipTypeEnum.groupsMembership) {
        membershipsToCreate.push(
          generateGroupMembershipWithoutUserInput(membership, activeTenantId)
        );
      }
    } else if (membership.shouldDelete) {
      if (id) {
        membershipsToDelete.push({ id: { equals: id } });
      }
    } else if (membership.shouldUpdate) {
      if (id) {
        membershipsToUpdate.push({
          data: {
            role: { set: membership.role },
          },
          where: {
            id: { equals: id },
          },
        });
      }
    }
  });
}

export default function generateMembershipInputs({
  tenantMembership,
  groupMemberships,
  locationMemberships,
  role,
  activeMembership,
  userAccessType,
}: GenerateMembershipInputsProps): GenerateMembershipInputsType {
  const membershipsToCreate: GenerateMembershipInputsType['create'] = [];
  let membershipsToDelete: GenerateMembershipInputsType['delete'] = [];
  const membershipsToUpdate: GenerateMembershipInputsType['update'] = [];

  const { tenantId: activeTenantId } = activeMembership;

  if (userAccessType === UserAccessTypeEnum.fullAccess) {
    // Create the tenant full access membership if doesn't exist
    if (!tenantMembership) {
      membershipsToCreate.push(
        generateCreateTenantMembershipOptions({ tenantMembership, activeMembership, role })
      );
    } else if (tenantMembership.role !== role) {
      membershipsToUpdate.push(generateUpdateTenantMembershipOptions({ tenantMembership, role }));
    }

    // Remove the group memberships
    const deletableGroupMemberships = Object.values(groupMemberships).filter((groupMembership) =>
      Boolean(groupMembership.membershipId)
    );

    // Remove the location memberships
    const deletableLocationMemberships = Object.values(
      locationMemberships
    ).filter((locationMembership) => Boolean(locationMembership.membershipId));

    membershipsToDelete = [
      ...membershipsToDelete,
      ...deletableGroupMemberships.map((m) => ({ id: { equals: m.membershipId } })),
      ...deletableLocationMemberships.map((m) => ({ id: { equals: m.membershipId } })),
    ];
  } else if (userAccessType === UserAccessTypeEnum.perMembership) {
    // Remove the tenant full access membership if does exist
    if (tenantMembership) {
      membershipsToDelete.push({ id: { equals: tenantMembership.id } });
    }

    // Create / update / remove the group memberships
    manageGroupAndLocationsMemberships({
      activeTenantId,
      memberships: groupMemberships,
      membershipType: UserFormMembershipTypeEnum.groupsMembership,
      currentValues: {
        create: membershipsToCreate,
        delete: membershipsToDelete,
        update: membershipsToUpdate,
      },
    });

    // Create / update / remove the location memberships
    manageGroupAndLocationsMemberships({
      activeTenantId,
      memberships: locationMemberships,
      membershipType: UserFormMembershipTypeEnum.locationsMembership,
      currentValues: {
        create: membershipsToCreate,
        delete: membershipsToDelete,
        update: membershipsToUpdate,
      },
    });
  }

  return {
    create: membershipsToCreate,
    delete: membershipsToDelete,
    update: membershipsToUpdate,
  };
}
