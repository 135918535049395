/* eslint-disable no-param-reassign */
import { GroupType } from '../useLocationGroupsTree';
import { LocationGroupData } from './types';
import { cloneDeep } from 'lodash';

type GroupWithParentGroupIds = GroupType & Pick<LocationGroupData, 'parentGroupIds'>;

/** Recursively delete the parentGroupIds property */
function removeParentGroupIds(groups: GroupWithParentGroupIds[]): void {
  groups.forEach((group) => {
    delete group.parentGroupIds;

    if (group.groups) {
      removeParentGroupIds(group.groups);
    }
  });
}

/** Recursively sort the inner groups and locations */
function sortGroupsAndLocations(groups: GroupType[]): void {
  groups.sort((gA, gB) => gA.name.localeCompare(gB.name));

  groups?.forEach((group) => {
    if (group.locations) {
      group.locations.sort((lA, lB) => lA.name.localeCompare(lB.name));
    }

    if (group.groups) {
      group.groups.sort((gA, gB) => gA.name.localeCompare(gB.name));

      sortGroupsAndLocations(group.groups);
    }
  });
}

/** This function receives a flat list (LocationGroupData[]) and transform
 * it into a tree (GroupType[]) */
export function createLocationGroupsTreeBasedOnFlatList(_groups: LocationGroupData[]): GroupType[] {
  const groups = cloneDeep(_groups);

  let finalGroups = groups.map(
    (group): GroupWithParentGroupIds => ({
      id: group.id,
      name: group.name,
      locations: group.locations ?? [],
      groups: [],
      parentGroupIds: group.parentGroupIds,
    })
  );

  const childGroups = finalGroups.filter(
    (group) => group.parentGroupIds && group.parentGroupIds.length > 0
  );

  childGroups.forEach((childGroup) => {
    const parentsIds = childGroup.parentGroupIds;

    parentsIds?.forEach((parentId) => {
      const parentGroup = finalGroups.find((group) => group.id === parentId);
      if (parentGroup) {
        parentGroup.groups.push(childGroup);
      }
    });
  });

  // Remove the child groups from the tree root
  finalGroups = finalGroups.filter(
    (group) => !group.parentGroupIds || group?.parentGroupIds?.length === 0
  );

  removeParentGroupIds(finalGroups);
  sortGroupsAndLocations(finalGroups);

  return finalGroups;
}
