import 'styled-components/macro';

import KioskLiveUsageCardStyle, {
  KioskLiveUsageCardHeaderStyle,
  KioskLiveUsageCardKioskNameStyle,
  KioskLiveUsageCardStatusActiveStyle,
  KioskLiveUsageCardStatusIdleStyle,
  KioskLiveUsageCardStatusStyle,
  KioskLiveUsagePreBookedPackagesContainerStyle,
  KioskLiveUsagePreBookedPackagesHeaderStyle,
  KioskLiveUsagePreBookedPackagesStyle,
  KioskLiveUsageReservation1stColumnInfoStyle,
  KioskLiveUsageReservationConfirmationNumberStyle,
  KioskLiveUsageReservationContainerInfoStyle,
  KioskLiveUsageReservationContainerRowStyle,
  KioskLiveUsageReservationInfoStyle,
} from './styles/KioskLiveUsageCard.style';
import { getTotalAdultsLabel, getTotalKidsLabel } from './util/getTotalGuestsPlural';

import Container from '../../../../../components/Container';
import { GuestEngagementComponent } from './GuestEngagementComponent';
import { KioskLiveUsageData } from '../../../../../__generated__/graphql';
import { Link } from 'react-router-dom';
import React from 'react';
import TextContent from '../../../../../components/TextContent';
import links from '../../../../../util/links';
import useDateTimeFormatter from '../../../../../hooks/useDateTimeFormatter';

type KioskLiveUsageCardProps = {
  kiosk: KioskLiveUsageData;
};

const KioskCardTitle: React.FC<KioskLiveUsageCardProps> = ({ kiosk }: KioskLiveUsageCardProps) => {
  const kioskStatus = kiosk.accessGrant ? 'Active' : 'Idle';
  const kioskStatusStyle: React.CSSProperties = kiosk.accessGrant
    ? KioskLiveUsageCardStatusActiveStyle
    : KioskLiveUsageCardStatusIdleStyle;

  return (
    <div style={KioskLiveUsageCardHeaderStyle}>
      <Link to={`${links.paths.KIOSKS}/${kiosk.kiosk.id}`} style={KioskLiveUsageCardKioskNameStyle}>
        {kiosk.kiosk.name}
      </Link>
      <TextContent style={{ ...KioskLiveUsageCardStatusStyle, ...kioskStatusStyle }}>
        {kioskStatus}
      </TextContent>
    </div>
  );
};

const ReservationInfo: React.FC<KioskLiveUsageCardProps> = ({ kiosk }: KioskLiveUsageCardProps) => {
  const { formatDate, formatTime } = useDateTimeFormatter();

  if (!kiosk.accessGrant) {
    return <></>;
  }

  return (
    <div style={KioskLiveUsageReservationContainerRowStyle}>
      <Link
        to={`${links.paths.ACCESS_GRANTS}/${kiosk.accessGrant.id}`}
        style={{
          ...KioskLiveUsageReservationInfoStyle,
          ...KioskLiveUsageReservation1stColumnInfoStyle,
          ...KioskLiveUsageReservationConfirmationNumberStyle,
        }}
      >
        {kiosk.accessGrant.confirmationCode}
      </Link>
      <TextContent style={KioskLiveUsageReservationInfoStyle}>
        {formatDate(kiosk.accessGrant.startsAt, { timezone: kiosk.kiosk.location.timezone })}
      </TextContent>
      <TextContent style={KioskLiveUsageReservationInfoStyle}>
        {formatTime(kiosk.accessGrant.startsAt, { timezone: kiosk.kiosk.location.timezone })}
      </TextContent>
    </div>
  );
};

const GuestsInfo: React.FC<KioskLiveUsageCardProps> = ({ kiosk }: KioskLiveUsageCardProps) => {
  if (!kiosk.accessGrant) {
    return <></>;
  }

  return (
    <div style={KioskLiveUsageReservationContainerRowStyle}>
      <Link
        to={`${links.paths.USERS}/${kiosk.accessGrant.user.id}`}
        style={{
          ...KioskLiveUsageReservationInfoStyle,
          ...KioskLiveUsageReservation1stColumnInfoStyle,
        }}
      >
        {kiosk.accessGrant.user.name}
      </Link>
      <TextContent style={KioskLiveUsageReservationInfoStyle}>
        {kiosk.accessGrant.totalGuests.adults}{' '}
        {getTotalAdultsLabel(kiosk?.accessGrant?.totalGuests?.adults ?? undefined)}
      </TextContent>
      <TextContent style={KioskLiveUsageReservationInfoStyle}>
        {kiosk.accessGrant.totalGuests.children}{' '}
        {getTotalKidsLabel(kiosk.accessGrant.totalGuests.children ?? undefined)}
      </TextContent>
    </div>
  );
};

const PrebookedPackagesInfo: React.FC<KioskLiveUsageCardProps> = ({
  kiosk,
}: KioskLiveUsageCardProps) => {
  if (!kiosk.accessGrant) {
    return <></>;
  }

  return (
    <div>
      <TextContent style={KioskLiveUsagePreBookedPackagesHeaderStyle}>
        Pre-booked packages
      </TextContent>
      <div
        style={{
          ...KioskLiveUsageReservationContainerRowStyle,
          ...KioskLiveUsageReservationContainerInfoStyle,
          ...KioskLiveUsagePreBookedPackagesContainerStyle,
        }}
      >
        {kiosk.accessGrant.bookedPackages.map((bookedPackage) => (
          <TextContent
            key={`${bookedPackage.quantity}-${bookedPackage.title}`}
            style={KioskLiveUsagePreBookedPackagesStyle}
          >
            {bookedPackage.title} ({bookedPackage.originalQuantity})
          </TextContent>
        ))}
      </div>
    </div>
  );
};

const AccessGrantInfo: React.FC<KioskLiveUsageCardProps> = ({ kiosk }: KioskLiveUsageCardProps) => {
  return (
    <div style={KioskLiveUsageReservationContainerInfoStyle}>
      <ReservationInfo kiosk={kiosk} />
      <GuestsInfo kiosk={kiosk} />
      <PrebookedPackagesInfo kiosk={kiosk} />
    </div>
  );
};

const KioskLiveUsageCard: React.FC<KioskLiveUsageCardProps> = ({
  kiosk,
}: KioskLiveUsageCardProps) => {
  return (
    <Container style={KioskLiveUsageCardStyle}>
      <KioskCardTitle kiosk={kiosk} />
      <AccessGrantInfo kiosk={kiosk} />
      <GuestEngagementComponent kiosk={kiosk} />
    </Container>
  );
};

export default KioskLiveUsageCard;
