import React, { useEffect } from 'react';

import CreateUserFormInputs from './@types/CreateUserFormInputs';
import EditUserFormInputs from './@types/EditUserFormInputs';
import FormTemplate from '../../templates/Form';
import { Mutation } from '../../__generated__/graphql';
import { RootState } from '../../redux/reducers';
import USER_FORM_STEPS from './constants/userFormSteps';
import USER_VALIDATION_SCHEMA from './constants/userValidationSchema';
import generateEditUserValidationSchema from './functions/generateEditUserValidationSchema';
import { getInitialValues } from './functions/getInitialValues';
import useGetUserById from '../../hooks/useGetUserById';
import { useSelector } from 'react-redux';
import userIsAbleToSetPassword from './functions/userIsAbleToSetPassword';

type CreateUserData = Pick<Mutation, 'createUser'>;

const UserForm: React.FC<{}> = () => {
  const loggedUser = useSelector((state: RootState) => state.me);
  const id = useSelector((state: RootState) => state.modals.form.id);

  const { called, data: user, query } = useGetUserById();

  const activeMembership = useSelector((state: RootState) => state.me.activeMembership)!;

  useEffect(() => {
    if (id && !called) {
      query(id);
    }
  }, [id, called, query]);

  if (id && !user) {
    return <></>;
  }

  if (user) {
    const initialValues = getInitialValues({ user, activeMembership });

    const EDIT_USER_VALIDATION_SCHEMA = generateEditUserValidationSchema({
      isAbleToEditPassword: userIsAbleToSetPassword({
        currentUser: loggedUser,
        editingUserId: id,
        userRole: initialValues.role,
      }),
    });

    return (
      <FormTemplate<EditUserFormInputs, CreateUserData>
        formHeader="Edit User"
        identifier={id}
        steps={USER_FORM_STEPS}
        validationSchema={EDIT_USER_VALIDATION_SCHEMA}
        initialValues={initialValues}
      />
    );
  }

  return (
    <FormTemplate<CreateUserFormInputs, CreateUserData>
      formHeader="Create User"
      initialValues={{
        locations: {
          map: {},
          values: [],
        },
        groups: {
          map: {},
          values: [],
        },
      }}
      steps={USER_FORM_STEPS}
      validationSchema={USER_VALIDATION_SCHEMA}
    />
  );
};

export default React.memo(UserForm);
