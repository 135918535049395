import { Colors, RGB } from '../../../theme/colors';

import Modal from 'react-modal';
import styled from 'styled-components';

const ConfirmationModalStyle: Modal.Styles = {
  content: {
    alignSelf: 'center',
    border: 0,
    bottom: 'unset',
    boxShadow: `0px 10px 32px 0px rgba(${RGB.neroDark}, 0.21)`,
    gridColumn: '3 / -3',
    justifySelf: 'center',
    left: 'unset',
    padding: '32px',
    right: 'unset',
    top: '250px',
    width: '500px',
    zIndex: 999,
    borderRadius: '12px',
  },
  overlay: {
    background: 'transparent',
    display: 'grid',
    gridColumnGap: '25px',
    gridTemplateColumns: 'repeat(12, 1fr)',
    zIndex: 999,
  },
};

export const ConfirmationModalContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 20px;
`;

export const ConfirmationModalMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ConfirmationModalTitleText = styled.p`
  color: ${Colors.freeSpeechRed};
  font-size: 18px;
  font-weight: 700;
`;

export const ConfirmationModalMessageText = styled.p`
  font-size: 16px;
`;

export const ConfirmationModalActionsContainer = styled.div`
  display: flex;
  gap: 20px;

  button {
    color: ${Colors.neroDark};

    &:hover {
      .no-icon {
        color: ${Colors.freeSpeechRed};
      }

      .yes-icon {
        color: ${Colors.mountainMeadow};
      }
    }
  }
`;

export default ConfirmationModalStyle;
