import 'styled-components/macro';

import { ControlProps, GroupBase, components } from 'react-select';

import { MembershipLevelIcon } from '../MembershipLevelIcon';
import { MembershipSelectorOptionType } from '../../types';
import React from 'react';

export const MembershipSelectorControl = (
  props: ControlProps<MembershipSelectorOptionType, false, GroupBase<MembershipSelectorOptionType>>
): React.ReactElement => {
  const selectedValue = props.getValue()[0];

  const membershipLevelIcon = MembershipLevelIcon({
    isSelected: false,
    selectorProps: props,
    option: selectedValue,
  });

  return (
    <components.Control {...props}>
      {membershipLevelIcon}
      {props.children}
    </components.Control>
  );
};
