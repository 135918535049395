import {
  IterateOverLocationsGroupCallbackProps,
  iterateOverLocationsGroup,
} from './locationsGroupTreeUtils';
import { useEffect, useState } from 'react';

import { ExtendedGroupType } from './types';
import { useLocationsGroups } from '../useLocationsGroups/useLocationGroups';

export type UseLocationsGroupsType = {
  data: ExtendedGroupType[] | undefined;
  loading: boolean;
  query: () => void;
  toggleGroupTree: (groupId: string) => void;
  toggleAll: (displayList: boolean) => void;
};

export const useLocationsGroupsTree = (): UseLocationsGroupsType => {
  const [extendedData, setExtendedData] = useState<ExtendedGroupType[]>([]);

  const { query, data: locationGroupData, loading } = useLocationsGroups();

  const assignDisplayListToGroups = (
    groups: ExtendedGroupType[],
    displayList: boolean,
    forceUpdate: boolean
  ): ExtendedGroupType[] => {
    iterateOverLocationsGroup(
      groups,
      (group): IterateOverLocationsGroupCallbackProps => {
        if (group.displayList === undefined || forceUpdate) {
          // eslint-disable-next-line no-param-reassign
          group.displayList = displayList;
        }
      }
    );

    return groups;
  };

  useEffect(() => {
    if (!locationGroupData) {
      return;
    }

    const newData = assignDisplayListToGroups(
      locationGroupData as ExtendedGroupType[],
      true,
      false
    );

    setExtendedData(newData);
  }, [locationGroupData]);

  const findGroup = (groupId: string): ExtendedGroupType | undefined => {
    let valueFound: ExtendedGroupType | undefined;

    iterateOverLocationsGroup(
      extendedData,
      (group): IterateOverLocationsGroupCallbackProps => {
        if (group.id === groupId) {
          valueFound = group;
          return { stopLoop: true };
        }

        return { stopLoop: false };
      }
    );

    return valueFound;
  };

  const toggleGroupTree = (groupId: string): void => {
    if (!extendedData) {
      return;
    }

    const groupFound = findGroup(groupId);

    if (groupFound) {
      groupFound.displayList = !groupFound.displayList;

      const newData = [...extendedData];
      setExtendedData(newData);
    }
  };

  const toggleAll = (displayList: boolean): void => {
    assignDisplayListToGroups(extendedData, displayList, true);

    const newData = [...extendedData];
    setExtendedData(newData);
  };

  return {
    data: extendedData,
    toggleGroupTree,
    toggleAll,
    query,
    loading,
  };
};
