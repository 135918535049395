import React from 'react';
import Theme from '../../theme';

interface SVGProps {
  color?: string;
  style?: React.CSSProperties;
}

export const GroupIcon: React.FunctionComponent<SVGProps> = ({
  color = Theme.colors.dark.hexCode,
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 6.66669C5.83333 7.12692 5.46024 7.50002 5 7.50002C4.53976 7.50002 4.16667 7.12692 4.16667 6.66669C4.16667 6.20645 4.53976 5.83335 5 5.83335C5.46024 5.83335 5.83333 6.20645 5.83333 6.66669ZM7.5 6.66669C7.5 8.0474 6.38071 9.16669 5 9.16669C3.61929 9.16669 2.5 8.0474 2.5 6.66669C2.5 5.28598 3.61929 4.16669 5 4.16669C6.38071 4.16669 7.5 5.28598 7.5 6.66669ZM9.16667 14.1667C9.16667 15.0872 8.42047 15.8334 7.5 15.8334C6.57953 15.8334 5.83333 15.0872 5.83333 14.1667C5.83333 13.2462 6.57953 12.5 7.5 12.5C8.42047 12.5 9.16667 13.2462 9.16667 14.1667ZM10.8333 14.1667C10.8333 16.0076 9.34095 17.5 7.5 17.5C5.65905 17.5 4.16667 16.0076 4.16667 14.1667C4.16667 12.3257 5.65905 10.8334 7.5 10.8334C9.34095 10.8334 10.8333 12.3257 10.8333 14.1667Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3332 9.16667C14.7139 9.16667 15.8332 8.04738 15.8332 6.66667C15.8332 5.28595 14.7139 4.16667 13.3332 4.16667C11.9525 4.16667 10.8332 5.28595 10.8332 6.66667C10.8332 8.04738 11.9525 9.16667 13.3332 9.16667ZM13.3332 10.8333C15.6344 10.8333 17.4998 8.96785 17.4998 6.66667C17.4998 4.36548 15.6344 2.5 13.3332 2.5C11.032 2.5 9.1665 4.36548 9.1665 6.66667C9.1665 8.96785 11.032 10.8333 13.3332 10.8333Z"
        fill={color}
      />
    </svg>
  );
};
