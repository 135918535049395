/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

import Container from '../../../Container';

type MembershipContainerProps = {
  enabled: boolean;
};

export const MembershipContainer = styled(Container)<MembershipContainerProps>`
  gap: 25px;
  ${({ enabled }) =>
    !enabled &&
    css`
      opacity: 0.1;
      pointer-events: none;
    `}
`;

export const MembershipSectionContainer = styled(Container)`
  gap: 15px;
`;
