import LogInAction, { LogInActionPayloadType } from './@types/LogInAction';

import LoadMeAction from './@types/LoadMeAction';
import LogOutAction from './@types/LogOutAction';
import MeActionEnum from '../../enums/MeAction.enum';
import SelectMembershipAction from './@types/SelectMembershipAction';

export const login = (user: LogInActionPayloadType): LogInAction => ({
  payload: user,
  type: MeActionEnum.LOG_IN,
});

export const logout = (): LogOutAction => ({
  type: MeActionEnum.LOG_OUT,
});

export const loadMe = (): LoadMeAction => ({
  type: MeActionEnum.LOAD_ME,
});

export const selectMembership = (virtualId: string): SelectMembershipAction => ({
  payload: {
    virtualId,
  },
  type: MeActionEnum.SELECT_MEMBERSHIP,
});

export type MeAction = LoadMeAction | LogInAction | LogOutAction | SelectMembershipAction;
