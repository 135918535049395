import 'styled-components/macro';

import {
  CreateGroupButton,
  ExpandAndCollapseButton,
  ExpandAndCollapseContainer,
  ExpandItemsComponent,
  GroupsHeader,
} from './GroupsDataHeaderStyles';
import React, { useMemo } from 'react';

import { openForm } from '../../../../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import { useLocationsGroupsProvider } from '../../contexts/LocationsGroupsContext';

export const GroupsDataHeader: React.FC = () => {
  const dispatch = useDispatch();

  const { toggleAll, data, loading } = useLocationsGroupsProvider();

  const expandAll = (): void => {
    toggleAll(true);
  };

  const collapseAll = (): void => {
    toggleAll(false);
  };

  const openCreateGroupForm = (): void => {
    dispatch(openForm('locationsGroup'));
  };

  const displayExpandAndCollapse = useMemo(() => {
    if (loading) {
      return false;
    }

    if (!data || data?.length === 0) {
      return false;
    }

    return true;
  }, [data, loading]);

  return (
    <GroupsHeader>
      {displayExpandAndCollapse ? (
        <ExpandAndCollapseContainer>
          <ExpandAndCollapseButton onClick={expandAll}>
            <ExpandItemsComponent />
            Expand All
          </ExpandAndCollapseButton>
          <ExpandAndCollapseButton onClick={collapseAll}>
            <ExpandItemsComponent />
            Collapse All
          </ExpandAndCollapseButton>
        </ExpandAndCollapseContainer>
      ) : (
        <div></div>
      )}
      <CreateGroupButton onClick={openCreateGroupForm}>
        <div>+</div>
        <div>Create Group</div>
      </CreateGroupButton>
    </GroupsHeader>
  );
};
