/* eslint-disable */
import { ConciergeItem, ConciergeTreeViewNode } from '../../__generated__/graphql';
import React, { FC, useCallback, useEffect } from 'react';
import { openForm, openModal } from '../../redux/actions/modals.actions';

import ConciergeTreeExplain from './header/explain';
import ConciergeTreeHeader from './header';
import ModalsReduxState from '../../redux/reducers/@types/ModalsReduxState';
import { NodeModel } from '@minoru/react-dnd-treeview';
import SelectOption from '../../@types/react/SelectOption';
import TreeView from './tree/TreeView';
import styles from './index.module.css';
import useConciergeCrud from './form/hooks/useConciergeCrud';
import useConciergeInfo from './form/hooks/useConciergeInfo';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type TTreeViewNodeValue = Omit<ConciergeTreeViewNode, '__typename'>;
interface IConciergereeProps {
  modals: ModalsReduxState;
}

const ConciergeTree: FC<IConciergereeProps> = ({ modals }) => {
  const { treeData, setTreeData, query } = useConciergeInfo();
  const { locationId } = useParams<{ locationId: string }>();
  const { updateConciergeOrder } = useConciergeCrud();
  const dispatch = useDispatch();

  const getAvailableCategories = useCallback((): Array<SelectOption> => {
    return treeData.map(({ id, text }) => {
      return { label: text, value: id.toString() };
    });
  }, [treeData]);

  const createConciergeItem = useCallback(() => {
    dispatch(openForm('concierge'));
    dispatch(openModal('concierge' as keyof ModalsReduxState));
  }, [dispatch]);

  const editConciergeItem = useCallback(
    (id: string, data: ConciergeItem & { parentCategory?: SelectOption }) => {
      dispatch(openForm('concierge', id, data));
      dispatch(openModal('concierge' as keyof ModalsReduxState));
    },
    [dispatch]
  );

  const reorderConcierge = useCallback(
    async (newTree: Array<NodeModel<TTreeViewNodeValue>>) => {
      const updateData = newTree.map(({ id, parent }, index) => {
        return {
          id: id as string,
          parent: parent as string,
          order: index + 1,
        };
      });
      await updateConciergeOrder(updateData, locationId);
    },
    [updateConciergeOrder, locationId]
  );

  useEffect(() => {
    if (modals.form.name) {
      return;
    }
    query();
  }, [modals.form.name]);

  return (
    <>
      <ConciergeTreeHeader onPress={createConciergeItem} />
      <ConciergeTreeExplain />
      {treeData.length && (
        <div className={styles.app}>
          <TreeView
            reorderConcierge={reorderConcierge}
            editConciergeItem={editConciergeItem}
            treeData={treeData}
            setTreeData={setTreeData}
            allCategories={getAvailableCategories()}
          />
        </div>
      )}
    </>
  );
};

export default ConciergeTree;
