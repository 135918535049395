import React, { FC } from 'react';
import Button from '../../../../../../components/Button';
import ButtonModel from '../../../../../../components/Button/enums/ButtonModel.enum';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { GuestEngagementStyles } from './guestEngagementStyle';
import { KioskLiveUsageData } from '../../../../../../__generated__/graphql';
import { useGuestEngagement } from './hooks/useGuestEngagement';

export const GuestEngagementComponent: FC<{ kiosk: KioskLiveUsageData }> = ({ kiosk }) => {
  const { handleClick, isModalOpen, setIsModalOpen } = useGuestEngagement(kiosk);
  if (!kiosk.accessGrant?.id) {
    return null;
  }

  return (
    <div style={GuestEngagementStyles.btnContainer}>
      <Button
        onClick={(): void => setIsModalOpen(true)}
        style={GuestEngagementStyles.guestEngagementButton}
        model={ButtonModel.ACTIVE_CTA}
      >
        Assisted by Agent
      </Button>
      <ConfirmModal
        isModalOpen={isModalOpen}
        headerText={'Confirm Guest Assistance'}
        description={
          'Did you personally assist this guest and help with any packages or questions?'
        }
        onConfirm={(): void => {
          handleClick();
        }}
        onCancel={(): void => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};
