import { Membership, Role } from '../../../__generated__/graphql';

import { DashboardPreferencesInput } from './CreateUserFormInputs';
import SelectOption from '../../../@types/react/SelectOption';
import UserFormMembershipMap from './UserFormMembershipMap';

export const UserAccessTypeEnum = {
  /** When the access level is per membership, the user will have access only to
   * the selected memberships */
  perMembership: 'perMembership',
  /** When the access level is full, the user will have access in the Tenant level,
   * it means the user will have access to the whole tenant */
  fullAccess: 'fullAccess',
} as const;
export type UserAccessType = typeof UserAccessTypeEnum[keyof typeof UserAccessTypeEnum];

export const UserFormMembershipTypeEnum = {
  locationsMembership: 'locations',
  groupsMembership: 'groups',
} as const;
// eslint-disable-next-line max-len
export type UserFormMembershipType = typeof UserFormMembershipTypeEnum[keyof typeof UserFormMembershipTypeEnum];

export const UserFormInputsNames = {
  userAccessType: 'userAccessType',
  locationsMembership: UserFormMembershipTypeEnum.locationsMembership,
  groupsMembership: UserFormMembershipTypeEnum.groupsMembership,
  map: 'map',
  values: 'values',
} as const;

type MembershipsMapping = {
  [UserFormInputsNames.map]: UserFormMembershipMap;
  [UserFormInputsNames.values]: SelectOption[];
};

export type UserFormInputs = {
  name: string;
  password?: string;
  phoneNumber: string;
  /** This role field determines:
   * - the role in the tenant level if the user access is full-access.
   * - When the user access is per membership, this role fields is taken into consideration
   * whether the Dashboard fields (password / confirm password / dashboard preferences)
   * should be displayed or not */
  role: Role;
  email: string;
  dashboardPreferences?: DashboardPreferencesInput;
  /** This field refers to the full-access membership
   *  (Tenant Level, without Location or Group associated) */
  tenantMembership?: Pick<Membership, 'id' | 'tenantId' | 'role'>;
  [UserFormInputsNames.userAccessType]: UserAccessType;
  /** Location Memberships */
  [UserFormInputsNames.locationsMembership]: MembershipsMapping;
  /** Location Memberships */
  [UserFormInputsNames.groupsMembership]: MembershipsMapping;
};
