import { gql } from '../../../__generated__';

export const CREATE_LOCATIONS_GROUP = gql(`
  mutation CreateLocationGroup($data: CustomLocationGroupCreateInput!) {
    createLocationGroup(data: $data) {
      id
      name
    }
  }
`);
