import {
  CloseButtonStyle,
  ConfirmModalCancel,
  ConfirmModalDesc,
  ConfirmModalHeader,
  ModalStyles,
  confirmModalStyle,
} from './confirmModalStyle';

import Button from '../../../../../../components/Button';
import ButtonModel from '../../../../../../components/Button/enums/ButtonModel.enum';
import Container from '../../../../../../components/Container';
import { FC } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import React from 'react';

interface ConfirmModalProps {
  isModalOpen: boolean;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  headerText: string;
  description: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  isModalOpen,
  shouldCloseOnEsc = false,
  shouldCloseOnOverlayClick = false,
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  headerText,
  description,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={confirmModalStyle}
    >
      <Container>
        <div style={ModalStyles.headerContainer}>
          <div style={ModalStyles.headerTextContainer}>
            <ConfirmModalHeader>{headerText}</ConfirmModalHeader>
          </div>
          <div style={ModalStyles.iconContainer}>
            <Button css={CloseButtonStyle} model={ButtonModel.LIGHT_ROUNDED} onClick={onCancel}>
              <MdClose size="22px" />
            </Button>
          </div>
        </div>
        <Container>
          <div style={ModalStyles.descContainer}>
            <ConfirmModalDesc>{description}</ConfirmModalDesc>
          </div>
          <div style={ModalStyles.ctaContainer}>
            <ConfirmModalCancel onClick={onCancel}>{cancelButtonLabel}</ConfirmModalCancel>
            <Button
              onClick={onConfirm}
              style={ModalStyles.confirmButton}
              model={ButtonModel.ACTIVE_CTA}
            >
              {confirmButtonLabel}
            </Button>
          </div>
        </Container>
      </Container>
    </Modal>
  );
};
