/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GroupIcon } from '../../../../../../assets/icons/GroupIcon';
import MarkerIcon from '../../../../../../assets/icons/LocationIcon';
import { MembershipSelectorOptionProps } from '../MembershipSelectorOption/MembershipSelectorOptionStyles';
import React from 'react';
import TenantIcon from '../../../../../../assets/icons/TenantIcon';
import Theme from '../../../../../../theme';
import { membershipGroupsLabel } from '../../functions/generateMembershipOptions';

type MembershipLevelIconProps = MembershipSelectorOptionProps & {
  membershipLevel: keyof typeof membershipGroupsLabel;
};

const MembershipLevelIcon: React.FC<MembershipLevelIconProps> = ({
  isSelected,
  membershipLevel,
}) => {
  const color = !isSelected ? '#000' : Theme.colors.white.hexCode;

  const icon = (() => {
    switch (membershipLevel) {
      case 'tenant':
        return TenantIcon;
      case 'location':
        return MarkerIcon;
      case 'group':
        return GroupIcon;
      default:
        return undefined;
    }
  })();

  return icon ? icon({ color, style: { flex: 'none' } }) : <></>;
};

export const MembershipLevelTenantIcon: React.FC<MembershipSelectorOptionProps> = (props) => {
  return <MembershipLevelIcon membershipLevel="tenant" {...props} />;
};

export const MembershipLevelLocationIcon: React.FC<MembershipSelectorOptionProps> = (props) => {
  return <MembershipLevelIcon membershipLevel="location" {...props} />;
};

export const MembershipLevelGroupIcon: React.FC<MembershipSelectorOptionProps> = (props) => {
  return <MembershipLevelIcon membershipLevel="group" {...props} />;
};
