import React from 'react';
import Theme from '../../theme';

interface SVGProps {
  color?: string;
  style?: React.CSSProperties;
}

const TenantIcon: React.FunctionComponent<SVGProps> = ({
  color = Theme.colors.dark.hexCode,
  style,
}: SVGProps) => {
  const height = '24px';
  const width = '24px';
  const viewBox = `0 0 ${width} ${height}`.replace(/px/g, '');

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      strokeWidth="1"
      fillRule="evenodd"
      style={style}
    >
      <g fillRule="evenodd" fill={color}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 11V15H7V11H9ZM5 9H7H9H11V11V15V17H9H7H5V15V11V9ZM17 11V15H15V11H17ZM13 9H15H17H19V11V15V17H17H15H13V15V11V9Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 17H6C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17ZM6 15C4.34315 15 3 16.3431 3 18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15H6Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.92866 9.00002H18.0713C18.5842 9.00002 19 8.58425 19 8.07136C19 7.67774 18.7518 7.32688 18.3807 7.19575L12.3331 5.05907C12.1176 4.98291 11.8824 4.98291 11.6669 5.05907L5.61929 7.19575C5.24815 7.32688 5 7.67774 5 8.07136C5 8.58425 5.41577 9.00002 5.92866 9.00002ZM4.95303 5.30999C3.78259 5.72352 3 6.83002 3 8.07136C3 9.68882 4.3112 11 5.92866 11H18.0713C19.6888 11 21 9.68882 21 8.07136C21 6.83002 20.2174 5.72352 19.047 5.30999L12.9994 3.17331C12.3527 2.94483 11.6473 2.94483 11.0006 3.17331L4.95303 5.30999Z"
        />
      </g>
    </svg>
  );
};

export default TenantIcon;
