export enum Colors {
  babyBlue = '#DEEBFF',
  blackPearl = '#1e1f20',
  blackRussian = '#242731',
  blackTint = '#0B0B0B',
  brightGrey = '#585B6A',
  charcoal = '#454545',
  comet = '#5E6272',
  dodgerBlue = '#3F8CFF',
  freeSpeechRed = '#CE0000',
  ghostWhite = '#f9f9fA',
  linkWater = '#d1d2d6',
  manatee = '#8F92A1',
  mediumTurquoise = '#6CDBD7',
  midnightExpress = '#262A34',
  mischka = '#AEB0B8',
  mountainMeadow = '#1FBA77',
  navyBlue = '#0052CC',
  neonPink = '#ff70c6',
  neroDarkA = '#161616',
  neroDark = '#171717',
  neroDarkC = '#191919',
  neroLight = '#282828',
  nobel = '#979797',
  salmon = '#FF7A68',
  selectiveYellow = '#ffb700',
  shuttleGrey = '#66676A',
  slateBlue = '#6C5DD3',
  solitude = '#E3E6EC',
  stormGrey = '#777C8E',
  turquoiseBlue = '#6cdcd7',
  whisper = '#E4E4E4',
  white = '#ffffff',
  danger = '#DC143C',
  lightBorder = '#303030',
  quickActionButton = '#0a6cff',
  whiteSmoke = '#F5F5F5',
  black = '#000000',
}

/* for purposes of rgba background opacity */
export enum RGB {
  babyBlue = '222, 235, 255',
  blackPearl = '30, 31, 32',
  blackRussian = '36, 39, 49',
  blackTint = '11, 11, 11',
  brightGrey = '88, 91, 106',
  charcoal = '69, 69, 69',
  comet = '94, 98, 114',
  dodgerBlue = '63, 140, 255',
  freeSpeechRed = '206, 0, 0',
  ghostWhite = '249, 249, 250',
  linkWater = '209, 210, 214',
  manatee = '143, 146, 161',
  mediumTurquoise = '',
  midnightExpress = '38, 42, 52',
  mischka = '174, 176, 184',
  mountainMeadow = '31, 186, 119',
  navyBlue = '0, 82, 204',
  neonPink = '255, 112, 198',
  neroDarkA = '22, 22, 22',
  neroDark = '23, 23, 23',
  neroDarkC = '25, 25, 25',
  neroLight = '40, 40, 40',
  nobel = '151, 151, 151',
  salmon = '255, 122, 104',
  selectiveYellow = '255, 183, 0',
  shuttleGrey = '102, 103, 106',
  slateBlue = '108, 93, 211',
  solitude = '227, 230, 236',
  stormGrey = '119, 124, 142',
  turquoiseBlue = '108, 220, 215',
  whisper = '228, 228, 228',
  white = '255, 255, 255',
  lightBorder = '48,48,48',
  quickActionButton = '10, 108, 255',
  whiteSmoke = '245, 245, 245',
  black = '0, 0, 0',
}

export default {
  babyBlue: { hexCode: Colors.babyBlue, rgb: RGB.babyBlue },
  bgBlackTint: { hexCode: Colors.blackTint, rgb: RGB.blackTint },
  borderDark: { hexCode: Colors.neroLight, rgb: RGB.neroLight },
  borderGrey: { hexCode: Colors.nobel, rgb: RGB.nobel },
  borderTable: { hexCode: Colors.whisper, rgb: RGB.whisper },
  boxShadow: { hexCode: Colors.solitude, rgb: RGB.solitude },
  buttonDark: { hexCode: Colors.blackRussian, rgb: RGB.blackRussian },
  buttonDarkBackground: { hexCode: Colors.slateBlue, rgb: RGB.slateBlue },
  buttonDominant: { hexCode: Colors.navyBlue, rgb: RGB.navyBlue },
  buttonInputEmbedded: { hexCode: Colors.dodgerBlue, rgb: RGB.dodgerBlue },
  buttonSubmissive: { hexCode: Colors.manatee, rgb: RGB.manatee },
  dark: { hexCode: Colors.blackPearl, rgb: RGB.blackPearl },
  darkAlternate: { hexCode: Colors.neroDark, rgb: RGB.neroDark },
  error: { hexCode: Colors.neonPink, rgb: RGB.neonPink },
  errorText: { hexCode: Colors.freeSpeechRed, rgb: RGB.freeSpeechRed },
  expandCollapseButtons: { hexCode: Colors.mediumTurquoise, rbg: RGB.mediumTurquoise },
  ghostWhite: { hexCode: Colors.ghostWhite, rgb: RGB.ghostWhite },
  grey: { hexCode: Colors.manatee, rgb: RGB.manatee },
  groupFolder: { hexCode: Colors.slateBlue, rgb: RGB.slateBlue },
  lightBorder: { hexCode: Colors.lightBorder, rgb: RGB.lightBorder },
  linkDarkBackground: { hexCode: Colors.turquoiseBlue, rgb: RGB.turquoiseBlue },
  linkWater: { hexCode: Colors.linkWater, rgb: RGB.linkWater },
  overlay: { hexCode: Colors.charcoal, rgb: RGB.charcoal },
  primaryBg: { hexCode: Colors.neroDarkC, rgb: RGB.neroDarkC },
  quickActionButton: { hexCode: Colors.quickActionButton, rgb: RGB.quickActionButton },
  success: { hexCode: Colors.turquoiseBlue, rgb: RGB.turquoiseBlue },
  tableHeading: { hexCode: Colors.shuttleGrey, rgb: RGB.shuttleGrey },
  warning: { hexCode: Colors.selectiveYellow, rgb: RGB.selectiveYellow },
  whisper: { hexCode: Colors.whisper, rgb: RGB.whisper },
  white: { hexCode: Colors.white, rgb: RGB.white },
  whiteSmoke: { hexCode: Colors.whiteSmoke, rgb: RGB.whiteSmoke },
  black: { hexCode: Colors.black, rgb: RGB.black },
};
