import React, { useEffect } from 'react';
import {
  SegmentedPickerContainer,
  SegmentedPickerOption,
  SegmentedPickerOptionContainer,
} from './SegmentedPicker.style';

import InputGroupProps from '../InputGroup/@types/InputGroupProps';
import { castToSelectOptions } from '../InputGroup/utils/selectInputUtils';
import { useFormContext } from 'react-hook-form';

type SegmentedPickerProps = Pick<InputGroupProps, 'name' | 'options' | 'defaultValue' | 'onChange'>;

export const SegmentedPicker: React.FC<SegmentedPickerProps> = ({
  name,
  options,
  defaultValue,
}) => {
  const { getValues, register, setValue } = useFormContext();
  const currentValue = getValues(name);

  useEffect(() => {
    register(name);
    setValue(name, defaultValue);
  }, []);

  const selectOptions = castToSelectOptions(options);

  const onChangeValue = (newValue: string): void => {
    setValue(name, newValue);
  };

  return (
    <SegmentedPickerContainer>
      {selectOptions.map((option, index) => (
        <SegmentedPickerOptionContainer
          key={`${index}-${option.value}`}
          checked={currentValue === option.value}
          onClick={(): void => onChangeValue(option.value)}
        >
          <SegmentedPickerOption
            type="radio"
            checked={currentValue === option.value}
            onClick={(): void => onChangeValue(option.value)}
          />
          {option.label}
        </SegmentedPickerOptionContainer>
      ))}
    </SegmentedPickerContainer>
  );
};
