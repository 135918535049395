import { Kiosk, KioskLiveUsageData } from '../../../../../__generated__/graphql';

type JoinOnlineKioskWithKiosksLiveUsageDataProps = {
  kiosksLiveUsage: KioskLiveUsageData[];
  onlineKiosks: Kiosk[];
};

const joinOnlineKioskWithKiosksLiveUsageData = ({
  kiosksLiveUsage,
  onlineKiosks,
}: JoinOnlineKioskWithKiosksLiveUsageDataProps): KioskLiveUsageData[] => {
  const kiosksLiveUsageIDs = kiosksLiveUsage.map((kiosk) => kiosk.kiosk.id!);

  const onlineKiosksNotActive = onlineKiosks
    .filter((kiosk) => kiosksLiveUsageIDs.indexOf(kiosk.id) === -1)
    .map(
      (kiosk): KioskLiveUsageData => {
        return {
          kiosk: {
            id: kiosk.id,
            location: kiosk.location,
            name: kiosk.name,
          },
        };
      }
    );

  return [...kiosksLiveUsage, ...onlineKiosksNotActive];
};

export default joinOnlineKioskWithKiosksLiveUsageData;
