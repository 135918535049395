import {
  AccessMembershipMutation,
  AccessMembershipMutationVariables,
} from '../__generated__/graphql';

import ACCESS_MEMBERSHIP from '../graphql/accessMembership';
import { RootState } from '../redux/reducers';
import { VirtualMembershipEntity } from '../redux/reducers/@types/MeReduxState';
import { flashApolloError } from '../util/errorUtils';
import useLoadDashboardPreferences from './useLoadDashboardPreferences';
import { useManageLocalActiveMembership } from './useManageLocalActiveMembership';
import { useMutation } from '@apollo/client';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

type AccessMembershipMutationData = AccessMembershipMutation;
type AccessMembershipMutationArgs = AccessMembershipMutationVariables;

type UseAccessMembershipType = {
  accessMembership: (virtualMembershipOption: VirtualMembershipEntity) => void;
};

export default function useAccessMembership(): UseAccessMembershipType {
  const { id: userId } = useSelector((state: RootState) => state.me);

  const { loadDashboardPreferences } = useLoadDashboardPreferences();
  const { saveMembershipLocally } = useManageLocalActiveMembership();

  const virtualMembershipOptionRef = useRef<VirtualMembershipEntity | undefined>(undefined);

  const [mutation] = useMutation<AccessMembershipMutationData, AccessMembershipMutationArgs>(
    ACCESS_MEMBERSHIP,
    {
      onCompleted: ({ accessMembership }) => {
        if (userId) {
          loadDashboardPreferences({
            locationId: accessMembership.location?.id,
            tenantId: accessMembership.tenant.id,
            userId,
          });
        }

        const virtualMembershipOption = virtualMembershipOptionRef.current;
        if (virtualMembershipOption) {
          saveMembershipLocally(virtualMembershipOption);
        }
      },
      onError: flashApolloError,
    }
  );

  const accessMembership = (virtualMembershipOption: VirtualMembershipEntity): void => {
    virtualMembershipOptionRef.current = virtualMembershipOption;

    mutation({
      variables: {
        membershipId: virtualMembershipOption.id,
      },
    });
  };

  return {
    accessMembership,
  };
}
