import { ActiveMembership } from '../../../../../redux/reducers/@types/MeReduxState';
import { KioskLiveUsageData } from '../../../../../__generated__/graphql';

type FilterKiosksByLocationParams = {
  kiosks: KioskLiveUsageData[];
  locationsFiltered: string[] | undefined;
  activeMembership?: ActiveMembership;
};

const filterKiosksByLocation = ({
  kiosks: _kiosks,
  locationsFiltered,
  activeMembership,
}: FilterKiosksByLocationParams): KioskLiveUsageData[] => {
  let kiosks = [..._kiosks];

  if (locationsFiltered && locationsFiltered.length > 0) {
    kiosks = kiosks.filter((kiosk) => locationsFiltered.includes(kiosk.kiosk.location.id));
  }

  const activeMembershipLocationId = activeMembership?.location?.id;
  if (activeMembershipLocationId) {
    kiosks = kiosks.filter((kiosk) => kiosk.kiosk.location.id === activeMembershipLocationId);
  }

  return kiosks;
};

export default filterKiosksByLocation;
