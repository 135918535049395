import { ExtendedGroupType } from './types';

export type IterateOverLocationsGroupCallbackProps = {
  stopLoop: boolean;
} | void;

export const iterateOverLocationsGroup = (
  groups: ExtendedGroupType[] | undefined,
  callback: (group: ExtendedGroupType) => IterateOverLocationsGroupCallbackProps
): void => {
  if (!groups) {
    return;
  }

  const groupsValues = Object.values(groups);

  groupsValues.every((group): boolean => {
    const result = callback(group);
    if (result && result.stopLoop) {
      // Returning false means the loop should stop
      return false;
    }

    if (group.groups.length) {
      iterateOverLocationsGroup(group.groups, callback);
    }

    // Returning true means that it should continue the loop
    return true;
  });
};
