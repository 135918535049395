import Heading, { HeadingModel } from '../../../Heading';
import { MembershipContainer, MembershipSectionContainer } from './MembershipSection.style';
import {
  UserAccessTypeEnum,
  UserFormInputs,
  UserFormInputsNames,
  UserFormMembershipTypeEnum,
} from '../../@types/UserFormInputs';

import AsyncSelectInput from '../../../AsyncSelectInput';
import { Divider } from '../../../InputGroup/styles/Divider.style';
import Form from '../../../Form';
import { MembershipOption } from './components';
import React from 'react';
import { SegmentedPicker } from '../../../SegmentedPicker/SegmentedPicker';
import SubmitRow from '../../../SubmitRow';
import TextContent from '../../../TextContent';
import UserFormMembershipMap from '../../@types/UserFormMembershipMap';
import useAdjustMembershipsMap from '../../hooks/useAdjustMembershipsMap';
import { useFormContext } from 'react-hook-form';
import { useLoadGroupsOptions } from '../../../../hooks/useLoadGroupsOptions/useLoadGroupsOptions';
import useLoadLocationOptions from '../../../../hooks/useLoadLocationOptions';
import { useSubmitUser } from '../../hooks/useSubmitUser';
import values from 'lodash/values';

const locationMembershipsValueFieldName = `${UserFormMembershipTypeEnum.locationsMembership}.${UserFormInputsNames.values}`;
const locationMembershipsMappingFieldName = `${UserFormMembershipTypeEnum.locationsMembership}.${UserFormInputsNames.map}`;

const groupMembershipsValueFieldName = `${UserFormMembershipTypeEnum.groupsMembership}.${UserFormInputsNames.values}`;
const groupMembershipsMappingFieldName = `${UserFormMembershipTypeEnum.groupsMembership}.${UserFormInputsNames.map}`;

const MembershipSection: React.FC = () => {
  const { getValues, handleSubmit } = useFormContext<UserFormInputs>();
  const userAccessTypeValue = getValues(UserFormInputsNames.userAccessType);

  const { loadingMutation, submitHandler } = useSubmitUser();

  const { adjustMembershipsMap: adjustLocationMembershipsMap } = useAdjustMembershipsMap({
    fieldName: UserFormMembershipTypeEnum.locationsMembership,
  });
  const { adjustMembershipsMap: adjustGroupMembershipsMap } = useAdjustMembershipsMap({
    fieldName: UserFormMembershipTypeEnum.groupsMembership,
  });

  const groupMembershipsMappingValue: UserFormMembershipMap = getValues(
    groupMembershipsMappingFieldName
  );
  const groupActiveMemberships = values(groupMembershipsMappingValue).filter(
    (membership) => membership.shouldCreate || (membership.membershipId && !membership.shouldDelete)
  );

  const locationMembershipsMappingValue: UserFormMembershipMap = getValues(
    locationMembershipsMappingFieldName
  );
  const locationActiveMemberships = values(locationMembershipsMappingValue).filter(
    (membership) => membership.shouldCreate || (membership.membershipId && !membership.shouldDelete)
  );

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <MembershipContainer enabled>
        <SegmentedPicker
          name={UserFormInputsNames.userAccessType}
          defaultValue={userAccessTypeValue}
          options={[
            { label: 'Select Memberships', value: UserAccessTypeEnum.perMembership },
            { label: 'Grant Full Access', value: UserAccessTypeEnum.fullAccess },
          ]}
        />

        <MembershipContainer enabled={userAccessTypeValue === UserAccessTypeEnum.perMembership}>
          {/* Groups and Locations Memberships */}
          <MembershipSectionContainer>
            <Heading model={HeadingModel.SECONDARY}>Select Groups</Heading>
            <AsyncSelectInput
              isMulti
              loadOptions={useLoadGroupsOptions()}
              name={groupMembershipsValueFieldName}
              onChange={adjustGroupMembershipsMap}
              displayError
            />

            <Heading model={HeadingModel.SECONDARY}>Select Locations</Heading>
            <AsyncSelectInput
              isMulti
              loadOptions={useLoadLocationOptions()}
              name={locationMembershipsValueFieldName}
              onChange={adjustLocationMembershipsMap}
              displayError
            />
          </MembershipSectionContainer>

          <Divider />

          {/* Group roles */}
          <MembershipSectionContainer>
            <Heading model={HeadingModel.SECONDARY}>Select Group Roles</Heading>
            {groupActiveMemberships.map((membership, index) => (
              <MembershipOption
                key={`${index}-${membership.membershipId}`}
                membership={membership}
                fieldName={UserFormMembershipTypeEnum.groupsMembership}
              />
            ))}
          </MembershipSectionContainer>

          <Divider />

          {/* Location Memberships */}
          <MembershipSectionContainer>
            <Heading model={HeadingModel.SECONDARY}>Select Locations Roles</Heading>
            <TextContent>
              Location roles will override group for these specific locations.
            </TextContent>
            {locationActiveMemberships.map((membership, index) => (
              <MembershipOption
                key={`${index}-${membership.membershipId}`}
                membership={membership}
                fieldName={UserFormMembershipTypeEnum.locationsMembership}
              />
            ))}
          </MembershipSectionContainer>
        </MembershipContainer>
      </MembershipContainer>
      <SubmitRow loading={loadingMutation} submitText="Submit" />
    </Form>
  );
};

export default MembershipSection;
