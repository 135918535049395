import {
  CanAssociateLocationGroupQuery,
  CanAssociateLocationGroupQueryVariables,
} from '../../../__generated__/graphql';

import { CAN_ASSOCIATE_LOCATION_GROUP } from '../graphql/canAssociateLocationGroup';
import { useApolloClient } from '@apollo/client';

type CanAssociateGroupQueryData = CanAssociateLocationGroupQuery;
type CanAssociateGroupQueryVariables = CanAssociateLocationGroupQueryVariables;

type UseCanAssociateLocationGroupType = (
  variables: CanAssociateGroupQueryVariables
) => Promise<boolean>;

export function useCanAssociateLocationGroup(): UseCanAssociateLocationGroupType {
  const client = useApolloClient();

  return async (variables: CanAssociateGroupQueryVariables): Promise<boolean> => {
    const { data } = await client.query<
      CanAssociateGroupQueryData,
      CanAssociateGroupQueryVariables
    >({
      query: CAN_ASSOCIATE_LOCATION_GROUP,
      variables,
    });

    return data.canAssociateLocationGroup;
  };
}
