import CreateUserFormInputs from '../@types/CreateUserFormInputs';
import EditUserFormInputs from '../@types/EditUserFormInputs';
import FormContext from '../../../contexts/form.context';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import { Role } from '../../../__generated__/graphql';
import { SubmitHandler } from 'react-hook-form';
import { UserAccessTypeEnum } from '../@types/UserFormInputs';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useContext } from 'react';
import useCreateUser from '../../../hooks/useCreateUser';
import { useDispatch } from 'react-redux';
import useUpdateUser from '../../../hooks/useUpdateUser';

type UserFormInputs = CreateUserFormInputs | EditUserFormInputs;

type UseSubmitUserType = {
  loadingMutation: boolean;
  submitHandler: SubmitHandler<UserFormInputs>;
};

export const useSubmitUser = (): UseSubmitUserType => {
  // Form properties
  const dispatch = useDispatch();
  const { identifier } = useContext(FormContext);
  const crudAction = identifier ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  // Mutations - Create/Update
  const useMutation = crudAction === FormCrudAction.CREATE ? useCreateUser : useUpdateUser;
  const { loading: loadingMutation, mutation } = useMutation();

  // Auxiliar functions
  const validateUserAccessTypeAndMemberships = (mutationInputs: UserFormInputs): void => {
    const { userAccessType, locations, groups } = mutationInputs;

    if (userAccessType === UserAccessTypeEnum.perMembership) {
      const hasLocationMembership = Boolean(Object.values(locations?.values).length > 0);

      const hasGroupMembership = Boolean(Object.values(groups?.values).length > 0);

      if (!hasLocationMembership && !hasGroupMembership) {
        const errorMessage = 'At least one membership should be created';

        window.flash({
          message: `Error: ${errorMessage}`,
          type: 'error',
        });

        throw new Error(errorMessage);
      }
    }
  };

  const removeDashboardPreferencesWhenNeeded = (mutationInputs: UserFormInputs): void => {
    const dashboardPreferencesIsEmpty =
      !mutationInputs.dashboardPreferences ||
      Object.values(mutationInputs.dashboardPreferences).every((field) => field === undefined);
    if (dashboardPreferencesIsEmpty || mutationInputs.role === Role.User) {
      // eslint-disable-next-line no-param-reassign
      mutationInputs.dashboardPreferences = undefined;
    }
  };

  // Submit
  const submitHandler: SubmitHandler<UserFormInputs> = (inputs): void => {
    dispatch(
      openConfirmationModal({
        action: (): void => {
          try {
            const mutationInputs: UserFormInputs = { ...inputs };

            validateUserAccessTypeAndMemberships(mutationInputs);

            removeDashboardPreferencesWhenNeeded(mutationInputs);

            if (identifier) {
              mutation(mutationInputs, identifier);
            } else {
              mutation(mutationInputs);
            }
            // eslint-disable-next-line no-empty
          } catch {}
        },
      })
    );
  };

  return {
    submitHandler,
    loadingMutation,
  };
};
