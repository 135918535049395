import * as yup from 'yup';

import {
  LocationsGroupChildrenType,
  LocationsGroupChildrenTypeEnum,
  LocationsGroupFormInputsNames,
} from './locationsGroupFormInputs';

import SELECT_OPTION from '../../../constants/yupValidations/selectOption';

export const LOCATIONS_GROUP_FORM_VALIDATION_SCHEMA = yup.object().shape({
  [LocationsGroupFormInputsNames.Name]: yup.string().required('Name is required'),
  [LocationsGroupFormInputsNames.ChildrenType]: yup
    .mixed<LocationsGroupChildrenType>()
    .oneOf(Object.values(LocationsGroupChildrenTypeEnum)),
  [LocationsGroupFormInputsNames.Locations]: yup.object().shape({
    values: yup.array(SELECT_OPTION),
  }),
  [LocationsGroupFormInputsNames.Groups]: yup.object().shape({
    values: yup.array(SELECT_OPTION),
  }),
});
