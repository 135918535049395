import { KioskLiveUsageData, SortOrder } from '../../../../../__generated__/graphql';

import { KioskLiveUsagePayloadEvent } from '../../../../../redux/reducers/kiosksLiveUsage.reducer/@types/KiosksLiveUsageReduxState';
import { RootState } from '../../../../../redux/reducers';
import filterKiosksByLocation from '../functions/filterKiosksByLocation';
import generateMembershipQuery from '../../../../../util/api/generateMembershipQuery';
import { getKioskOfflineMS } from '../../../../../util/kiosk/isKioskOnline';
import { isEqual } from 'lodash';
import joinOnlineKioskWithKiosksLiveUsageData from '../functions/joinOnlineKioskWithKiosksLiveUsageData';
import { kiosksLiveUsageDataTableKey } from '../components/KiosksLiveUsageFilter';
import useDidMountEffect from '../../../../../hooks/util/useDidMountEffect';
import { useEffect } from 'react';
import useGetKiosks from '../../../../../hooks/useGetKiosks';
import useGetKiosksLiveUsageData from '../../../../../hooks/useGetKiosksLiveUsageData';
import { useSelector } from 'react-redux';

type UseGetKiosksLiveUsage = {
  allKiosks: KioskLiveUsageData[];
  isLoading: boolean;
};

const identifyIfThereIsAnyPayloadNotLoadedYet = (
  kiosksLiveUsageCurrentData: (KioskLiveUsageData | undefined)[],
  kiosksLiveUsageToBeGet: KioskLiveUsagePayloadEvent[]
): boolean => {
  return kiosksLiveUsageToBeGet.some((outdatedKioskData) => {
    const kioskWasFoundOnLiveUsageCurrentData = kiosksLiveUsageCurrentData.find(
      (updatedKioskData) => {
        const updatedDataToPayload = {
          kioskId: updatedKioskData?.kiosk.id,
          accessGrantId: updatedKioskData?.accessGrant?.id,
        };

        return isEqual(outdatedKioskData, updatedDataToPayload);
      }
    );

    return !kioskWasFoundOnLiveUsageCurrentData;
  });
};

const useGetKiosksLiveUsage = (): UseGetKiosksLiveUsage => {
  const kiosksLiveUsageState = useSelector((store: RootState) => store.kioskLiveUsage);

  const activeMembership = useSelector((store: RootState) => store.me.activeMembership);

  const locationsFiltered = useSelector((state: RootState) => {
    const kiosksLiveUsageDataTableFilters = state.dataTable.filters[kiosksLiveUsageDataTableKey];

    if (kiosksLiveUsageDataTableFilters?.length > 0) {
      const locationsFilter = kiosksLiveUsageDataTableFilters.find(
        (filter) => filter.fieldKey === 'loc'
      );
      if (locationsFilter) {
        return locationsFilter.values;
      }
    }

    return undefined;
  });

  const {
    isLoading: isLoadingKiosksLiveUsage,
    query: queryKiosksLiveUsage,
  } = useGetKiosksLiveUsageData();

  const {
    get: getOnlineKiosks,
    loading: isLoadingOnlineKiosks,
    data: onlineKiosksData,
  } = useGetKiosks({
    queryOptions: {
      orderBy: [{ name: SortOrder.Asc }],
      take: 20,
      where: {
        ...generateMembershipQuery(activeMembership),
        lastSeenAt: { gte: new Date(getKioskOfflineMS()) },
        ...(locationsFiltered && locationsFiltered.length > 0
          ? {
              locationId: {
                in: locationsFiltered,
              },
            }
          : {}),
      },
    },
  });

  const queryGetkiosksLiveUsageData = (): void => {
    /** List of kiosks that received events and it needs to query the full kiosk/reservation data */
    const kiosksLiveUsageToBeGet: KioskLiveUsagePayloadEvent[] = Object.values(kiosksLiveUsageState)
      .filter(({ data }) => !data)
      .map(
        (data): KioskLiveUsagePayloadEvent => {
          return {
            accessGrantId: data.payload.accessGrant?.id,
            kioskId: data.payload.kioskId,
          };
        }
      );

    /** List of kiosks with kiosk/reservation data already loaded  */
    const kiosksLiveUsageCurrentData = Object.values(kiosksLiveUsageState)
      .filter(({ data }) => data)
      .map(({ data }) => data);

    const hasSomePayloadNotLoadedYet = identifyIfThereIsAnyPayloadNotLoadedYet(
      kiosksLiveUsageCurrentData,
      kiosksLiveUsageToBeGet
    );

    const shouldQueryKiosksLiveUsage =
      kiosksLiveUsageToBeGet.length > 0 && hasSomePayloadNotLoadedYet;

    if (shouldQueryKiosksLiveUsage) {
      queryKiosksLiveUsage(kiosksLiveUsageToBeGet);
    }
  };

  useEffect(() => {
    getOnlineKiosks();
  }, []);

  useEffect(() => {
    queryGetkiosksLiveUsageData();
  }, [kiosksLiveUsageState]);

  useDidMountEffect(() => {
    // It will not be called on the first render since getOnlineKiosks is already called
    //  on kiosksLiveUsageState dependency.
    //  It will be triggered only when locationsFiltered has changed.
    getOnlineKiosks();
  }, [locationsFiltered]);

  let kiosks = joinOnlineKioskWithKiosksLiveUsageData({
    kiosksLiveUsage: Object.values(kiosksLiveUsageState)
      .filter((kioskLiveUsageState) => kioskLiveUsageState.data)
      .map((kioskLiveUsageState) => kioskLiveUsageState.data!),
    onlineKiosks: onlineKiosksData ?? [],
  });
  kiosks = filterKiosksByLocation({ activeMembership, kiosks, locationsFiltered });

  // Sort by the active kiosks (with Access Grant), then, online kiosks
  const allKiosks: KioskLiveUsageData[] = [
    ...kiosks.filter((kiosk) => kiosk.accessGrant),
    ...kiosks.filter((kiosk) => !kiosk.accessGrant),
  ];

  const isLoading = isLoadingKiosksLiveUsage || isLoadingOnlineKiosks;

  return {
    allKiosks,
    isLoading,
  };
};

export default useGetKiosksLiveUsage;

export class UnitTestExports {
  static identifyIfThereIsAnyPayloadNotLoadedYet = identifyIfThereIsAnyPayloadNotLoadedYet;
}
