import { CreateLocationsGroupArgs, UpdateLocationsGroupArgs } from '../@types';
import { useDispatch, useSelector } from 'react-redux';

import { CustomLocationGroupCreateInput } from '../../../__generated__/graphql';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import { LocationsGroupChildrenTypeEnum } from '../constants/locationsGroupFormInputs';
import { LocationsGroupFormInputsType } from '../@types/LocationsGroupFormInputsType';
import { RootState } from '../../../redux/reducers';
import { SubmitHandler } from 'react-hook-form';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useCreateLocationsGroup } from './useCreateLocationsGroup';
import { useUpdateLocationsGroup } from './useUpdateLocationsGroup';

type UseSubmitLocationsGroup = {
  loadingMutation: boolean;
  submitHandler: SubmitHandler<LocationsGroupFormInputsType>;
};

export const useSubmitLocationsGroup = (): UseSubmitLocationsGroup => {
  const { activeMembership } = useSelector((state: RootState) => state.me);
  const dispatch = useDispatch();

  // Form Properties
  const id = useSelector((state: RootState) => state.modals.form.id);
  const crudAction = id ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  // Mutations - Create/Update
  const { mutation: mutationCreate, loading: loadingCreate } = useCreateLocationsGroup();
  const { mutation: mutationUpdate, loading: loadingUpdate } = useUpdateLocationsGroup();

  // Submit
  const submitHandler: SubmitHandler<LocationsGroupFormInputsType> = (
    inputs: LocationsGroupFormInputsType
  ): void => {
    dispatch(
      openConfirmationModal({
        action: (): void => {
          const { childrenType } = inputs;
          const locations =
            childrenType === LocationsGroupChildrenTypeEnum.locations
              ? inputs.locations?.values?.map((l) => l.value)
              : [];
          const groups =
            childrenType === LocationsGroupChildrenTypeEnum.groups
              ? inputs.groups?.values?.map((g) => g.value)
              : [];

          const mutationInputs: Pick<
            CustomLocationGroupCreateInput,
            'name' | 'locations' | 'groups'
          > = {
            name: inputs.name,
            locations,
            groups,
          };

          if (crudAction === FormCrudAction.CREATE) {
            const createInput: CreateLocationsGroupArgs = {
              data: {
                tenantId: activeMembership.tenantId,
                ...mutationInputs,
              },
            };

            mutationCreate(createInput);
          } else if (crudAction === FormCrudAction.EDIT && id) {
            const updateInput: UpdateLocationsGroupArgs = {
              data: {
                ...mutationInputs,
              },
              where: {
                id,
              },
            };

            mutationUpdate(updateInput);
          }
        },
      })
    );
  };

  return {
    loadingMutation: loadingCreate || loadingUpdate,
    submitHandler,
  };
};
