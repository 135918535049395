import { PropsWithChildren, createContext, useContext } from 'react';
import { UseLocationsGroupsType, useLocationsGroupsTree } from '../hooks/useLocationGroupsTree';

import React from 'react';

export const LocationsGroupsContext = createContext<UseLocationsGroupsType>({
  data: undefined,
  query: () => {},
  toggleAll: () => {},
  toggleGroupTree: () => {},
  loading: true,
});

type LocationsGroupsTreeProviderProps = PropsWithChildren<{}>;

export const LocationsGroupsTreeProvider: React.FC<LocationsGroupsTreeProviderProps> = ({
  children,
}: LocationsGroupsTreeProviderProps) => {
  const locationsGroupsTreeHook = useLocationsGroupsTree();

  return (
    <LocationsGroupsContext.Provider value={locationsGroupsTreeHook}>
      {children}
    </LocationsGroupsContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useLocationsGroupsProvider = () => useContext(LocationsGroupsContext);
