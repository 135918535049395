import { RootState } from '../../../../../redux/reducers';
import { isFullAccessMembership as isFullAccessMembershipFn } from '../../../../../util/membership/membershipUtils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type UseLocationsGroupsAvailabilityType = {
  locationsGroupsIsAvailable: boolean;
};

export const useLocationsGroupsAvailability = (): UseLocationsGroupsAvailabilityType => {
  const activeMembership = useSelector((state: RootState) => state.me.activeMembership);
  const dashboardPreferences = useSelector((state: RootState) => state.dashboardPreferences);

  const locationsGroupsIsAvailable = useMemo(() => {
    const isFullAccessMembership = isFullAccessMembershipFn(activeMembership);

    const locationGroupIsEnabled = Boolean(dashboardPreferences?.locationGroup);

    return isFullAccessMembership && locationGroupIsEnabled;
  }, [activeMembership, dashboardPreferences]);

  return {
    locationsGroupsIsAvailable,
  };
};
