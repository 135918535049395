import { Location, LocationGroup, Membership, Role, User } from '../../../__generated__/graphql';
import { UserAccessTypeEnum, UserFormMembershipTypeEnum } from '../@types/UserFormInputs';
import { dateFormatOptions, timeFormatOptions } from '../constants/dashboardPreferencesInputs';

import { ActiveMembership } from '../../../redux/reducers/@types/MeReduxState';
import { DashboardPreferencesInput } from '../@types/CreateUserFormInputs';
import EditUserFormInputs from '../@types/EditUserFormInputs';
import SelectOption from '../../../@types/react/SelectOption';
import { UserDashboardPreferences } from '../../../@types/client/DashboardPreferences';
import UserFormMembershipMap from '../@types/UserFormMembershipMap';
import { isDefined } from '../../../util/graphQLUtils';
import { isFullAccessMembership } from '../../../util/membership/membershipUtils';

export type GetInitialValuesProps = {
  user: Pick<User, 'memberships' | 'name' | 'email' | 'phoneNumber' | 'dashboardPreferences'>;
  activeMembership: Pick<ActiveMembership, 'tenantId'>;
};

type LocationMembership = Membership & {
  location: Location;
};

type GroupMembership = Membership & {
  locationGroup: LocationGroup;
};

type DashboardPreferencesInputType = EditUserFormInputs['dashboardPreferences'];
type LocationsMembershipsType = EditUserFormInputs['locations'];
type GroupsMembershipsType = EditUserFormInputs['groups'];
type TenantMembershipType = EditUserFormInputs['tenantMembership'];
type RoleMembershipType = EditUserFormInputs['role'];

function getDashboardPreferences(
  user: GetInitialValuesProps['user']
): DashboardPreferencesInputType {
  let dashboardPreferencesInput: DashboardPreferencesInput | undefined;

  if (user.dashboardPreferences) {
    const dashboardPreferences = user.dashboardPreferences as UserDashboardPreferences;

    dashboardPreferencesInput = {
      ...(dashboardPreferences.preferredDateFormat && {
        preferredDateFormat: {
          label:
            dateFormatOptions.find(
              (dateFormat) => dateFormat.value === dashboardPreferences.preferredDateFormat
            )?.label ?? dashboardPreferences.preferredDateFormat,
          value: dashboardPreferences.preferredDateFormat,
        },
      }),
      ...(dashboardPreferences.preferredTimeFormat && {
        preferredTimeFormat: {
          label:
            timeFormatOptions.find(
              (timeFormat) => timeFormat.value === dashboardPreferences.preferredTimeFormat
            )?.label ?? dashboardPreferences.preferredTimeFormat,
          value: dashboardPreferences.preferredTimeFormat,
        },
      }),
    };
  }

  return dashboardPreferencesInput;
}

function getLocationMembership(user: GetInitialValuesProps['user']): LocationsMembershipsType {
  const locationMemberships: LocationMembership[] = user.memberships.filter(
    (membership): membership is LocationMembership => isDefined(membership.location)
  );

  const locationsMembershipsOptions: SelectOption[] = locationMemberships.map((membership) => ({
    label: membership.location.name,
    value: membership.location.id,
  }));
  const locationsMembershipsMap: UserFormMembershipMap = locationMemberships.reduce(
    (map, membership) => {
      // eslint-disable-next-line no-param-reassign
      map[membership.location.id] = {
        membershipId: membership.id,
        option: {
          label: membership.location.name,
          value: membership.location.id,
        },
        shouldCreate: false,
        shouldDelete: false,
        shouldUpdate: false,
        role: membership.role,
        type: UserFormMembershipTypeEnum.locationsMembership,
      };
      return map;
    },
    {} as UserFormMembershipMap
  );

  return {
    map: locationsMembershipsMap,
    values: locationsMembershipsOptions,
  };
}

function getGroupMembership(user: GetInitialValuesProps['user']): GroupsMembershipsType {
  const groupMemberships: GroupMembership[] = user.memberships.filter(
    (membership): membership is GroupMembership => isDefined(membership.locationGroup)
  );

  const groupsMembershipsOptions: SelectOption[] = groupMemberships.map((membership) => ({
    label: membership.locationGroup.name,
    value: membership.locationGroup.id,
  }));
  const groupsMembershipsMap: UserFormMembershipMap = groupMemberships.reduce((map, membership) => {
    // eslint-disable-next-line no-param-reassign
    map[membership.locationGroup.id] = {
      membershipId: membership.id,
      option: {
        label: membership.locationGroup.name,
        value: membership.locationGroup.id,
      },
      shouldCreate: false,
      shouldDelete: false,
      shouldUpdate: false,
      role: membership.role,
      type: UserFormMembershipTypeEnum.groupsMembership,
    };
    return map;
  }, {} as UserFormMembershipMap);

  return {
    map: groupsMembershipsMap,
    values: groupsMembershipsOptions,
  };
}

function getActiveTenantFullAccessMembership({
  user,
  activeMembership,
}: GetInitialValuesProps): Membership | undefined {
  const activeTenantFullAccessMembership = user.memberships.find(
    (membership) =>
      membership.tenantId === activeMembership.tenantId && isFullAccessMembership(membership)
  );

  return activeTenantFullAccessMembership;
}

/** Obtain the full access membership (tenant membership without location or group associated) */
function getTenantMembership({
  user,
  activeMembership,
}: GetInitialValuesProps): TenantMembershipType {
  const activeTenantFullAccessMembership = getActiveTenantFullAccessMembership({
    user,
    activeMembership,
  });

  return activeTenantFullAccessMembership;
}

function getRole({ user, activeMembership }: GetInitialValuesProps): RoleMembershipType {
  let tenantMembership = getTenantMembership({ user, activeMembership });

  if (tenantMembership) {
    return tenantMembership.role;
  }

  const activeTenantMemberships = user.memberships.filter(
    (membership) => membership.tenantId === activeMembership.tenantId
  );

  if (activeTenantMemberships.length > 0) {
    const sortedTenantMemberships = [
      ...activeTenantMemberships.filter((m) => m.role === Role.Admin),
      ...activeTenantMemberships.filter((m) => m.role === Role.Agent),
      ...activeTenantMemberships.filter((m) => m.role === Role.User),
    ];

    [tenantMembership] = sortedTenantMemberships;
    return tenantMembership.role;
  }

  return Role.User;
}

function getUserAccessType({
  user,
  activeMembership,
}: GetInitialValuesProps): EditUserFormInputs['userAccessType'] {
  const activeTenantFullAccessMembership = getActiveTenantFullAccessMembership({
    user,
    activeMembership,
  });

  return activeTenantFullAccessMembership
    ? UserAccessTypeEnum.fullAccess
    : UserAccessTypeEnum.perMembership;
}

export function getInitialValues(props: GetInitialValuesProps): Partial<EditUserFormInputs> {
  const { user } = props;

  const dashboardPreferences = getDashboardPreferences(user);
  const locations = getLocationMembership(user);
  const groups = getGroupMembership(user);
  const tenantMembership = getTenantMembership(props);
  const role = getRole(props);
  const userAccessType = getUserAccessType(props);

  return {
    dashboardPreferences,
    email: user.email ?? '',
    locations,
    groups,
    name: user.name ?? '',
    tenantMembership,
    role,
    userAccessType,
    ...(user.phoneNumber && { phoneNumber: user.phoneNumber }),
  };
}

export class UnitTestExports {
  static getTenantMembership = getTenantMembership;
}
