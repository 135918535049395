import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  generateVirtualMembership,
  getActiveMembershipFromVirtualMembership,
} from '../util/membership/virtualMembershipUtils';
import { loadMe, login } from '../redux/actions/me.actions';
import { useDispatch, useSelector } from 'react-redux';

import ME from '../graphql/me';
import { MeWithUserRolePermissionsQuery } from '../__generated__/graphql';
import { RootState } from '../redux/reducers';
import { VirtualUserMembership } from '../redux/reducers/@types/MeReduxState';
import { flashApolloError } from '../util/errorUtils';
import isTokenExpired from '../util/isTokenExpired';
import useAccessMembership from './useAccessMembership';
import useLogoutOfApp from './useLogoutOfApp';
import { useManageLocalActiveMembership } from './useManageLocalActiveMembership';

interface UseMeInterface {
  called: boolean;
  getMe: () => void;
  loading: boolean;
}

type MeQueryData = MeWithUserRolePermissionsQuery;

export default function useMe(): UseMeInterface {
  const dispatch = useDispatch();
  const loggedIn = useSelector<RootState>((state) => state.me.initialized);

  const { accessMembership } = useAccessMembership();
  const logout = useLogoutOfApp();
  const {
    getTheMostRecentActiveMembershipBetweenTheMeQueryAndTheLocalOne,
  } = useManageLocalActiveMembership();

  const [getMe, { called, error, loading }] = useLazyQuery<MeQueryData>(ME, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ meWithUserRolePermissions }) => {
      const { activeMembership } = meWithUserRolePermissions;

      let newActiveMembership = activeMembership;

      if (activeMembership) {
        const virtualMemberships:
          | VirtualUserMembership[]
          | undefined = meWithUserRolePermissions.virtualMemberships?.map((virtualMembership) => {
          const virtualMembershipEntity = generateVirtualMembership(virtualMembership);

          return {
            ...virtualMembership,
            virtualMembershipEntity,
          };
        });

        // eslint-disable-next-line max-len
        const mostRecentActiveMembership = getTheMostRecentActiveMembershipBetweenTheMeQueryAndTheLocalOne(
          virtualMemberships ?? [],
          activeMembership
        );
        accessMembership(mostRecentActiveMembership.virtualMembershipEntity);
        newActiveMembership = getActiveMembershipFromVirtualMembership(mostRecentActiveMembership);

        dispatch(
          login({
            ...meWithUserRolePermissions,
            activeMembership: newActiveMembership,
            virtualMemberships,
          })
        );
      }
    },
    onError: (e: ApolloError) => {
      flashApolloError(e);
      logout();
    },
  });

  return {
    called,
    getMe: (): void => {
      const tokenExpired = isTokenExpired();

      if (!error && !tokenExpired && !loggedIn && !loading) {
        dispatch(loadMe());
        getMe();
      }
    },
    loading,
  };
}
