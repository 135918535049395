/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Theme from '../../theme';
import styled from 'styled-components';

type RadioInputOptionContainerProps = {
  checked: boolean;
};

export const RadioInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

export const RadioInputOptionContainer = styled.div<RadioInputOptionContainerProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${({ checked }) =>
    checked &&
    `
    color: ${Theme.colors.quickActionButton.hexCode};
  `}
`;

export const RadioInputStyle = styled.input<RadioInputOptionContainerProps>`
  margin: 0 !important;
`;
