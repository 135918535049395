import React from 'react';

export const FolderIcon: React.FC<{
  color: string;
}> = ({ color }) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.1875 3H1.98438V15H18.0156V5.01562H9.15625L7.1875 3ZM7.98438 0.984375L10 3H18.0156C19.0938 3 19.9844 3.89062 19.9844 5.01562V15C19.9844 16.0781 19.0938 17.0156 18.0156 17.0156H1.98438C0.90625 17.0156 0.015625 16.0781 0.015625 15V3C0.015625 1.92188 0.90625 0.984375 1.98438 0.984375H7.98438Z"
        fill={color}
      />
    </svg>
  );
};
