import 'styled-components/macro';

import AppHeaderStyle from './styles/AppHeader.style';
import Container from '../Container';
import { MembershipSelector } from './components/MembershipSelector/MembershipSelector';
import React from 'react';

const AppHeader: React.FC = () => {
  return (
    <Container alignContent="center" css={AppHeaderStyle}>
      <MembershipSelector />
    </Container>
  );
};

export default AppHeader;
