import {
  MembershipOptionContainer,
  MembershipOptionLabel,
  MembershipOptionLabelContainer,
  MembershipOptionSelectInputStyles,
  RemoveMembershipButtonContainer,
} from './MembershipOption.style';
import {
  UserFormInputs,
  UserFormInputsNames,
  UserFormMembershipType,
} from '../../../@types/UserFormInputs';

import { MdDelete } from 'react-icons/md';
import React from 'react';
import { Role } from '../../../../../__generated__/graphql';
import Select from 'react-select';
import SelectOption from '../../../../../@types/react/SelectOption';
import { UserFormMembership } from '../../../@types/UserFormMembershipMap';
import useAdjustMembershipsMap from '../../../hooks/useAdjustMembershipsMap';
import { useFormContext } from 'react-hook-form';

type MembershipOptionProps = {
  membership: UserFormMembership;
  fieldName: UserFormMembershipType;
};

export const MembershipOption: React.FC<MembershipOptionProps> = ({
  membership: { option, role },
  fieldName,
}) => {
  const { getValues, setValue } = useFormContext<UserFormInputs>();
  const { adjustMembershipRole, adjustMembershipsMap } = useAdjustMembershipsMap({ fieldName });

  const membershipsValueFieldName = `${fieldName}.${UserFormInputsNames.values}`;

  const removeMembership = (): void => {
    const currentValues: SelectOption[] = getValues(membershipsValueFieldName);

    const newValues = currentValues.filter((m) => option.value !== m.value);

    setValue(membershipsValueFieldName, newValues);

    adjustMembershipsMap(option, {
      action: 'remove-value',
      removedValue: option,
    });
  };

  const options: SelectOption[] = Object.values(Role).map((_role) => ({
    label: _role,
    value: `${_role}.${option.value}`,
  }));

  const value: SelectOption = {
    label: role,
    value: `${role}.${option.value}`,
  };

  return (
    <MembershipOptionContainer>
      <MembershipOptionLabelContainer>
        <MembershipOptionLabel>{option.label}</MembershipOptionLabel>
        <RemoveMembershipButtonContainer onClick={removeMembership}>
          <MdDelete size="18px" />
        </RemoveMembershipButtonContainer>
      </MembershipOptionLabelContainer>
      <Select
        onChange={adjustMembershipRole}
        options={options}
        styles={MembershipOptionSelectInputStyles}
        value={value}
      />
    </MembershipOptionContainer>
  );
};
