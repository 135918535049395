import 'styled-components/macro';

import SubmitRowContainerStyle, {
  SubmitRowMultiContainerStyle,
} from './styles/SubmitRowContainer.style';

import { Bars } from 'svg-loaders-react';
import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import CancelButton from '../CancelButton';
import { Colors } from '../../theme/colors';
import Container from '../Container';
import React from 'react';
import { RootState } from '../../redux/reducers';
import SubmitButton from '../SubmitButton';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export interface SubmitRowProps {
  disabled?: boolean;
  loading?: boolean;
  submitText: string;
  showCancelButton?: boolean;
  onCancelClick?: (itemId: string, locationId: string) => void | Promise<void>;
  cancelButtonText?: string;
}

const SubmitRow: React.FC<SubmitRowProps> = ({
  disabled = false,
  loading,
  submitText,
  showCancelButton,
  onCancelClick,
  cancelButtonText,
}: SubmitRowProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const { id } = useSelector((state: RootState) => state.modals.form);
  if (showCancelButton) {
    return (
      <Container css={SubmitRowMultiContainerStyle}>
        {loading && (
          <Button background={Colors.solitude} disabled model={ButtonModel.PASSIVE_CTA}>
            <Bars
              fill={Colors.shuttleGrey}
              height="25px"
              stroke={Colors.shuttleGrey}
              width="25px"
            />
          </Button>
        )}
        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {showCancelButton && (
            <CancelButton
              onClick={(e) => {
                e.preventDefault();
                onCancelClick?.(id as string, locationId);
              }}
            >
              {cancelButtonText}
            </CancelButton>
          )}
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {!loading && <SubmitButton disabled={disabled} value={submitText} />}
        </div>
      </Container>
    );
  }
  return (
    <Container css={SubmitRowContainerStyle}>
      {loading && (
        <Button background={Colors.solitude} disabled model={ButtonModel.PASSIVE_CTA}>
          <Bars fill={Colors.shuttleGrey} height="25px" stroke={Colors.shuttleGrey} width="25px" />
        </Button>
      )}
      {!loading && <SubmitButton disabled={disabled} value={submitText} />}
    </Container>
  );
};

export default SubmitRow;
