import 'styled-components/macro';

import ConfirmationModal from '../ConfirmationModal';
import React from 'react';
import RemoveHook from '../../../hooks/@types/RemoveHook';
import { closeRemoveModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';

interface RemoveModalProps {
  dataType: string;
  id: string;
  hook: RemoveHook;
  verbiage: string;
  onRemoveCompleted?: () => void;
}

const RemoveModal: React.FC<RemoveModalProps> = ({
  dataType,
  hook,
  id,
  verbiage,
  onRemoveCompleted,
}: RemoveModalProps) => {
  const { mutation } = hook({ onRemoveCompleted });
  const dispatch = useDispatch();

  function close(): void {
    dispatch(closeRemoveModal());
  }

  function handleSubmit(): void {
    mutation(id);
  }

  return (
    <ConfirmationModal
      titleText={`Are you sure you want to ${verbiage} this ${dataType}?`}
      onConfirm={handleSubmit}
      onClose={close}
    />
  );
};

export default RemoveModal;
