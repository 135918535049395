import 'styled-components/macro';

import React, { useEffect } from 'react';

import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { LocationsGroupTreeComponent } from './components';
import NoResultsTextStyle from '../../../../../../components/DataTable/styles/NoResultsText.style';
import Table from '../../../../../../components/DataTable/components/Table';
import TableBody from '../../../../../../components/DataTable/components/TableBody';
import TextContent from '../../../../../../components/TextContent';
import { useLocationsGroupsProvider } from '../../contexts/LocationsGroupsContext';

export const locationComponentKey = (locationId: string, parentId: string): string =>
  `Location-${locationId}(Parent-${parentId})`;

export const groupComponentKey = (groupId: string, parentId: string): string =>
  `Group-${groupId}(Parent-${parentId})`;

export const LocationGroupsTree: React.FC = () => {
  const { data, query, loading } = useLocationsGroupsProvider();

  useEffect(() => {
    query();
  }, []);

  const initialLevel = 1;

  const hasData = data !== undefined && !loading;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (hasData && data && data?.length > 0) {
    return (
      <Table columns={1}>
        <TableBody>
          {data.map((group) => (
            <LocationsGroupTreeComponent
              key={groupComponentKey(group.id, '')}
              group={group}
              level={initialLevel}
            />
          ))}
        </TableBody>
      </Table>
    );
  }

  return <TextContent css={NoResultsTextStyle}>No Results</TextContent>;
};
