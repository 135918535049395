import {
  RadioInputOptionContainer,
  RadioInputStyle,
  RadioInputsContainer,
} from './RadionInput.styles';
import React, { useEffect } from 'react';

import InputGroupProps from '../InputGroup/@types/InputGroupProps';
import { castToSelectOptions } from '../InputGroup/utils/selectInputUtils';
import { useFormContext } from 'react-hook-form';

type RadioInputProps = Pick<
  InputGroupProps,
  'name' | 'options' | 'defaultValue' | 'onChange' | 'rules'
>;

export const RadioInput: React.FC<RadioInputProps> = ({
  options,
  name,
  defaultValue,
  onChange,
  rules,
}: RadioInputProps) => {
  const { getValues, register, setValue } = useFormContext();

  const currentValue = getValues(name);

  useEffect(() => {
    register(name);
    setValue(name, defaultValue);
  }, []);

  const selectOptions = castToSelectOptions(options);

  const onChangeValue = async (newValue: string): Promise<void> => {
    if (rules) {
      const isValid = await rules.validate(newValue);
      if (!isValid) {
        return;
      }
    }

    setValue(name, newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <RadioInputsContainer>
      {selectOptions.map((option, index) => (
        <RadioInputOptionContainer
          key={`${index}-${option.value}`}
          checked={currentValue === option.value}
          onClick={(): Promise<void> => onChangeValue(option.value)}
        >
          <RadioInputStyle
            type="radio"
            value={option.value}
            checked={currentValue === option.value}
            onChange={(e): Promise<void> => onChangeValue(e.target.value)}
          />
          {option.label}
        </RadioInputOptionContainer>
      ))}
    </RadioInputsContainer>
  );
};
