import 'styled-components/macro';

import { DeepPartial, FormProvider, UnpackNestedValue, useForm } from 'react-hook-form';
import FormContext, { FormContextProps } from '../../contexts/form.context';
import Heading, { HeadingModel } from '../../components/Heading';
import React, { useState } from 'react';
import { closeForm, openConfirmationModal } from '../../redux/actions/modals.actions';

import Button from '../../components/Button';
import ButtonModel from '../../components/Button/enums/ButtonModel.enum';
import { Colors } from '../../theme/colors';
import Container from '../../components/Container';
import FORM_MODAL_STYLE from './constants/formModalStyle';
import FormContainerStyle from './styles/FormContainer.style';
import FormHeaderStyle from './styles/FormHeader.style';
import FormOuterContainerStyle from './styles/FormOuterContainer.style';
import FormSectionButton from '../../components/FormSectionButton';
import FormSectionsContainerStyle from './styles/FormSectionsContainer.style';
import FormTemplateProps from './@types/FormTemplateProps';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import { isEqual as objectsAreEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

export default function FormTemplate<MutationArgs extends {}, MutationData>({
  formHeader,
  identifier: id = '',
  initialValues,
  isModal = true,
  steps,
  validationSchema,
}: FormTemplateProps<MutationArgs, MutationData>): React.ReactElement {
  const dispatch = useDispatch();
  const [stepIndex, setStep] = useState<number>(0);
  const [identifier, setIdentifier] = useState<string>(id);

  const [stepsToDisplay, setStepsToDisplay] = useState<{ name: string }[] | null>(
    steps.length > 1 ? steps.map((step) => ({ name: step.name })) : null
  );

  const currentValidationSchema = steps[stepIndex]?.validationSchema ?? validationSchema;

  const methods = useForm<MutationArgs>({
    defaultValues: (initialValues as UnpackNestedValue<DeepPartial<MutationArgs>>) ?? undefined,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    ...(currentValidationSchema && { resolver: yupResolver(currentValidationSchema) }),
  });

  const { errors, watch } = methods;

  const allFields = watch();

  const FORM_CONTEXT_PROPS: FormContextProps = {
    identifier,
    initialValues,
    setIdentifier,
    setStep,
    setStepsToDisplay,
    step: stepIndex,
    stepsToDisplay,
  };

  const StepComponent: React.ComponentClass = steps[stepIndex]?.component ?? React.Fragment;

  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
    }

    return (): void => {
      document.body.style.overflow = 'initial';
    };
  }, [isModal]);

  if (isModal) {
    const onClose = (): void => {
      if (objectsAreEqual(initialValues ?? {}, allFields)) {
        dispatch(closeForm());
      } else {
        dispatch(
          openConfirmationModal({
            action: (): void => {
              dispatch(closeForm());
            },
            title: 'Please confirm that you would like to discard the changes',
          })
        );
      }
    };

    return (
      <FormContext.Provider value={FORM_CONTEXT_PROPS}>
        <Modal
          isOpen
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          style={FORM_MODAL_STYLE}
        >
          <Container css={FormOuterContainerStyle}>
            <Container css={FormContainerStyle} id={`modal-form`}>
              <Container css={FormHeaderStyle}>
                {formHeader && <Heading model={HeadingModel.SECONDARY}>{formHeader}</Heading>}
                <Button
                  background={Colors.blackRussian}
                  model={ButtonModel.PASSIVE_ICON_BUTTON}
                  onClick={onClose}
                >
                  <MdClose size="22px" />
                </Button>
              </Container>
              {!stepsToDisplay && <div />}
              {stepsToDisplay && (
                <Container css={FormSectionsContainerStyle}>
                  {steps?.map((step, index) => (
                    <FormSectionButton
                      active={index === stepIndex}
                      disabled={index === stepIndex || !step.canBeAccessed(allFields, errors ?? {})}
                      formSection={step}
                      index={index}
                      key={`ag-form-section-${step}-${index}`}
                      setFormSection={setStep}
                    />
                  ))}
                </Container>
              )}
              <FormProvider {...methods}>
                <StepComponent />
              </FormProvider>
            </Container>
          </Container>
        </Modal>
      </FormContext.Provider>
    );
  }

  return (
    <FormContext.Provider value={FORM_CONTEXT_PROPS}>
      <Container>
        <FormProvider {...methods}>{steps.map((step) => step.component)}</FormProvider>
      </Container>
    </FormContext.Provider>
  );
}
