import 'styled-components/macro';

import Container from '../../Container';
import Theme from '../../../theme';
import styled from 'styled-components';

export const DataTableContainer = styled(Container)`
  background: ${Theme.colors.darkAlternate.hexCode};
  border-radius: 24px;
  grid-auto-rows: auto;
  grid-template-rows: repeat(2, max-content);
  min-height: 800px;
  padding: 30px 30px 35px;
  gap: 16px;
`;
