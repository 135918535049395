import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessGrantForm from '../AccessGrantForm';
import AccessGrantPollReservationForm from '../AccessGrantPollReservationForm';
import AmenityForm from '../AmenityForm';
import ConciergeForm from '../ConciergeTree/form';
import { ConciergeItem } from '../../__generated__/graphql';
import ConfirmationModal from '../ConfirmationsModal/ConfirmationModal';
import IncomingCallModals from '../IncomingCallModals';
import InfoItemForm from '../InfoItemForm';
import JumioIDModal from '../JumioIDModal';
import KioskActionsModal from '../KioskActionsModal';
import KioskForm from '../KioskForm';
import KioskIssueKeycardForm from '../KioskIssueKeycardForm';
import LocationForm from '../LocationForm';
import { LocationsGroupForm } from '../LocationsGroupForm';
import RemoveModal from '../ConfirmationsModal/RemoveModal';
import ResourceForm from '../ResourceForm';
import ResourceGroupForm from '../ResourceGroupForm';
import ResourceImportForm from '../ResourceImportForm';
import { RootState } from '../../redux/reducers';
import ServiceForm from '../ServiceForm';
import UnitTypeForm from '../UnitTypeForm';
import UnitTypeImportForm from '../UnitTypeImportForm';
import UserForm from '../UserForm';
import UsersImportForm from '../UsersImportForm';
import VendorServerForm from '../VendorServerForm';
import VideoCallModal from '../VideoCallModal';
import { closeModal } from '../../redux/actions/modals.actions';

const Modals: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const modals = useSelector((state: RootState) => state.modals);
  const incomingCalls = useSelector((state: RootState) => state.remoteAssistance.incomingCalls);
  const activeCall = useSelector((state: RootState) => state.remoteAssistance.activeCall);
  const incomingCallOpen = useSelector((state: RootState) => state.modals.incomingCall);
  const videoToken = useSelector((state: RootState) => state.remoteAssistance.videoToken);
  const videoCallOpen = useSelector((state: RootState) => state.modals.videoCall);

  function canOpenRemoveModal(): boolean {
    return Boolean(modals.remove.dataType && modals.remove.id && modals.remove.isOpen);
  }

  function canOpenConfirmationModal(): boolean {
    return Boolean(modals.confirmationModal?.action);
  }

  function closeKioskModal(): void {
    dispatch(closeModal('kioskActions'));
  }

  return (
    <>
      {modals.form.name === 'accessGrant' && (
        <AccessGrantForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm}
          id={modals.form.id}
        />
      )}
      {modals.form.name === 'accessGrant-import' && (
        <AccessGrantPollReservationForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm}
        />
      )}
      {modals.form.name === 'location' && <LocationForm id={modals.form.id} />}
      {modals.form.name === 'kiosk' && (
        <KioskForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm}
          id={modals.form.id}
        />
      )}
      {modals.form.name === 'KioskIssueKeycardForm' && (
        <KioskIssueKeycardForm id={modals.form.id} />
      )}
      {modals.form.name === 'resource' && (
        <ResourceForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm}
          id={modals.form.id}
        />
      )}
      {modals.form.name === 'resource-import' && <ResourceImportForm />}
      {modals.form.name === 'unitType' && (
        <UnitTypeForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm}
          id={modals.form.id}
        />
      )}
      {modals.form.name === 'unitType-import' && <UnitTypeImportForm />}
      {modals.form.name === 'amenity' && (
        <AmenityForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm}
          id={modals.form.id}
        />
      )}
      {modals.form.name === 'infoItem' && (
        <InfoItemForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm}
          id={modals.form.id}
        />
      )}
      {modals.form.name === 'concierge' && (
        <ConciergeForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm as ConciergeItem}
          id={modals.form.id}
        />
      )}
      {modals.form.name === 'service' && (
        <ServiceForm
          aditionalDataOnOpeningForm={modals.form.aditionalDataOnOpeningForm}
          id={modals.form.id}
        />
      )}
      {modals.form.name === 'vendorServer' && <VendorServerForm id={modals.form.id} />}
      {modals.form.name === 'user' && <UserForm />}
      {modals.form.name === 'user-import' && <UsersImportForm />}
      {modals.form.name === 'jumioID' && <JumioIDModal userId={modals.form.id} />}
      {modals.form.name === 'resourceGroup' && <ResourceGroupForm />}
      {modals.form.name === 'locationsGroup' && <LocationsGroupForm />}

      {incomingCallOpen && incomingCalls.length > 0 && <IncomingCallModals />}

      {videoCallOpen && activeCall?.kiosk && (
        <KioskActionsModal
          accessGrantId={activeCall.accessGrant?.id}
          close={closeKioskModal}
          kioskId={activeCall.kiosk.id}
        />
      )}

      {videoCallOpen && videoToken && activeCall && (
        <VideoCallModal activeCall={activeCall} videoToken={videoToken} />
      )}

      {canOpenRemoveModal() && (
        <RemoveModal
          dataType={modals.remove.dataType as string}
          id={modals.remove.id as string}
          hook={modals.remove.hook}
          verbiage={modals.remove.verbiage}
          onRemoveCompleted={modals.remove.onRemoveCompleted}
        />
      )}

      {canOpenConfirmationModal() && (
        <ConfirmationModal
          onConfirm={modals.confirmationModal!.action}
          onClose={modals.confirmationModal!.onClose}
          onReject={modals.confirmationModal!.onReject}
          titleText={modals.confirmationModal!.title}
          messageText={modals.confirmationModal!.message}
        />
      )}
    </>
  );
};

export default memo(Modals);
