import { CreateLocationsGroupData, UpdateLocationsGroupData } from '../@types';

import FormTemplateStep from '../../../templates/Form/@types/FormTemplateStep';
import { LocationsGroupFormInputsType } from '../@types/LocationsGroupFormInputsType';
import { LocationsGroupFormSection } from '../components/LocationsGroupFormSection';

export const LOCATIONS_GROUP_FORM_STEPS: FormTemplateStep<
  LocationsGroupFormInputsType,
  CreateLocationsGroupData | UpdateLocationsGroupData
>[] = [
  {
    canBeAccessed: (): boolean => true,
    component: LocationsGroupFormSection,
    name: 'Group',
  },
];
