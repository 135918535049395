import 'styled-components/macro';

import React, { Fragment, useState } from 'react';
import TextContent, { TextContentModel } from '../TextContent';
import { useFieldArray, useFormContext } from 'react-hook-form';

import Container from '../Container';
import { DefaultRequirement } from '../../__generated__/graphql';
import InputGroupProps from '../InputGroup/@types/InputGroupProps';
import { InputLabel } from '../InputGroup/styles/InputGroupLabel.style';
import { Metadata } from './Metadata';
import MultiSelectContainerStyle from './styles/MultiSelectContainer.style';
import MultiSelectItemStyle from './styles/MultiSelectItem.stye';
import MultiSelectOptionsContainerStyle from './styles/MultiSelectOptionsContainer.style';
import reduce from 'lodash/reduce';
import startCase from 'lodash/startCase';

interface MultiSelectProps extends Omit<InputGroupProps, 'type'> {
  formatOption?: (option: string) => void;
  options: string[];
}

const MultiSelect: React.FC<MultiSelectProps> = ({ label, name, options }: MultiSelectProps) => {
  const { getValues, control } = useFormContext();

  const currentValue: DefaultRequirement[] = getValues(name) ?? [];

  const optionsMap: { [key: string]: boolean } = reduce(
    options,
    (acc, option) => ({
      ...acc,
      [`${option}`]: false,
    }),
    {}
  );

  currentValue.forEach((value: { type: string }) => {
    optionsMap[value.type] = true;
  });

  const [valuesMap, setValues] = useState(optionsMap);

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'defaultRequirements.values',
  });

  const getFieldIndex = (option: string): number => {
    return fields.findIndex((field) => field.type === option);
  };

  function handleChange(e: React.MouseEvent<HTMLDivElement>): void {
    const option = e.currentTarget.getAttribute('data-identifier');

    if (option) {
      const newState = {
        ...valuesMap,
        [option]: !valuesMap[option] ?? true,
      };
      setValues(newState);

      const index = fields.findIndex((field) => field.type === option);

      if (index === -1) {
        append({
          type: option,
        });
      } else {
        remove(index);
      }
    }
  }

  function handleSpecialUpperCase(displayValue: string): string {
    let handleSpecialUpperCaseString = displayValue;
    const specialUpperCaseTexts = ['id', 'mrz', 'pms'];
    handleSpecialUpperCaseString = displayValue
      .split(' ')
      .reduce((result, splitDisplayValue) => {
        if (specialUpperCaseTexts.includes(splitDisplayValue.toLowerCase())) {
          result.push(splitDisplayValue.toUpperCase());
        } else {
          result.push(splitDisplayValue);
        }
        return result;
      }, [] as string[])
      .join(' ');
    return handleSpecialUpperCaseString;
  }

  return (
    <Container css={MultiSelectContainerStyle}>
      {label && <InputLabel>{label}</InputLabel>}
      <Container css={MultiSelectOptionsContainerStyle}>
        {options.map((option) => {
          const displayValue = handleSpecialUpperCase(
            startCase(option.split(/[-_]/).join(' ').toLowerCase())
          );
          return (
            <Fragment key={`multi-select-${option}`}>
              <Container
                className={valuesMap[option] ? 'active' : ''}
                data-identifier={option}
                css={MultiSelectItemStyle}
                key={`multi-select-option-${option}`}
                onClick={handleChange}
              >
                <TextContent model={TextContentModel.BODY_LARGE}>{displayValue}</TextContent>
              </Container>
              {valuesMap[option] && <Metadata option={option} index={getFieldIndex(option)} />}
            </Fragment>
          );
        })}
      </Container>
    </Container>
  );
};

export default MultiSelect;
