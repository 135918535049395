import { gql } from '../../../__generated__/gql';

export const CAN_ASSOCIATE_LOCATION_GROUP = gql(`
  query CanAssociateLocationGroup(
    $locationGroupId: String!,
    $parentLocationGroupId: String!
  ) {
    canAssociateLocationGroup(
      locationGroupId: $locationGroupId
      parentLocationGroupId: $parentLocationGroupId
    )
  }
`);
