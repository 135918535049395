import React, { useEffect } from 'react';
import { TabButton, TabIcon, TabsContainer } from './LocationsPageStyle';
import { useHistory, useLocation } from 'react-router-dom';

import Banner from '../../components/Banner';
import BaseLayout from '../../templates/BaseLayout';
import Container from '../../components/Container';
import { GroupIcon } from '../../assets/icons/GroupIcon';
import { GroupsLocationsTab } from './components/Groups';
import { LocationsTab } from './components/LocationsTab';
import MarkerIcon from '../../assets/icons/LocationIcon';
import qs from 'qs';
import { useLocationsGroupsAvailability } from './components/Groups/hooks/useLocationsGroupsAvailability';

type LocationTabType = {
  key: string;
  title: string;
  Icon: React.FC<{
    color?: string;
  }>;
};

const LocationsTabs: { [key: string]: LocationTabType } = {
  LOCATIONS: {
    key: 'locations',
    title: 'Locations',
    Icon: MarkerIcon,
  },
  GROUPS: {
    key: 'groups',
    title: 'Groups',
    Icon: GroupIcon,
  },
};

export const LocationsPage: React.FC = () => {
  const { locationsGroupsIsAvailable } = useLocationsGroupsAvailability();

  const history = useHistory();
  const location = useLocation();

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const tabParam = queryParams.tab as string;

  const defaultTab = LocationsTabs.LOCATIONS;
  const activeTab = Object.values(LocationsTabs).find((tab) => tab.key === tabParam) ?? defaultTab;

  const switchToTab = (newTab: LocationTabType): void => {
    const params = qs.stringify({ tab: newTab.key });

    history.push({
      pathname: location.pathname,
      search: params,
    });
  };

  useEffect(() => {
    if (locationsGroupsIsAvailable) {
      switchToTab(activeTab);
    }
  }, []);

  if (!locationsGroupsIsAvailable) {
    return (
      <BaseLayout>
        <Container>
          <Banner text="Locations" subText="Virdee" />
          <LocationsTab />
        </Container>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Container>
        <Banner text="Locations" subText="Virdee" />

        <TabsContainer>
          {Object.values(LocationsTabs).map((tab) => {
            const { key, Icon, title } = tab;

            const isActive = activeTab.key === key;

            return (
              <TabButton key={key} isActive={isActive} onClick={(): void => switchToTab(tab)}>
                <TabIcon Icon={Icon} isActive={isActive} /> {title}
              </TabButton>
            );
          })}
        </TabsContainer>

        {activeTab.key === LocationsTabs.LOCATIONS.key && <LocationsTab />}
        {activeTab.key === LocationsTabs.GROUPS.key && <GroupsLocationsTab />}
      </Container>
    </BaseLayout>
  );
};

export default LocationsPage;
