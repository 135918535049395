import TextContent, { TextContentModel } from '../TextContent';

import { Colors } from '../../theme/colors';
import React from 'react';

type InputErrorProps = {
  error: string;
};

export const InputError: React.FC<InputErrorProps> = ({ error }) => {
  return (
    <TextContent
      color={Colors.salmon}
      margin="5px 0 0"
      model={TextContentModel.BODY_SMALL}
      role="alert"
    >
      {error}
    </TextContent>
  );
};
