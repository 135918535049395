import { LocationRelationFilter, TenantRelationFilter } from '../../__generated__/graphql';

import { ActiveMembership } from '../../redux/reducers/@types/MeReduxState';
import { MembershipAccessLevelEnum } from '../membership/types';
import { getMembershipAccessLevel } from '../membership/membershipUtils';

type MembershipQueryWhere = {
  tenant: TenantRelationFilter;
  location?: LocationRelationFilter;
};

export default function generateMembershipQuery(
  membership: ActiveMembership
): MembershipQueryWhere {
  const tenantRelationFilter: TenantRelationFilter = {
    is: {
      id: {
        equals: membership.tenant.id,
      },
    },
  };

  let locationRelationFilter: LocationRelationFilter | undefined;
  const membershipAccessLevel = getMembershipAccessLevel(membership);
  if (membershipAccessLevel === MembershipAccessLevelEnum.location) {
    locationRelationFilter = {
      is: {
        id: {
          equals: membership.location?.id,
        },
      },
    };
  } else if (membershipAccessLevel === MembershipAccessLevelEnum.locationGroup) {
    locationRelationFilter = {
      is: {
        id: {
          in: membership.locationGroup?.locationIds ?? [],
        },
      },
    };
  }

  return {
    tenant: tenantRelationFilter,
    location: locationRelationFilter,
  };
}
