import React from 'react';

export const ExpandItemIcon: React.FC<{
  color: string;
}> = ({ color }) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5938 0.578125L12 1.98438L6 7.98438L0 1.98438L1.40625 0.578125L6 5.17188L10.5938 0.578125Z"
        fill={color}
      />
    </svg>
  );
};
