import {
  UseLoadGroupsOptionsType,
  useLoadGroupsOptions,
} from '../../../hooks/useLoadGroupsOptions/useLoadGroupsOptions';

import { LocationsGroupChildrenTypeEnum } from '../constants/locationsGroupFormInputs';
import { LocationsGroupFormInputsType } from '../@types/LocationsGroupFormInputsType';
import SelectOption from '../../../@types/react/SelectOption';
import { useFormContext } from 'react-hook-form';

export const useLoadNotSelectedGroupsOptions = (): UseLoadGroupsOptionsType => {
  const { getValues } = useFormContext<LocationsGroupFormInputsType>();

  const selectedGroups = getValues(
    `${LocationsGroupChildrenTypeEnum.groups}.values`
  ) as SelectOption[];
  const selectedGroupsIds = selectedGroups?.map((g) => g.value) ?? [];

  return useLoadGroupsOptions({ groupsFilteredOut: selectedGroupsIds });
};
