import 'styled-components/macro';

import * as yup from 'yup';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import React, { useCallback, useEffect } from 'react';

import AuthenticationFormErrorTextStyle from '../../../../components/AuthenticationBaseComponent/styles/AuthenticationFormErrorText.style';
import AuthenticationFormSubmit1stButtonStyle from '../../../../components/AuthenticationBaseComponent/styles/AuthenticationFormSubmit1stButtonStyle.style';
import ChooseLocationInputStyle from './style/ChooseLocationInput.style';
import ChooseLocationLabel from './style/ChooseLocationLabel.style';
import Form from '../../../../components/Form';
import SelectInput from '../../../../components/SelectInput';
import SelectOption from '../../../../@types/react/SelectOption';
import SubmitButton from '../../../../components/SubmitButton';
import { TenantMembership } from '../../../../__generated__/graphql';
import TextContent from '../../../../components/TextContent';
import { capitalize } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';

interface ChooseLocationProps {
  tenantMemberships: TenantMembership[];
  onLocationChosen: (locationId: string) => void;
  loading: boolean;
}

interface ChooseLocationFormInputs {
  tenantId?: SelectOption;
  locationId?: SelectOption;
}

const chooseLocationFormSchema = yup.object().shape({
  locationId: yup.object().shape({
    label: yup.string().required('Location is required'),
    value: yup.string().required('Location is required'),
  }),
  tenantId: yup.object().shape({
    label: yup.string().required('Tenant is required'),
    value: yup.string().required('Tenant is required'),
  }),
});

const ChooseLocationForm: React.FC<ChooseLocationProps> = ({
  tenantMemberships,
  onLocationChosen,
  loading,
}: ChooseLocationProps) => {
  const formMethods = useForm<ChooseLocationFormInputs>({
    mode: 'onTouched',
    resolver: yupResolver(chooseLocationFormSchema),
  });
  const { errors, handleSubmit, setValue, watch } = formMethods;

  const selectedTenantId = watch('tenantId');
  const selectedLocationId = watch('locationId');

  const getSelectedTenant = useCallback((): TenantMembership | undefined => {
    if (selectedTenantId) {
      const tenant = tenantMemberships.find((_tenant) => _tenant.id === selectedTenantId.value);
      if (tenant) {
        return tenant;
      }
    }

    return undefined;
  }, [selectedTenantId, tenantMemberships]);

  useEffect(() => {
    const selectedTenant = getSelectedTenant();

    if (selectedTenant && selectedLocationId && selectedTenant.locations) {
      if (
        selectedTenant.locations.findIndex(
          (_location) => _location.id === selectedLocationId.value
        ) === -1
      ) {
        setValue('locationId', null);
      }
    } else {
      setValue('locationId', null);
    }
  }, [selectedTenantId, selectedLocationId, getSelectedTenant, setValue]);

  const onSubmit: SubmitHandler<ChooseLocationFormInputs> = async ({
    locationId,
  }): Promise<void> => {
    if (locationId) {
      onLocationChosen(locationId.value);
    }
  };

  useEffect(() => {
    if (tenantMemberships.length === 1) {
      const [tenant] = tenantMemberships;
      setValue('tenantId', {
        label: tenant.name,
        value: tenant.id,
      });

      const { locations } = tenant;
      if (locations && locations.length === 1) {
        const [location] = locations;
        setValue('locationId', {
          label: location.name,
          value: location.id,
        });
      }
    }
  }, [setValue, tenantMemberships]);

  const tenantsOptions: SelectOption[] = tenantMemberships.map((tenant) => {
    return {
      label: tenant.name,
      value: tenant.id,
    };
  });

  const getLocationsOptions = (): SelectOption[] => {
    const tenant = getSelectedTenant();
    if (tenant && tenant.locations) {
      return tenant.locations.map((location) => {
        return {
          label: location.name,
          value: location.id,
        };
      });
    }

    return [];
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formMethods}>
        <ChooseLocationLabel>Tenant</ChooseLocationLabel>
        <SelectInput
          css={ChooseLocationInputStyle}
          className={errors.tenantId ? 'error' : ''}
          name={'tenantId'}
          options={tenantsOptions}
        />
        {errors.tenantId && (
          <TextContent css={AuthenticationFormErrorTextStyle}>
            {capitalize(errors.tenantId?.value?.message)}
          </TextContent>
        )}
        <ChooseLocationLabel>Location</ChooseLocationLabel>
        <SelectInput
          disabled={!selectedTenantId}
          css={ChooseLocationInputStyle}
          className={errors.locationId ? 'error' : ''}
          name={'locationId'}
          options={getLocationsOptions()}
        />
        {errors.locationId && (
          <TextContent css={AuthenticationFormErrorTextStyle}>
            {capitalize(errors.locationId?.value?.message)}
          </TextContent>
        )}

        <SubmitButton
          css={AuthenticationFormSubmit1stButtonStyle}
          disabled={loading}
          type="submit"
          value="Connect to Cloudbeds"
        />
      </FormProvider>
    </Form>
  );
};

export default ChooseLocationForm;
