import {
  CreateLocationsGroupData,
  GetLocationsGroupData,
  GetLocationsGroupProps,
  UpdateLocationsGroupData,
} from './@types';
import {
  LocationsGroupChildrenType,
  LocationsGroupChildrenTypeEnum,
  LocationsGroupFormInputsNames,
} from './constants/locationsGroupFormInputs';
import React, { useEffect } from 'react';

import FormTemplate from '../../templates/Form';
import { GET_LOCATION_GROUP } from './graphql/getLocationGroup';
import { LOCATIONS_GROUP_FORM_STEPS } from './constants/locationsGroupFormSteps';
import { LOCATIONS_GROUP_FORM_VALIDATION_SCHEMA } from './constants/locationsGroupFormValidationSchema';
import { LocationsGroupFormInputsType } from './@types/LocationsGroupFormInputsType';
import { RootState } from '../../redux/reducers';
import SelectOption from '../../@types/react/SelectOption';
import { flashApolloError } from '../../util/errorUtils';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

export const LocationsGroupForm: React.FC = () => {
  const id = useSelector((state: RootState) => state.modals.form.id);

  const [getLocationGroup, { data, loading }] = useLazyQuery<
    GetLocationsGroupData,
    GetLocationsGroupProps
  >(GET_LOCATION_GROUP, {
    fetchPolicy: 'network-only',
    onError: flashApolloError,
  });

  useEffect(() => {
    if (id) {
      getLocationGroup({
        variables: {
          locationGroupId: id,
        },
      });
    }
  }, [getLocationGroup, id]);

  const locationGroup = data?.getLocationGroup;

  if (id && (loading || !locationGroup)) {
    return <div>Loading...</div>;
  }

  // Fill the initial values when editing
  if (id && locationGroup) {
    // Map and sort the locations alphabetically
    const locations: SelectOption[] =
      locationGroup.locations
        ?.map((location) => ({ label: location.name, value: location.id }))
        .sort((lA, lB) => lA.label.localeCompare(lB.label)) ?? [];

    // Map and sort the groups alphabetically
    const groups: SelectOption[] =
      locationGroup.groups
        ?.map((group) => ({ label: group.name, value: group.id }))
        .sort((gA, gB) => gA.label.localeCompare(gB.label)) ?? [];

    let childrenType: LocationsGroupChildrenType = LocationsGroupChildrenTypeEnum.locations;
    if (locations.length > 0) {
      childrenType = LocationsGroupChildrenTypeEnum.locations;
    } else if (groups.length > 0) {
      childrenType = LocationsGroupChildrenTypeEnum.groups;
    }

    const initialValues: Partial<LocationsGroupFormInputsType> = {
      name: locationGroup.name,
      locations: { values: locations },
      groups: { values: groups },
      [LocationsGroupFormInputsNames.ChildrenType]: childrenType,
    };

    return (
      <FormTemplate<LocationsGroupFormInputsType, UpdateLocationsGroupData>
        initialValues={initialValues}
        identifier={id}
        formHeader="Edit Group"
        steps={LOCATIONS_GROUP_FORM_STEPS}
        validationSchema={LOCATIONS_GROUP_FORM_VALIDATION_SCHEMA}
      />
    );
  }

  const defaultInitialValues: Partial<LocationsGroupFormInputsType> = {
    [LocationsGroupFormInputsNames.ChildrenType]: LocationsGroupChildrenTypeEnum.locations,
  };

  return (
    <FormTemplate<LocationsGroupFormInputsType, CreateLocationsGroupData>
      formHeader="Create Group"
      initialValues={defaultInitialValues}
      steps={LOCATIONS_GROUP_FORM_STEPS}
      validationSchema={LOCATIONS_GROUP_FORM_VALIDATION_SCHEMA}
    />
  );
};
