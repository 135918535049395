import { GroupBase, StylesConfig } from 'react-select';

import { Colors } from '../../../theme/colors';
import SelectOption from '../../../@types/react/SelectOption';
import Theme from '../../../theme';

export const SelectInputContainerStyle = `
  border-radius: 8px;

  &.error {
    border: 2px solid ${Colors.salmon};
  }

  &:focus {
    border: 2px solid ${Colors.mountainMeadow};
  }
`;

export const SelectInputStyles: Partial<StylesConfig<
  SelectOption,
  false,
  GroupBase<SelectOption>
>> = {
  control: () => ({
    background: 'rgba(209,210,214,.2)',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    height: '40px',
  }),
  singleValue: () => ({
    gridArea: '1 / 1 / 2 / 3',
    color: Colors.blackRussian,
    flexWrap: 'wrap',
    fontSize: Theme.fontSizes.bodyLarge,
    fontWeight: 'bold',
  }),
};
