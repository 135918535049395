import { UpdateLocationsGroupArgs, UpdateLocationsGroupData } from '../@types';

import MutationHookData from '../../../hooks/@types/MutationHookData';
import MutationHookParams from '../../../hooks/@types/MutationHookParams';
import { UPDATE_LOCATIONS_GROUP } from '../graphql/updateLocationsGroup';
import { closeForm } from '../../../redux/actions/modals.actions';
import { flashApolloError } from '../../../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useLocationsGroups } from '../../../pages/Locations/components/Groups/hooks/useLocationsGroups';
import { useMutation } from '@apollo/client';

type UseUpdateLocationsGroupProps = MutationHookParams<UpdateLocationsGroupData>;
type UseUpdateLocationsGroup = MutationHookData<UpdateLocationsGroupArgs, UpdateLocationsGroupData>;

export const useUpdateLocationsGroup = ({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: UseUpdateLocationsGroupProps = {}): UseUpdateLocationsGroup => {
  const { refetchQueryOption } = useLocationsGroups();

  const dispatch = useDispatch();

  const [update, { data, error, loading }] = useMutation<
    UpdateLocationsGroupData,
    UpdateLocationsGroupArgs
  >(UPDATE_LOCATIONS_GROUP, {
    onCompleted: ({ updateLocationGroup }) => {
      if (updateLocationGroup) {
        window.flash({
          message: `Group: ${updateLocationGroup.name} updated successfully`,
        });

        onCompleted({ updateLocationGroup });
      } else {
        window.flash({ message: 'Fail to update group' });
      }

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: UpdateLocationsGroupArgs): void => {
      update({
        refetchQueries: [refetchQueryOption],
        variables: inputs,
      });
    },
  };
};
