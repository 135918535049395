/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GroupBase, StylesConfig } from 'react-select';

import { Colors } from '../../../../../theme/colors';
import Container from '../../../../Container';
import { InputLabel } from '../../../../InputGroup/styles/InputGroupLabel.style';
import { SelectInputStyles } from '../../../../SelectInput/styles/SelectInput.style';
import SelectOption from '../../../../../@types/react/SelectOption';
import styled from 'styled-components';

export const MembershipOptionSelectInputStyles: Partial<StylesConfig<
  SelectOption,
  false,
  GroupBase<SelectOption>
>> = {
  ...SelectInputStyles,
  container: () => ({
    ...SelectInputStyles.container,
    width: '100%',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    ...SelectInputStyles.singleValue,
    fontWeight: 'normal',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    top: 'auto',
  }),
};

export const MembershipOptionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const MembershipOptionLabel = styled(InputLabel)`
  margin: 0;
`;

export const MembershipOptionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;

export const RemoveMembershipButtonContainer = styled.button`
  display: flex;
  background: transparent;
  border: 0;
  padding: 0;
  color: ${Colors.freeSpeechRed};
`;
