import styled, { css } from 'styled-components';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Container from '../Container';
import DefaultTextInputStyle from '../TextInput/styles/DefaultTextInput.style';

type SegmentedPickerOptionProps = {
  checked: boolean;
};

export const SegmentedPickerContainer = styled(Container)`
  display: flex;
  height: 40px;
  padding: 3px;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background-color: ${DefaultTextInputStyle.default.background};
`;

export const SegmentedPickerOptionContainer = styled.div<SegmentedPickerOptionProps>`
  display: flex;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  font-weight: 600;
  cursor: pointer;

  ${({ checked }) =>
    checked &&
    css`
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
    `}
`;

export const SegmentedPickerOption = styled.input`
  display: none;
`;
