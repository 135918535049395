import 'styled-components/macro';

import { DataTableContainer } from '../../../../components/DataTable/components/DataTableContainer';
import { GroupsDataHeader } from './components/GroupsDataHeader';
import { LocationGroupsTree } from './components/LocationsGroupsTree';
import { LocationsGroupsTreeProvider } from './contexts/LocationsGroupsContext';
import React from 'react';

export const GroupsLocationsTab: React.FC = () => {
  return (
    <LocationsGroupsTreeProvider>
      <DataTableContainer>
        <GroupsDataHeader />
        <LocationGroupsTree />
      </DataTableContainer>
    </LocationsGroupsTreeProvider>
  );
};
