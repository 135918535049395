import { gql } from '../../../__generated__/gql';

export const SEARCH_GROUPS = gql(`
  query SearchGroups(
    $orderBy: [LocationGroupOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: LocationGroupWhereInput
  ) {
    locationGroups(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      id
      name
    }
  }
`);
