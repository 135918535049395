import { MutationUpdateUserArgs, UserUpdateInput } from '../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import CreateUserFormInputs from '../components/UserForm/@types/CreateUserFormInputs';
import EditUserFormInputs from '../components/UserForm/@types/EditUserFormInputs';
import GET_USER from '../graphql/getUser';
import ME from '../graphql/me';
import { Mutation } from '../__generated__/graphql';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { RootState } from '../redux/reducers';
import UPDATE_USER from '../graphql/updateUser';
import { UserDashboardPreferences } from '../@types/client/DashboardPreferences';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import generateMembershipInputs from './util/userMemberships/generateMembershipInputs';
import links from '../util/links';
import transformCreateInputIntoUpdateInput from '../util/api/transformCreateInputIntoUpdateInput';
import { useMutation } from '@apollo/client';

type UpdateUserData = Pick<Mutation, 'updateUser'>;

export default function useUpdateUser({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<UpdateUserData> = {}): MutationHookData<
  CreateUserFormInputs,
  UpdateUserData
> {
  const me = useSelector((state: RootState) => state.me);
  const dispatch = useDispatch();

  const { activeMembership } = me;

  const onCompletedEndFunction = ({ updateUser }: UpdateUserData): void => {
    dispatch(closeForm());

    window.flash({
      link: `${links.paths.USERS}/${updateUser.id}`,
      message: `User: ${updateUser.name} updated successfully`,
    });

    onCompleted({ updateUser });
  };

  const [update, { data, error, loading }] = useMutation<UpdateUserData, MutationUpdateUserArgs>(
    UPDATE_USER,
    {
      onCompleted: onCompletedEndFunction,
      onError,
    }
  );

  return {
    data,
    error,
    loading,
    mutation: (inputs: EditUserFormInputs, id = ''): void => {
      const groupMemberships = inputs.groups.map ?? {};
      const locationMemberships = inputs.locations?.map ?? {};

      const { role, tenantMembership, userAccessType } = inputs;

      const {
        create: membershipsToCreate,
        delete: membershipsToDelete,
        update: membershipsToUpdate,
      } = generateMembershipInputs({
        locationMemberships,
        groupMemberships,
        activeMembership,
        role,
        tenantMembership,
        userAccessType,
      });

      const dashboardPreferences: UserDashboardPreferences | undefined = {
        preferredDateFormat: inputs.dashboardPreferences?.preferredDateFormat?.value,
        preferredTimeFormat: inputs.dashboardPreferences?.preferredTimeFormat?.value,
      };

      const updateInputs: UserUpdateInput = {
        ...transformCreateInputIntoUpdateInput<UserUpdateInput>({
          email: inputs.email,
          name: inputs.name,
          password: inputs.password || '',
          phoneNumber: inputs.phoneNumber,
        }),
        dashboardPreferences,
        memberships: {
          create: membershipsToCreate,
          deleteMany: membershipsToDelete,
          updateMany: membershipsToUpdate,
        },
      };
      const userIsUpdatingHimself = id === me.id;

      update({
        refetchQueries: [
          {
            query: GET_USER,
            variables: { where: { id: { equals: id } } },
          },
          ...(userIsUpdatingHimself
            ? [
                {
                  query: ME,
                },
              ]
            : []),
        ],
        variables: {
          data: updateInputs,
          where: { id },
        },
      });
    },
  };
}
