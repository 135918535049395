import { GroupBase, OptionProps } from 'react-select';
import {
  MembershipLevelGroupIcon,
  MembershipLevelLocationIcon,
  MembershipLevelTenantIcon,
} from './MembershipLevelIconStyle';

import { MembershipSelectorOptionProps } from '../MembershipSelectorOption/MembershipSelectorOptionStyles';
import { MembershipSelectorOptionType } from '../../types';
import React from 'react';
import { membershipGroupsLabel } from '../../functions/generateMembershipOptions';

type SelectorProps = Pick<
  OptionProps<MembershipSelectorOptionType, false, GroupBase<MembershipSelectorOptionType>>,
  'options'
>;

type MembershipLevelIconProps = MembershipSelectorOptionProps & {
  option: MembershipSelectorOptionType;
  selectorProps: SelectorProps;
};

export const membershipLevelIconLabel = {
  [membershipGroupsLabel.tenant]: MembershipLevelTenantIcon,
  [membershipGroupsLabel.location]: MembershipLevelLocationIcon,
  [membershipGroupsLabel.group]: MembershipLevelGroupIcon,
};

export const MembershipLevelIcon: React.FC<MembershipLevelIconProps> = (props) => {
  const { option, selectorProps } = props;
  const options = selectorProps.options as GroupBase<MembershipSelectorOptionType>[];

  const groupLabel = options.find((_groupOptions) =>
    _groupOptions.options.find((_option) => _option.value === option.value)
  )?.label;

  if (!groupLabel) {
    return <></>;
  }

  const membershipLevelIcon = membershipLevelIconLabel[groupLabel];

  return membershipLevelIcon({ isSelected: props.isSelected });
};
