import { ActionMeta, Options } from 'react-select';

import FormCrudAction from '../../../enums/FormCrudAction.enum';
import { LocationsGroupFormInputsNames } from '../constants/locationsGroupFormInputs';
import { LocationsGroupFormInputsType } from '../@types/LocationsGroupFormInputsType';
import { RootState } from '../../../redux/reducers';
import SelectOption from '../../../@types/react/SelectOption';
import { pull } from 'lodash';
import { useCanAssociateLocationGroup } from './useCanAssociateLocationGroup';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

type UseOnSelectGroupType = {
  onSelectGroup: (
    input: SelectOption | Options<SelectOption> | null,
    action: ActionMeta<SelectOption>
  ) => void;
};

export function useOnSelectGroup(): UseOnSelectGroupType {
  const id = useSelector((state: RootState) => state.modals.form.id);
  const formAction = id ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  const { getValues, setValue } = useFormContext<LocationsGroupFormInputsType>();

  const canAssociateLocationGroup = useCanAssociateLocationGroup();

  /** Verify if the incldued group creates a circular reference */
  const validateCircularReferenceOnSelectGroup = (
    input: SelectOption | Options<SelectOption> | null,
    action: ActionMeta<SelectOption>
  ): void => {
    if (
      id &&
      formAction === FormCrudAction.EDIT &&
      action.option &&
      action.action === 'select-option'
    ) {
      canAssociateLocationGroup({
        parentLocationGroupId: id,
        locationGroupId: action.option.value,
      }).then((isValid) => {
        if (!isValid) {
          const locationsGroups = getValues(LocationsGroupFormInputsNames.Groups);

          const updateLocationsGroups = pull(locationsGroups.values, action.option);

          setValue(LocationsGroupFormInputsNames.Groups, updateLocationsGroups);

          const parentGroupName = getValues(LocationsGroupFormInputsNames.Name);
          const childGroupName = action.option?.label;

          window.flash({
            message: 'Cannot associate group with circular reference',
            subText: `The group "${childGroupName}" cannot be associated with "${parentGroupName}" because it would create a circular reference.`,
            timeout: 10000,
          });
        }
      });
    }
  };

  return {
    onSelectGroup: validateCircularReferenceOnSelectGroup,
  };
}
