import {
  ActiveMembership,
  VirtualMembershipEntity,
  VirtualUserMembership,
} from '../redux/reducers/@types/MeReduxState';

import { VirtualDashboardMembership } from '../util/membership/types';
import { generateVirtualMembership } from '../util/membership/virtualMembershipUtils';
import { selectMembership } from '../redux/actions/me.actions';
import { useDispatch } from 'react-redux';

type UseManageActiveMembershipType = {
  saveMembershipLocally: (virtualMembership: VirtualMembershipEntity) => void;
  /** Compares the active membership from the `me` backend query with the active membership
   * saved locally on the Dashboard (Local storage) and returns the most recent one.
   * This is needed because since the virtual memberships are not saved in the database, is
   * not possible to return these memberships as activeMembership in the `me` query. */
  getTheMostRecentActiveMembershipBetweenTheMeQueryAndTheLocalOne: (
    virtualMemberships: VirtualUserMembership[],
    activeMembership: ActiveMembership
  ) => VirtualUserMembership;
};

const LOCAL_STORAGE_KEY = 'VIRTUAL_ACTIVE_MEMBERSHIP';

export function useManageLocalActiveMembership(): UseManageActiveMembershipType {
  const dispatch = useDispatch();

  const saveMembershipLocally = (virtualMembership: VirtualMembershipEntity): void => {
    const dashboardMembership: VirtualDashboardMembership = {
      ...virtualMembership,
      lastSeenAt: new Date(),
    };

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dashboardMembership));

    dispatch(selectMembership(dashboardMembership.virtualId));
  };

  function getLocalMembership(): VirtualDashboardMembership | undefined {
    const dashboardMembershipValue = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!dashboardMembershipValue) {
      return undefined;
    }

    const dashboardMembership = JSON.parse(dashboardMembershipValue) as VirtualDashboardMembership;

    return dashboardMembership;
  }

  function findVirtualMembership(
    virtualMemberships: VirtualUserMembership[],
    membershipVirtualId: string
  ): VirtualUserMembership | undefined {
    const virtualMembership = virtualMemberships?.find(
      (membership) => membership.virtualMembershipEntity.virtualId === membershipVirtualId
    );

    return virtualMembership;
  }

  function generateVirtualUserMembership(membership: ActiveMembership): VirtualUserMembership {
    return {
      ...membership,
      virtualMembershipEntity: generateVirtualMembership(membership),
    };
  }

  const getTheMostRecentActiveMembershipBetweenTheMeQueryAndTheLocalOne = (
    virtualMemberships: VirtualUserMembership[],
    activeMembership: ActiveMembership
  ): VirtualUserMembership => {
    const dashboardMembership = getLocalMembership();

    if (!dashboardMembership) {
      return generateVirtualUserMembership(activeMembership);
    }

    const virtualMembership = findVirtualMembership(
      virtualMemberships,
      dashboardMembership.virtualId
    );
    if (!virtualMembership) {
      return generateVirtualUserMembership(activeMembership);
    }

    const { lastSeenAt: membershipLastSeenAt } = activeMembership;
    const { lastSeenAt: dashboardMembershipLastSeenAt } = dashboardMembership;

    if (!membershipLastSeenAt || membershipLastSeenAt < dashboardMembershipLastSeenAt) {
      return virtualMembership;
    }

    return generateVirtualUserMembership(activeMembership);
  };

  return {
    saveMembershipLocally,
    getTheMostRecentActiveMembershipBetweenTheMeQueryAndTheLocalOne,
  };
}
