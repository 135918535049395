import { SelectInputOptionsType } from '../@types/InputGroupProps';
import SelectOption from '../../../@types/react/SelectOption';
import isStringArray from '../../../util/isStringArray';
import { startCase } from 'lodash';

export const optionsAreString = (options: SelectInputOptionsType): boolean => {
  return options.length ? isStringArray(options) : true;
};

export const castToSelectOptions = (options?: SelectInputOptionsType): SelectOption[] => {
  if (!options || options.length === 0) {
    return [];
  }

  if (optionsAreString(options)) {
    return (options as string[]).map(
      (option): SelectOption => {
        return {
          label: startCase(String(option)),
          value: String(option),
        };
      }
    );
  }

  return options as SelectOption[];
};
