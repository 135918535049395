import 'styled-components/macro';

import { MenuListProps, components } from 'react-select';

import { MembershipSelectorMenuListContainer } from './MembershipSelectorMenuListStyles';
import { MembershipSelectorOptionType } from '../../types';
import React from 'react';

export const MembershipSelectorMenuList = (
  props: MenuListProps<MembershipSelectorOptionType, false>
): React.ReactElement => {
  return (
    <MembershipSelectorMenuListContainer>
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </MembershipSelectorMenuListContainer>
  );
};
