import { ActionMeta, Options } from 'react-select';

import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import SelectOption from '../../../@types/react/SelectOption';
import { UseOnChangeChildrenType } from '../hooks/useOnChangeChildrenType';
import { useLoadNotSelectedGroupsOptions } from '../hooks/useLoadNotSelectedGroupsOptions';
import { useLoadNotSelectedLocationsOptions } from '../hooks/useLoadNotSelectedLocationsOptions';

export const LocationsGroupChildrenTypeEnum = {
  locations: 'locations',
  groups: 'groups',
} as const;

// eslint-disable-next-line max-len
export type LocationsGroupChildrenType = typeof LocationsGroupChildrenTypeEnum[keyof typeof LocationsGroupChildrenTypeEnum];

export const LocationsGroupFormInputsNames = {
  Name: 'name',
  ChildrenType: 'childrenType',
  Locations: LocationsGroupChildrenTypeEnum.locations,
  Groups: LocationsGroupChildrenTypeEnum.groups,
} as const;

type GenerateLocationsGroupFormInputsProps = {
  onChangeChildrenType: UseOnChangeChildrenType['onChangeChildrenType'];
  childrenType: LocationsGroupChildrenType;
  onSelectLocationGroup: (
    input: SelectOption | Options<SelectOption> | null,
    action: ActionMeta<SelectOption>
  ) => void;
};

export const generateLocationsGroupFormInputs = ({
  onChangeChildrenType,
  childrenType,
  onSelectLocationGroup,
}: GenerateLocationsGroupFormInputsProps): InputGroupProps[] => {
  const LOCATIONS_GROUP_FORM_INPUTS: InputGroupProps[] = [
    {
      label: 'Group Name',
      name: LocationsGroupFormInputsNames.Name,
      type: 'text',
      required: true,
    },
    {
      name: '',
      type: 'divider',
    },
    {
      label: 'Include in this group',
      name: LocationsGroupFormInputsNames.ChildrenType,
      type: 'radio',
      rules: { validate: onChangeChildrenType },
      options: [
        { label: 'Locations', value: LocationsGroupChildrenTypeEnum.locations },
        { label: 'Groups', value: LocationsGroupChildrenTypeEnum.groups },
      ],
    },
    {
      label: '',
      name: `${LocationsGroupChildrenTypeEnum.locations}.values`,
      hidden: childrenType !== LocationsGroupChildrenTypeEnum.locations,
      type: 'async-select',
      placeholder: 'Select Locations...',
      isMulti: true,
      loadOptions: useLoadNotSelectedLocationsOptions,
    },
    {
      label: '',
      name: `${LocationsGroupChildrenTypeEnum.groups}.values`,
      hidden: childrenType !== LocationsGroupChildrenTypeEnum.groups,
      type: 'async-select',
      placeholder: 'Select Groups...',
      isMulti: true,
      loadOptions: useLoadNotSelectedGroupsOptions,
      onSelect: onSelectLocationGroup,
    },
  ];

  return LOCATIONS_GROUP_FORM_INPUTS;
};
