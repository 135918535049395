import {
  ActiveMembership,
  VirtualMembershipEntity,
  VirtualUserMembership,
} from '../../redux/reducers/@types/MeReduxState';
import { BasicMembership, MembershipAccessLevelEnum } from './types';

export function generateVirtualMembership(membership: BasicMembership): VirtualMembershipEntity {
  let virtualMembershipEntityConfig: { entityLevel: MembershipAccessLevelEnum; entityId: string };

  if (membership.location) {
    virtualMembershipEntityConfig = {
      entityLevel: MembershipAccessLevelEnum.location,
      entityId: membership.location.id,
    };
  } else if (membership.locationGroup) {
    virtualMembershipEntityConfig = {
      entityLevel: MembershipAccessLevelEnum.locationGroup,
      entityId: membership.locationGroup.id,
    };
  } else {
    virtualMembershipEntityConfig = {
      entityLevel: MembershipAccessLevelEnum.tenant,
      entityId: membership.tenant.id,
    };
  }

  return {
    id: membership.id,
    virtualId: `${membership.id}-${virtualMembershipEntityConfig.entityLevel}-${virtualMembershipEntityConfig.entityId}`,
  };
}

export function getActiveMembershipFromVirtualMembership(
  virtualMembership: VirtualUserMembership
): ActiveMembership {
  const { id, role, tenant, location, locationGroup } = virtualMembership;

  return {
    id,
    role,
    tenant,
    tenantId: tenant.id,
    location,
    locationGroup,
  };
}
