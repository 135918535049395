import { GetLocationGroupArgs, GetLocationGroupData } from './types';

import { GET_LOCATION_GROUP_NODES } from '../../graphql/getLocationGroupNodes';
import { GroupType } from '../useLocationGroupsTree/types';
import { PureQueryOptions } from '@apollo/client';
import { RootState } from '../../../../../../redux/reducers';
import { createLocationGroupsTreeBasedOnFlatList } from './locationGroupUtils';
import useGetMany from '../../../../../../hooks/useGetMany';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type RefetchQueryOption = PureQueryOptions & {
  variables: GetLocationGroupArgs;
};

type UseLocationsGroupsType = {
  data: GroupType[] | undefined;
  query: () => void;
  loading: boolean;
  refetchQueryOption: RefetchQueryOption;
};

export const useLocationsGroups = (): UseLocationsGroupsType => {
  const {
    activeMembership: { tenantId: activeTenantId },
  } = useSelector((state: RootState) => state.me);

  const { data: resultData, loading, query } = useGetMany<
    GetLocationGroupData,
    GetLocationGroupArgs
  >({
    graphqlQuery: GET_LOCATION_GROUP_NODES,
  });

  const data = useMemo((): GroupType[] | undefined => {
    if (!resultData) {
      return undefined;
    }

    const locationGroupsTreeData: GroupType[] = createLocationGroupsTreeBasedOnFlatList(
      resultData.getLocationGroupNodes
    );

    return locationGroupsTreeData;
  }, [resultData]);

  const refetchQueryOption: RefetchQueryOption = {
    query: GET_LOCATION_GROUP_NODES,
    variables: { tenantId: activeTenantId },
  };

  return {
    data,
    query: (): void => {
      query({
        tenantId: activeTenantId,
      });
    },
    loading,
    refetchQueryOption,
  };
};
