import styled, { CSSProperties } from 'styled-components';
import Modal from 'react-modal';
import Theme from '../../../../../../theme';

export const confirmModalStyle: Modal.Styles = {
  content: {
    backgroundColor: Theme.colors.whiteSmoke.hexCode,
    alignSelf: 'center',
    border: '0',
    borderRadius: '24px',
    bottom: 'unset',
    display: 'grid',
    gridAutoRows: '1fr',
    justifySelf: 'center',
    margin: '0 auto',
    outline: '0',
    top: '10%',
    width: '600px',
    zIndex: 5,
    flex: 1,
  },
  overlay: {
    background: `rgba(${Theme.colors.black.rgb}, 0.5)`,
    border: '0',
    outline: '0',
    zIndex: 1000,
  },
};

export const CloseButtonStyle = `
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const ConfirmModalHeader = styled.div`
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ConfirmModalDesc = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ConfirmModalCancel = styled.p`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 32px;
`;

export const ModalStyles: Record<string, CSSProperties> = {
  headerContainer: { display: 'flex', flexDirection: 'row' },
  headerTextContainer: {
    display: 'flex',
    width: '80%',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    width: '20%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  descContainer: { paddingTop: 32, paddingBottom: 32 },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  confirmButton: {
    width: '154px',
    backgroundColor: Theme.colors.quickActionButton.hexCode,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 16,
    paddingBottom: 16,
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 0.5,
    borderRadius: 12,
  },
};
