import { QueryMode, SortOrder } from '../../__generated__/graphql';
import { SearchGroupsArgs, SearchGroupsData } from './types';

import { RootState } from '../../redux/reducers';
import { SEARCH_GROUPS } from './graphql/searchGroups';
import SelectOption from '../../@types/react/SelectOption';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';

type UseLoadGroupsOptionsProps = {
  groupsFilteredOut?: string[];
};

export type UseLoadGroupsOptionsType = (input: string) => Promise<SelectOption[]>;

export const useLoadGroupsOptions = (
  props: UseLoadGroupsOptionsProps = {}
): UseLoadGroupsOptionsType => {
  const client = useApolloClient();

  const me = useSelector((state: RootState) => state.me);
  const activeTenantId = me.activeMembership.tenantId;

  const { groupsFilteredOut } = props;
  const excludeGroups = groupsFilteredOut && groupsFilteredOut.length > 0;

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = await client.query<SearchGroupsData, SearchGroupsArgs>({
      query: SEARCH_GROUPS,
      variables: {
        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        take: 10,
        where: {
          name: {
            contains: input,
            mode: QueryMode.Insensitive,
          },
          tenantId: {
            equals: activeTenantId,
          },
          ...(excludeGroups
            ? {
                id: {
                  notIn: groupsFilteredOut,
                },
              }
            : {}),
        },
      },
      fetchPolicy: 'network-only',
    });

    if (!data.locationGroups) {
      return [];
    }

    return data.locationGroups.map((locationGroup) => ({
      label: locationGroup.name,
      value: locationGroup.id,
    }));
  };
};
