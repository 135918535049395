import { CreateLocationsGroupArgs, CreateLocationsGroupData } from '../@types';

import { CREATE_LOCATIONS_GROUP } from '../graphql/createLocationGroup';
import MutationHookData from '../../../hooks/@types/MutationHookData';
import MutationHookParams from '../../../hooks/@types/MutationHookParams';
import { closeForm } from '../../../redux/actions/modals.actions';
import { flashApolloError } from '../../../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useLocationsGroups } from '../../../pages/Locations/components/Groups/hooks/useLocationsGroups';
import { useMutation } from '@apollo/client';

type UseCreateLocationsGroupProps = MutationHookParams<CreateLocationsGroupData>;
type UseCreateLocationsGroup = MutationHookData<CreateLocationsGroupArgs, CreateLocationsGroupData>;

export const useCreateLocationsGroup = ({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: UseCreateLocationsGroupProps = {}): UseCreateLocationsGroup => {
  const { refetchQueryOption } = useLocationsGroups();

  const dispatch = useDispatch();

  const [create, { data, error, loading }] = useMutation<
    CreateLocationsGroupData,
    CreateLocationsGroupArgs
  >(CREATE_LOCATIONS_GROUP, {
    onCompleted: ({ createLocationGroup }) => {
      window.flash({
        message: `Group: ${createLocationGroup.name} created successfully`,
      });

      onCompleted({ createLocationGroup });

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: CreateLocationsGroupArgs): void => {
      create({
        variables: inputs,
        refetchQueries: [refetchQueryOption],
      });
    },
  };
};
