import { Query, QueryServicesArgs, ServiceWhereInput } from '../../__generated__/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';

import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import GET_SERVICE_COUNT from '../../graphql/aggregateService';
import GET_SERVICE_TABLE_DATA from '../../graphql/getServiceTableData';
import { LocationPageInfo } from '../../pages/Location/@types/GetLocationPageInfo';
import OrderByParams from '../../@types/client/OrderByParams';
import { RootState } from '../../redux/reducers';
import SERVICES_TABLE_HEADERS from './constants/ServicesTableHeaders';
import SERVICES_TABLE_SEARCH_FIELDS from './constants/ServicesTableSearchFields';
import SearchField from '../../@types/SearchField';
import TableHookData from '../@types/TableHookData';
import TableHookProps from '../@types/TableHookProps';
import generateQueryFilteringByLocation from '../../util/api/generateLocationMembershipQuery';
import generateServicesTableData from './functions/generateServicesTableData';
import parseOrderByDataTableParams from '../../components/DataTable/util/parseOrderByDataTableParams';
import parseServiceQueryParameters from './util/parseServiceQueryParameters';
import useGetMany from '../useGetMany';
import useRemoveServiceSoftly from '../useRemoveServiceSoftly';
import { useSelector } from 'react-redux';
import useServiceFilters from './hooks/useServiceFilters';

type ServicesQueryParams = {
  orderBy?: OrderByParams;
  where: Partial<ServiceWhereInput>;
};

interface ServicesTableData extends TableHookProps<QueryServicesArgs, ServiceWhereInput> {
  relatedLocation?: LocationPageInfo;
}

type GetServiceTableData = Pick<Query, 'services'>;
type AggregateServiceQueryData = Pick<Query, 'aggregateService'>;

export default function useServicesTableData({
  dataTableKey = 'services',
  formName = 'service',
  headers = SERVICES_TABLE_HEADERS,
  isSearchable = true,
  queryOptions,
  searchFields = SERVICES_TABLE_SEARCH_FIELDS,
  relatedLocation,
}: ServicesTableData): TableHookData<ServiceWhereInput> {
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const options = useRef<Partial<QueryServicesArgs>>(queryOptions ?? {});
  const fieldsFiltered = useSelector((state: RootState) => state.dataTable.filters);
  const sortOptions = useSelector((state: RootState) => state.dataTable.sortOptions);
  const filters = useServiceFilters();

  const [searchParameters, setSearchParameters] = useState<ServiceWhereInput>({});

  const { data, error, loading, offset, query, setOffset } = useGetMany<
    GetServiceTableData,
    QueryServicesArgs
  >({
    graphqlQuery: GET_SERVICE_TABLE_DATA,
  });

  const {
    data: countData,
    error: countError,
    loading: countLoading,
    query: countQuery,
  } = useGetMany<AggregateServiceQueryData, QueryServicesArgs>({
    graphqlQuery: GET_SERVICE_COUNT,
  });

  const createParams = useCallback((): ServicesQueryParams => {
    const whereArgs = parseServiceQueryParameters(fieldsFiltered[dataTableKey]);
    const sortParams = parseOrderByDataTableParams(sortOptions[dataTableKey]);

    return {
      ...(sortParams && { orderBy: sortParams }),
      where: {
        ...generateQueryFilteringByLocation(membership),
        ...options.current?.where,
        ...searchParameters,
        ...whereArgs,
      },
    };
  }, [membership, options, searchParameters, fieldsFiltered, sortOptions, dataTableKey]);

  const get = useCallback(() => {
    const params = createParams();

    query({
      ...options.current,
      ...(params.orderBy && { orderBy: params.orderBy }),
      skip: options.current?.skip ?? offset,
      take: options.current?.take ?? 10,
      where: params.where,
    });
  }, [createParams, offset, query]);

  const getCount = useCallback(() => {
    const params = createParams();

    countQuery({
      where: params.where,
    });
  }, [countQuery, createParams]);

  useEffect(() => {
    get();
  }, [get]);

  useEffect(() => {
    getCount();
  }, [getCount]);

  return {
    aditionalDataOnOpeningForm: relatedLocation
      ? {
          location: {
            id: relatedLocation.id,
            name: relatedLocation.name,
          },
        }
      : undefined,
    count: countData?.aggregateService?._count?._all ?? 0,
    data: generateServicesTableData(data),
    dataTableKey,
    dataType: 'Service',
    error: error || countError,
    filters,
    formName,
    headers,
    isLoading: loading || countLoading,
    isSearchable,
    offset,
    permission: EntityEnum.SERVICE,
    remove: useRemoveServiceSoftly,
    search: (searchField: SearchField, searchValue: string): void => {
      setSearchParameters({
        [searchField.queryField]: searchField.transform(searchValue),
      });

      setOffset(0);
    },
    searchFields,
    setOffset,
  };
}
