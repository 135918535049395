import {
  LocationsGroupChildrenTypeEnum,
  LocationsGroupFormInputsNames,
} from '../constants/locationsGroupFormInputs';

import { LocationsGroupFormInputsType } from '../@types/LocationsGroupFormInputsType';
import SelectOption from '../../../@types/react/SelectOption';
import { openConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

export type UseOnChangeChildrenType = {
  onChangeChildrenType: (newValue: string) => Promise<boolean>;
};

export const useOnChangeChildrenType = (): UseOnChangeChildrenType => {
  const dispatch = useDispatch();
  const { getValues } = useFormContext<LocationsGroupFormInputsType>();

  const currentChildrenType = getValues(LocationsGroupFormInputsNames.ChildrenType);

  const locations =
    (getValues(`${LocationsGroupChildrenTypeEnum.locations}.values`) as SelectOption[]) ?? [];
  const groups =
    (getValues(`${LocationsGroupChildrenTypeEnum.groups}.values`) as SelectOption[]) ?? [];

  function itShouldDisplayTheConfirmationModal(newValue: string): boolean {
    if (newValue === currentChildrenType) {
      return false;
    }

    if (currentChildrenType === LocationsGroupChildrenTypeEnum.locations && locations.length > 0) {
      return true;
    }

    if (currentChildrenType === LocationsGroupChildrenTypeEnum.groups && groups.length > 0) {
      return true;
    }

    return false;
  }

  /** Display the modal to confirm the user wants to change the childrens type */
  const onChangeChildrenType = (newValue: string): Promise<boolean> => {
    if (!itShouldDisplayTheConfirmationModal(newValue)) {
      return Promise.resolve(true);
    }

    return new Promise((resolve) => {
      dispatch(
        openConfirmationModal({
          action: (): void => resolve(true),
          onReject: (): void => resolve(false),
          title: 'Are you sure you want to change what is included in this group?',
          message:
            'Users of this group might lose access to locations if they’re not directly connected to the locations or to groups that include them.',
        })
      );
    });
  };

  return {
    onChangeChildrenType,
  };
};
