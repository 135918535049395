import { CSSProperties } from 'react';
import Theme from '../../../../../../theme';

export const GuestEngagementStyles: Record<string, CSSProperties> = {
  btnContainer: { width: '100%', display: 'flex', flexDirection: 'row' },
  guestEngagementButton: {
    width: '100%',
    height: 42,
    backgroundColor: Theme.colors.quickActionButton.hexCode,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.5,
  },
};
