import 'styled-components/macro';

import { GroupBase, OptionProps, components } from 'react-select';

import { MembershipLevelIcon } from '../MembershipLevelIcon';
import { MembershipSelectorOptionContainer } from './MembershipSelectorOptionStyles';
import { MembershipSelectorOptionType } from '../../types';
import React from 'react';

export const MembershipSelectorOption = (
  props: OptionProps<MembershipSelectorOptionType, false, GroupBase<MembershipSelectorOptionType>>
): React.ReactElement => {
  const defaultComponent = (
    <components.Option {...props}>
      <MembershipSelectorOptionContainer>{props.label}</MembershipSelectorOptionContainer>
    </components.Option>
  );

  try {
    const options = props.options as GroupBase<MembershipSelectorOptionType>[];
    const option = props.data;
    const optionValue = option.value;

    const groupLabel = options.find((_groupOptions) =>
      _groupOptions.options.find((_option) => _option.value === optionValue)
    )?.label;

    if (groupLabel) {
      const selectedValue = props.getValue()[0]?.value;
      const isSelected = selectedValue === optionValue;

      const membershipLevelIcon = MembershipLevelIcon({
        isSelected,
        selectorProps: props,
        option,
      });

      return (
        <components.Option {...props}>
          <MembershipSelectorOptionContainer isSelected={isSelected}>
            {membershipLevelIcon}
            <span>{props.label}</span>
          </MembershipSelectorOptionContainer>
        </components.Option>
      );
    }

    return defaultComponent;
  } catch {
    return defaultComponent;
  }
};
