import {
  LocationRelationFilter,
  LocationWhereInput,
  StringFilter,
  TenantRelationFilter,
} from '../../__generated__/graphql';

import { ActiveMembership } from '../../redux/reducers/@types/MeReduxState';
import { MembershipAccessLevelEnum } from '../membership/types';
import { getMembershipAccessLevel } from '../membership/membershipUtils';

type MembershipQueryWhere = {
  location: LocationRelationFilter;
};

export function generateQueryFilteringLocation(membership: ActiveMembership): LocationWhereInput {
  const tenantRelationFilter: TenantRelationFilter = {
    is: {
      id: {
        equals: membership.tenant.id,
      },
    },
  };

  let locationIdRelationFilter: StringFilter | undefined;
  const membershipAccessLevel = getMembershipAccessLevel(membership);
  if (membershipAccessLevel === MembershipAccessLevelEnum.location) {
    locationIdRelationFilter = {
      equals: membership.location?.id,
    };
  } else if (membershipAccessLevel === MembershipAccessLevelEnum.locationGroup) {
    locationIdRelationFilter = {
      in: membership.locationGroup?.locationIds ?? [],
    };
  }

  return {
    tenant: tenantRelationFilter,
    id: locationIdRelationFilter,
  };
}

export default function generateQueryFilteringByLocation(
  membership: ActiveMembership
): MembershipQueryWhere {
  const locationFilter = generateQueryFilteringLocation(membership);

  return {
    location: {
      is: locationFilter,
    },
  };
}
