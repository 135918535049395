import React from 'react';

export const ExpandItemsIcon: React.FC<{
  color: string;
}> = ({ color }) => {
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 9.60938L4.85938 7.75L5.67969 8.57031L3 11.25L0.320312 8.57031L1.14062 7.75L3 9.60938ZM3 2.39062L1.14062 4.25L0.320312 3.42969L3 0.75L5.67969 3.42969L4.85938 4.25L3 2.39062Z"
        fill={color}
      />
    </svg>
  );
};
