import 'styled-components/macro';

import React, { useContext, useEffect, useRef } from 'react';
import Select, { ActionMeta } from 'react-select';
import { SelectInputContainerStyle, SelectInputStyles } from './styles/SelectInput.style';
import {
  castToSelectOptions,
  optionsAreString as optionsAreStringFn,
} from '../InputGroup/utils/selectInputUtils';

import Container from '../Container';
import FormContext from '../../contexts/form.context';
import { SelectInputOptionsType } from '../InputGroup/@types/InputGroupProps';
import SelectOption from '../../@types/react/SelectOption';
import startCase from 'lodash/startCase';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

interface SelectInputProps {
  className?: string;
  disabled?: boolean;
  isEditable?: boolean;
  isFieldArray?: boolean;
  label?: string;
  menuPlacement?: 'bottom' | 'top' | 'auto';
  name: string;
  options: SelectInputOptionsType;
  placeholder?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({
  className,
  disabled = false,
  isEditable = true,
  isFieldArray = false,
  name,
  options,
  placeholder,
}: SelectInputProps) => {
  const { identifier } = useContext(FormContext);
  const { getValues, register, setValue, trigger } = useFormContext();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [menuPlacement, setMenuPlacement] = useState<'top' | 'bottom'>('bottom');
  const [offset, setOffset] = useState<number>(window.pageYOffset);
  const isEditForm = Boolean(identifier);
  const isDisabled = isEditForm ? !isEditable || disabled : disabled;

  const currentValue: string | SelectOption = getValues(name);

  const optionsAreString = optionsAreStringFn(options);

  const getCurrentValue = (): SelectOption | undefined => {
    if (currentValue) {
      if (optionsAreString) {
        return { label: startCase(String(currentValue)), value: String(currentValue) };
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isSelectOption = (object: any): object is SelectOption =>
        typeof object !== 'string' && 'label' in object;
      if (isSelectOption(currentValue)) {
        return currentValue;
      }

      const optionFound = (options as SelectOption[]).find(
        (_option) => _option.value === currentValue
      );
      if (optionFound) {
        return optionFound;
      }
    }

    return undefined;
  };

  function handleChange(
    option: { label: string; value: string } | null,
    action: ActionMeta<SelectOption>
  ): void {
    switch (action.action) {
      case 'clear': {
        if (optionsAreString) {
          setValue(name, '');
        } else {
          setValue(name, undefined);
        }
        break;
      }

      case 'select-option': {
        if (action.option) {
          setValue(name, action.option);
        } else if (optionsAreString) {
          setValue(name, option?.value);
        } else {
          setValue(name, option);
        }
        break;
      }

      default: {
        break;
      }
    }

    trigger(name);
  }

  useEffect(() => {
    if (!isFieldArray) {
      register(name);
    }
  });

  useEffect(() => {
    if (offset !== window.pageYOffset) {
      setOffset(window.pageYOffset);
    }
  }, [offset, setOffset]);

  useEffect(() => {
    const element = elementRef.current;
    const { innerHeight } = window;
    const spaceToBottom = innerHeight - (element?.offsetTop ?? 0);

    if (menuPlacement !== 'top' && spaceToBottom + offset < 300) {
      setMenuPlacement('top');
    } else if (menuPlacement !== 'bottom' && spaceToBottom + offset >= 300) {
      setMenuPlacement('bottom');
    }
  }, [menuPlacement, offset, setMenuPlacement]);

  const selectOptions = castToSelectOptions(options);

  return (
    <Container className={className} css={SelectInputContainerStyle} ref={elementRef}>
      <Select
        backspaceRemovesValue
        defaultValue={getCurrentValue()}
        isClearable
        isDisabled={isDisabled}
        onChange={handleChange}
        options={selectOptions}
        menuPlacement={menuPlacement}
        placeholder={placeholder ?? ''}
        styles={SelectInputStyles}
        value={getCurrentValue()}
        classNamePrefix="SelectInputStyle"
      />
    </Container>
  );
};

export default SelectInput;
