/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Theme from '../../../../../../theme';
import styled from 'styled-components';

export type MembershipSelectorOptionProps = {
  isSelected?: boolean;
};

export const MembershipSelectorOptionContainer = styled.div<MembershipSelectorOptionProps>`
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 12px;
  padding: 12px;
  cursor: default;
  :hover {
    ${({ isSelected }) =>
      !isSelected &&
      `
      background: ${Theme.colors.whiteSmoke.hexCode};
    `}
  }
  ${({ isSelected }) =>
    isSelected &&
    `
    background: ${Theme.colors.quickActionButton.hexCode};
    color: ${Theme.colors.white.hexCode};
  `}
`;
