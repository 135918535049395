import React from 'react';
import Theme from '../../theme';
import styled from 'styled-components';

interface TabIconProps {
  Icon: React.FC<{ color?: string }>;
  isActive: boolean;
}

const ColorsPerState = {
  active: {
    backgroundColor: Theme.colors.darkAlternate.hexCode,
    color: Theme.colors.white.hexCode,
  },
  inactive: {
    backgroundColor: Theme.colors.white.hexCode,
    color: Theme.colors.darkAlternate.hexCode,
  },
};

const getState = (isActive: boolean): 'active' | 'inactive' => {
  return isActive ? 'active' : 'inactive';
};

export const TabIcon: React.FC<TabIconProps> = ({ Icon, isActive }: TabIconProps) => {
  return <Icon color={ColorsPerState[getState(isActive)].color} />;
};

export const TabsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 30px;
`;

export const TabButton = styled.button<{ isActive: boolean }>`
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  font-size: ${Theme.fontSizes.thirdHeading};
  border: 0;

  background-color: ${({ isActive }): string => {
    return ColorsPerState[getState(isActive)].backgroundColor;
  }};
  color: ${({ isActive }): string => {
    return ColorsPerState[getState(isActive)].color;
  }};
`;
