import { ApolloErrorType, flashApolloError } from '../../../util/errorUtils';
import {
  Mutation,
  MutationCloudbedsConnectAppArgs,
  Query,
  Role,
} from '../../../__generated__/graphql';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import AuthenticationBaseContainer from '../../../components/AuthenticationBaseComponent';
import CONNECT_WITH_CLOUDBEDS_API from '../../../graphql/connectWithCloudbedsAPI';
import ChooseLocationForm from './components/ChooseLocationForm';
import CloudbedsAuthError from './components/CloudbedsAuthError';
import GET_USER_MEMBERSHIPS from '../../../graphql/getUserMemberships';
import React from 'react';
import { RootState } from '../../../redux/reducers';
import cloudbedsAuthMessages from './constants/messages';
import getTokenFromUrl from './functions/getTokenFromUrl';
import links from '../../../util/links';
import { useSelector } from 'react-redux';

type CloudbedsConnectAppData = Pick<Mutation, 'cloudbedsConnectApp'>;
type GetUserMembershipsData = Pick<Query, 'getUserMemberships'>;

const CloudbedsAuthenticationPage: React.FC<{}> = () => {
  const location = useLocation();
  const history = useHistory();
  const { virtualMemberships: memberships } = useSelector((state: RootState) => state.me);

  let authToken: string | undefined;

  const [
    getUserMemberships,
    {
      called: getUserMembershipsIsCalled,
      data: membershipsData,
      error: loadMembershipsError,
      loading: membershipIsLoading,
    },
  ] = useLazyQuery<GetUserMembershipsData>(GET_USER_MEMBERSHIPS, {
    onError: (error: ApolloErrorType): void =>
      flashApolloError(error, {
        messageOnInternalServerError: 'Failure on authenticate Cloudbeds App',
        messageTimeout: 0,
      }),
  });

  const [
    connectCloudbedsApp,
    { data: connectionData, error: connectionError, loading: connectionLoading },
  ] = useMutation<CloudbedsConnectAppData, MutationCloudbedsConnectAppArgs>(
    CONNECT_WITH_CLOUDBEDS_API,
    {
      onError: (error: ApolloErrorType): void =>
        flashApolloError(error, {
          messageOnInternalServerError: 'Failure on authenticate Cloudbeds App',
          messageTimeout: 0,
        }),
    }
  );

  if (!memberships?.some((membership) => [Role.Admin, Role.Agent].includes(membership.role))) {
    window.flash({
      message: 'Failure on authenticate Cloudbeds App',
      onClose: (): void => {
        history.push(links.paths.HOME);
      },
      subText: String('User is not authorized to perform this action.'),
      timeout: 10000,
    });

    return <CloudbedsAuthError />;
  }

  try {
    authToken = getTokenFromUrl(location.search);
    if (!authToken) {
      throw new Error();
    }
  } catch (error) {
    window.flash({
      message: 'Failure on authenticate Cloudbeds App',
      onClose: (): void => {
        history.push(links.paths.HOME);
      },
      subText: String(error),
      timeout: 10000,
    });

    return <CloudbedsAuthError />;
  }

  if (!getUserMembershipsIsCalled) {
    getUserMemberships();
  }

  if (connectionError || loadMembershipsError) {
    return <CloudbedsAuthError />;
  }

  if (connectionData?.cloudbedsConnectApp) {
    const {
      cloudbedsConnectApp: { success },
    } = connectionData;
    if (success) {
      window.flash({
        message: 'Cloudbeds App is successfully authenticated.',
        onClose: (): void => {
          history.push(links.paths.HOME);
        },
        timeout: 0,
      });

      return (
        <AuthenticationBaseContainer
          title={cloudbedsAuthMessages.title}
          subtitle={cloudbedsAuthMessages.subtitle}
          secondLevelSubtitle="Cloudbeds App is successfully authenticated."
        />
      );
    }

    return <CloudbedsAuthError />;
  }

  if (membershipIsLoading || connectionLoading) {
    return (
      <AuthenticationBaseContainer
        title={cloudbedsAuthMessages.title}
        subtitle={cloudbedsAuthMessages.subtitle}
        secondLevelSubtitle="Loading..."
      />
    );
  }

  if (membershipsData) {
    try {
      return (
        <AuthenticationBaseContainer
          title={cloudbedsAuthMessages.title}
          subtitle={cloudbedsAuthMessages.subtitle}
          secondLevelSubtitle="Choose location"
        >
          <ChooseLocationForm
            tenantMemberships={membershipsData.getUserMemberships}
            onLocationChosen={(locationIdInput: string): void => {
              connectCloudbedsApp({
                variables: {
                  locationId: locationIdInput,
                  token: authToken!,
                },
              });
            }}
            loading={connectionLoading}
          />
        </AuthenticationBaseContainer>
      );
    } catch (error) {
      window.flash({
        message: 'Failure on authenticate Cloudbeds.',
        subText: String(error),
        timeout: 0,
      });

      return <CloudbedsAuthError />;
    }
  }

  return (
    <AuthenticationBaseContainer
      title={cloudbedsAuthMessages.title}
      subtitle={cloudbedsAuthMessages.subtitle}
    />
  );
};

export default React.memo(CloudbedsAuthenticationPage);
