import {
  EditGroupIcon,
  ExpandItemButton,
  ExpandItemIcon,
  FolderIcon,
  LocationsGroupData,
  LocationsGroupInfo,
} from '../LocationsGroupsTreeStyles';
import { groupComponentKey, locationComponentKey } from '..';

import { ExtendedGroupType } from '../../../hooks/useLocationGroupsTree';
import { Fragment } from 'react';
import { LocationComponent } from '.';
import React from 'react';
import TableRow from '../../../../../../../components/DataTable/components/TableRow';
import { openForm } from '../../../../../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';
import { useLocationsGroupsProvider } from '../../../contexts/LocationsGroupsContext';

type LocationsGroupTreeComponentProps = {
  group: ExtendedGroupType;
  level: number;
};

export const LocationsGroupTreeComponent: React.FC<LocationsGroupTreeComponentProps> = ({
  group,
  level,
}: LocationsGroupTreeComponentProps) => {
  const dispatch = useDispatch();

  const { toggleGroupTree } = useLocationsGroupsProvider();

  const expandCollapseGroup = (): void => {
    toggleGroupTree(group.id);
  };

  const openCreateGroupForm = (): void => {
    dispatch(openForm('locationsGroup', group.id));
  };

  return (
    <Fragment>
      <TableRow>
        <LocationsGroupData level={level}>
          <LocationsGroupInfo>
            <ExpandItemButton displayList={group.displayList} onClick={expandCollapseGroup}>
              <ExpandItemIcon />
            </ExpandItemButton>
            <FolderIcon />
            {group.name}
          </LocationsGroupInfo>
          <EditGroupIcon onClick={openCreateGroupForm} />
        </LocationsGroupData>
      </TableRow>
      {/* SubGroups */}
      {group.displayList
        ? group.groups.map((subGroup) => (
            <LocationsGroupTreeComponent
              key={groupComponentKey(subGroup.id, group.id)}
              group={subGroup as ExtendedGroupType}
              level={level + 1}
            />
          ))
        : null}
      {/* Inner Locations */}
      {group.displayList
        ? group.locations.map((location) => (
            <LocationComponent
              key={locationComponentKey(location.id, group.id)}
              location={location}
              level={level + 1}
            />
          ))
        : null}
    </Fragment>
  );
};
