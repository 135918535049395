import { Mutation, MutationCreateUserArgs } from '../__generated__/graphql';
import { useDispatch, useSelector } from 'react-redux';

import CREATE_USER from '../graphql/createUser';
import CreateUserFormInputs from '../components/UserForm/@types/CreateUserFormInputs';
import MutationHookData from './@types/MutationHookData';
import MutationHookParams from './@types/MutationHookParams';
import { RootState } from '../redux/reducers';
import { UserDashboardPreferences } from '../@types/client/DashboardPreferences';
import { closeForm } from '../redux/actions/modals.actions';
import { flashApolloError } from '../util/errorUtils';
import generateMembershipInputs from './util/userMemberships/generateMembershipInputs';
import links from '../util/links';
import { useMutation } from '@apollo/client';

type CreateUserData = Pick<Mutation, 'createUser'>;

export default function useCreateUser({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: MutationHookParams<CreateUserData> = {}): MutationHookData<
  CreateUserFormInputs,
  CreateUserData
> {
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.me);

  const { activeMembership } = me;

  const [create, { data, error, loading }] = useMutation<CreateUserData, MutationCreateUserArgs>(
    CREATE_USER,
    {
      onCompleted: ({ createUser }) => {
        window.flash({
          link: `${links.paths.USERS}/${createUser.id}`,
          message: `User: ${createUser.name} created successfully`,
        });

        onCompleted({ createUser });

        dispatch(closeForm());
      },
      onError,
    }
  );

  return {
    data,
    error,
    loading,
    mutation: (inputs: CreateUserFormInputs): void => {
      const groupMemberships = inputs.groups.map;
      const locationMemberships = inputs.locations.map;

      const { role, tenantMembership, userAccessType } = inputs;

      const { create: membershipsToCreate } = generateMembershipInputs({
        locationMemberships,
        groupMemberships,
        activeMembership,
        role,
        tenantMembership,
        userAccessType,
      });

      const dashboardPreferences: UserDashboardPreferences | undefined = {
        preferredDateFormat: inputs.dashboardPreferences?.preferredDateFormat?.value,
        preferredTimeFormat: inputs.dashboardPreferences?.preferredTimeFormat?.value,
      };

      create({
        variables: {
          data: {
            dashboardPreferences,
            email: inputs.email,
            memberships: {
              create: membershipsToCreate,
            },
            name: inputs.name,
            password: inputs.password || '',
            phoneNumber: inputs.phoneNumber,
          },
        },
      });
    },
  };
}
