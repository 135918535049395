/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { ExpandItemIcon as ExpandItemIconSVG } from '../../../../../../assets/icons/ExpandItemIcon';
import { FolderIcon as FolderIconSVG } from '../../../../../../assets/icons/FolderIcon';
import MarkerIcon from '../../../../../../assets/icons/LocationIcon';
import { MdModeEdit } from 'react-icons/md';
import TableData from '../../../../../../components/DataTable/components/TableData';
import Theme from '../../../../../../theme';

type LocationsGroupsTreeDataProps = {
  level: number;
};

type ExpandItemButtonProps = {
  displayList: boolean;
};

export const ExpandItemButton = styled.button<ExpandItemButtonProps>`
  background: transparent;
  border: 0;
  ${({ displayList }) =>
    !displayList &&
    css`
      transform: rotate(270deg);
    `}
`;

export const ExpandItemIcon: React.FC = () => {
  return <ExpandItemIconSVG color={Theme.colors.white.hexCode} />;
};

export const FolderIcon: React.FC = () => {
  return <FolderIconSVG color={Theme.colors.groupFolder.hexCode} />;
};

export const LocationsGroupData = styled(TableData)<LocationsGroupsTreeDataProps>`
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin-left: ${({ level }): string => {
    return `${(level - 1) * 50}px`;
  }};
`;

export const LocationsGroupInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0px;
  font-weight: 500;
`;

const EditGroupIconButtonContainer = styled.button`
  margin: 6px;
  background: transparent;
  border: 0;

  background: rgba(${Theme.colors.white.rgb}, 0.2);
  border: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;

  :hover {
    background: ${Theme.colors.buttonDominant.hexCode};
  }
`;

type EditGroupIconProps = {
  onClick: () => void;
};

export const EditGroupIcon: React.FC<EditGroupIconProps> = ({ onClick }) => {
  return (
    <EditGroupIconButtonContainer onClick={onClick}>
      <MdModeEdit size={'16px'} />
    </EditGroupIconButtonContainer>
  );
};

const LocationInfoBase = styled(LocationsGroupInfo)`
  color: ${Theme.colors.expandCollapseButtons.hexCode};
`;

const LocationInfoButton = styled.button`
  border: 0;
  background: transparent;
`;

export const LocationInfo: React.FC<PropsWithChildren<{}>> = (props: PropsWithChildren<{}>) => {
  return (
    <LocationInfoButton>
      <LocationInfoBase>{props.children}</LocationInfoBase>
    </LocationInfoButton>
  );
};

export const LocationIcon: React.FC = () => {
  return <MarkerIcon color={Theme.colors.expandCollapseButtons.hexCode} />;
};
