import { gql } from '../../../__generated__';

export const UPDATE_LOCATIONS_GROUP = gql(`
  mutation UpdateLocationGroup($data: CustomLocationGroupUpdateInput!, $where: CustomLocationGroupWhereUniqueInput!) {
    updateLocationGroup(data: $data, where: $where) {
      id
      name
    }
  }
`);
