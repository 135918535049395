import Label from '../../Label';
import { RGB } from '../../../theme/colors';
import fontSizes from '../../../theme/fontSizes';
import styled from 'styled-components';

export const inputGroupLabelColor = `rgba(${RGB.comet}, .5)`;

export const InputLabel = styled(Label)`
  color: ${inputGroupLabelColor};
  font-size: ${fontSizes.bodyLarge};
  font-weight: bold;
  margin: 0 0 8px;
`;
