import {
  LocationsGroupFormInputsNames,
  generateLocationsGroupFormInputs,
} from '../constants/locationsGroupFormInputs';
import React, { useMemo } from 'react';
import SubmitRow, { SubmitRowProps } from '../../SubmitRow';

import Form from '../../Form';
import FormCrudAction from '../../../enums/FormCrudAction.enum';
import { LocationsGroupFormInputsType } from '../@types/LocationsGroupFormInputsType';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import { useDeleteLocationGroup } from '../hooks/useDeleteLocationGroup';
import { useFormContext } from 'react-hook-form';
import { useOnChangeChildrenType } from '../hooks/useOnChangeChildrenType';
import { useOnSelectGroup } from '../hooks/useOnSelectGroup';
import { useSelector } from 'react-redux';
import { useSubmitLocationsGroup } from '../hooks/useSubmitLocationsGroup';

export const LocationsGroupFormSection: React.FC = () => {
  const id = useSelector((state: RootState) => state.modals.form.id);
  const formAction = id ? FormCrudAction.EDIT : FormCrudAction.CREATE;

  const { handleSubmit, getValues } = useFormContext<LocationsGroupFormInputsType>();
  const { mutation: deleteLocationGroup, loading: loadingLocationGroup } = useDeleteLocationGroup();

  const { loadingMutation: loadingSubmitLocationGroup, submitHandler } = useSubmitLocationsGroup();
  const { onChangeChildrenType } = useOnChangeChildrenType();

  const { onSelectGroup } = useOnSelectGroup();

  const loading = useMemo(() => loadingLocationGroup || loadingSubmitLocationGroup, [
    loadingLocationGroup,
    loadingSubmitLocationGroup,
  ]);

  const childrenTypeValue = getValues(LocationsGroupFormInputsNames.ChildrenType);

  const locationsGroupFormInputs = generateLocationsGroupFormInputs({
    onChangeChildrenType,
    childrenType: childrenTypeValue,
    onSelectLocationGroup: onSelectGroup,
  });

  const submitRowProperties: Partial<SubmitRowProps> = useMemo(() => {
    if (formAction === FormCrudAction.EDIT && id) {
      return {
        cancelButtonText: 'Delete',
        showCancelButton: true,
        onCancelClick: (): void => deleteLocationGroup({ locationGroupId: id }),
      };
    }

    return {};
  }, [formAction]);

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <SimpleFormSection columns={1} inputs={locationsGroupFormInputs} />
      <SubmitRow
        loading={loading}
        submitText={formAction === FormCrudAction.CREATE ? 'Save Changes' : 'Confirm'}
        {...submitRowProperties}
      />
    </Form>
  );
};
