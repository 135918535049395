import 'styled-components/macro';

import ConfirmationModalStyle, {
  ConfirmationModalActionsContainer,
  ConfirmationModalContainer,
  ConfirmationModalMessageText,
  ConfirmationModalMessagesContainer,
  ConfirmationModalTitleText,
} from '../styles/ConfirmationModal.style';
import { MdCheck, MdClose } from 'react-icons/md';

import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import Modal from 'react-modal';
import React from 'react';
import { closeConfirmationModal } from '../../../redux/actions/modals.actions';
import { useDispatch } from 'react-redux';

interface ConfirmationModalProps {
  titleText?: string;
  messageText?: string;
  onClose?: () => void;
  onReject?: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  titleText,
  messageText,
  onClose,
  onReject,
  onConfirm,
}: ConfirmationModalProps) => {
  const dispatch = useDispatch();

  function close(): void {
    dispatch(closeConfirmationModal());
    if (onClose) {
      onClose();
    }
  }

  function onAcceptClick(): void {
    close();
    onConfirm();
  }

  function onRejectClic(): void {
    close();
    if (onReject) {
      onReject();
    }
  }

  return (
    <Modal
      isOpen
      onRequestClose={close}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={ConfirmationModalStyle}
    >
      <ConfirmationModalContainer>
        <ConfirmationModalMessagesContainer>
          <ConfirmationModalTitleText>
            {titleText ?? 'Please confirm that you would like to perform this action'}
          </ConfirmationModalTitleText>
          {messageText && (
            <ConfirmationModalMessageText>{messageText}</ConfirmationModalMessageText>
          )}
        </ConfirmationModalMessagesContainer>
        <ConfirmationModalActionsContainer>
          <Button model={ButtonModel.PASSIVE_ICON_BUTTON} onClick={onAcceptClick}>
            <MdCheck className="yes-icon" size="18px" />
          </Button>
          <Button model={ButtonModel.PASSIVE_ICON_BUTTON} onClick={onRejectClic}>
            <MdClose className="no-icon" size="18px" />
          </Button>
        </ConfirmationModalActionsContainer>
      </ConfirmationModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;
