import {
  ActiveMembership,
  VirtualMembershipEntity,
} from '../../redux/reducers/@types/MeReduxState';

import { Maybe } from '../../__generated__/graphql';

export type VirtualDashboardMembership = VirtualMembershipEntity & {
  lastSeenAt: Date;
};

export type BasicMembership = {
  id: string;
  tenant: Pick<NonNullable<ActiveMembership['tenant']>, 'id'>;
  location?: Maybe<Pick<NonNullable<ActiveMembership['location']>, 'id'>>;
  locationGroup?: Maybe<Pick<NonNullable<ActiveMembership['locationGroup']>, 'id'>>;
};

export enum MembershipAccessLevelEnum {
  tenant = 'tenant',
  location = 'location',
  locationGroup = 'locationGroup',
}
