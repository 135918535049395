import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { CREATE_GUEST_ENGAGEMENT_EVENT } from '../../../../../../../graphql/mutations/kiosk';
import { KioskLiveUsageData } from '../../../../../../../__generated__/graphql';
import { flashApolloError } from '../../../../../../../util/errorUtils';
import { useMutation } from '@apollo/client';

export const useGuestEngagement = (
  kiosk: KioskLiveUsageData
): {
  handleClick: () => Promise<void>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
} => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [createGuestEngagementEvent] = useMutation(CREATE_GUEST_ENGAGEMENT_EVENT, {
    onCompleted: () => {},
    onError: flashApolloError,
  });

  const handleClick = useCallback(async (): Promise<void> => {
    await createGuestEngagementEvent({
      variables: {
        accessGrantId: kiosk.accessGrant?.id,
        kioskId: kiosk.kiosk.id,
      },
    });
    setIsModalOpen(false);
  }, [createGuestEngagementEvent, kiosk]);

  return { handleClick, isModalOpen, setIsModalOpen };
};
