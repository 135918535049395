import { DeleteLocationsGroupArgs, DeleteLocationsGroupData } from '../@types';
import { closeForm, openConfirmationModal } from '../../../redux/actions/modals.actions';

import { DELETE_LOCATION_GROUP } from '../graphql/deleteLocationGroup';
import MutationHookData from '../../../hooks/@types/MutationHookData';
import MutationHookParams from '../../../hooks/@types/MutationHookParams';
import { flashApolloError } from '../../../util/errorUtils';
import { useDispatch } from 'react-redux';
import { useLocationsGroups } from '../../../pages/Locations/components/Groups/hooks/useLocationsGroups';
import { useMutation } from '@apollo/client';

type UseDeleteLocationsGroupProps = MutationHookParams<DeleteLocationsGroupData>;
type UseDeleteLocationsGroup = MutationHookData<DeleteLocationsGroupArgs, DeleteLocationsGroupData>;

export const useDeleteLocationGroup = ({
  onCompleted = (): void => {},
  onError = flashApolloError,
}: UseDeleteLocationsGroupProps = {}): UseDeleteLocationsGroup => {
  const { refetchQueryOption } = useLocationsGroups();

  const dispatch = useDispatch();

  const [deleteMutation, { data, error, loading }] = useMutation<
    DeleteLocationsGroupData,
    DeleteLocationsGroupArgs
  >(DELETE_LOCATION_GROUP, {
    onCompleted: ({ deleteLocationGroup }) => {
      if (deleteLocationGroup.success) {
        window.flash({
          message: `Group deleted successfully`,
        });
      }

      onCompleted({ deleteLocationGroup });

      dispatch(closeForm());
    },
    onError,
  });

  return {
    data,
    error,
    loading,
    mutation: (inputs: DeleteLocationsGroupArgs): void => {
      dispatch(
        openConfirmationModal({
          action: (): void => {
            deleteMutation({
              variables: {
                locationGroupId: inputs.locationGroupId,
              },
              refetchQueries: [refetchQueryOption],
            });
          },
          title: 'Are you sure you want to permanently delete this group?',
          message:
            'Users of this group might lose access to locations if they’re not directly connected to the locations or to groups that include them.',
        })
      );
    },
  };
};
