import { BasicMembership, MembershipAccessLevelEnum } from './types';

// eslint-disable-next-line max-len
type MembershipAccessLevelType = typeof MembershipAccessLevelEnum[keyof typeof MembershipAccessLevelEnum];

export function getMembershipAccessLevel(membership: BasicMembership): MembershipAccessLevelType {
  if (membership.location) {
    return MembershipAccessLevelEnum.location;
  }

  if (membership.locationGroup) {
    return MembershipAccessLevelEnum.locationGroup;
  }

  return MembershipAccessLevelEnum.tenant;
}

export function isFullAccessMembership(membership: BasicMembership): boolean {
  return getMembershipAccessLevel(membership) === MembershipAccessLevelEnum.tenant;
}
