import 'styled-components/macro';

import { MembershipContainer, getComponentsStyles } from './styles/MembershipContainerStyle';
import {
  MembershipSelectorControl,
  MembershipSelectorMenuList,
  MembershipSelectorOption,
} from './components';
import React, { useCallback, useMemo } from 'react';
import Select, { GroupBase } from 'react-select';
import generateMembershipOptions, {
  generateMembershipOptionWithoutLevel,
} from './functions/generateMembershipOptions';

import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { MembershipSelectorOptionType } from './types';
import { RootState } from '../../../../redux/reducers';
import useAccessMembership from '../../../../hooks/useAccessMembership';
import { useSelector } from 'react-redux';

export const MembershipSelector: React.FC = () => {
  const { activeMembership } = useSelector((state: RootState) => state.me);

  const memberships = useSelector((state: RootState) => state.me.virtualMemberships) ?? [];
  const { accessMembership } = useAccessMembership();

  const defaultValue = useMemo((): MembershipSelectorOptionType => {
    return generateMembershipOptionWithoutLevel(activeMembership);
  }, [activeMembership]);

  const handleSelect = (option: MembershipSelectorOptionType | null): void => {
    if (option?.value) {
      const virtualMembership = option?.virtualMembership;

      accessMembership(virtualMembership);
    }
  };

  const filterOption = (
    option: FilterOptionOption<MembershipSelectorOptionType>,
    inputValue: string
  ): boolean => {
    if (!inputValue) {
      return true;
    }

    return option.label.toUpperCase().includes(inputValue.toUpperCase());
  };

  const membershipOptions = useCallback(() => {
    return generateMembershipOptions(memberships);
  }, [memberships]);

  return (
    <MembershipContainer>
      <Select<MembershipSelectorOptionType, false, GroupBase<MembershipSelectorOptionType>>
        onChange={handleSelect}
        isClearable={false}
        isSearchable={true}
        filterOption={filterOption}
        options={membershipOptions()}
        value={defaultValue}
        styles={getComponentsStyles()}
        components={{
          Control: MembershipSelectorControl,
          Option: MembershipSelectorOption,
          MenuList: MembershipSelectorMenuList,
        }}
      />
    </MembershipContainer>
  );
};
