import 'styled-components/macro';

import { ActionMeta, GroupBase, Options, StylesConfig } from 'react-select';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';

import Async from 'react-select/async';
import AsyncSelectInputProps from './@types/AsyncSelectInputProps';
import { Colors } from '../../theme/colors';
import Container from '../Container';
import FormContext from '../../contexts/form.context';
import { InputError } from '../InputError/InputError';
import SelectOption from '../../@types/react/SelectOption';
import Theme from '../../theme';
import pull from 'lodash/pull';
import uniqBy from 'lodash/uniqBy';

const AsyncSelectInput: React.FC<AsyncSelectInputProps> = ({
  className = '',
  isEditable = true,
  isMulti = false,
  loadOptions,
  name,
  required,
  onChange = (): void => {},
  placeholder,
  displayError = false,
}: AsyncSelectInputProps) => {
  const { identifier } = useContext(FormContext);
  const [searchInput, setSearchInput] = useState<string>('');
  const { clearErrors, register, setValue, getValues, setError, errors } = useFormContext();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const currentValue = useMemo((): SelectOption[] => {
    const value = getValues(name);
    if (isMulti) {
      return [...(value ?? [])];
    }

    return value ?? [];
  }, [getValues(name)]);
  const isEditForm = Boolean(identifier);
  const isDisabled: boolean = isEditForm ? !isEditable : false;

  const errorMessage = 'Required field';

  const AsyncSelectInputStyles: Partial<StylesConfig<
    SelectOption,
    typeof isMulti,
    GroupBase<SelectOption>
  >> = {
    control: () => ({
      background: isDisabled ? Colors.mountainMeadow : 'rgba(209,210,214,.2)',
      border: className === 'error' ? `2px solid ${Colors.salmon}` : '',
      borderRadius: '8px',
      color: isDisabled ? Colors.white : 'initial',
      cursor: isDisabled ? 'initial' : 'pointer',
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      minHeight: '40px',
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      ...(isDisabled && { display: 'none' }),
    }),
    singleValue: () => ({
      gridArea: '1 / 1 / 2 / 3',
      color: isDisabled ? Colors.white : Colors.blackRussian,
      fontSize: Theme.fontSizes.bodyLarge,
      fontWeight: 'bold',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  const clearError = (): void => {
    clearErrors(name);
  };

  const validateField = (): void => {
    const value = getValues(name);

    if (required && (!value || value.length === 0)) {
      setError(`${name}.value`, { message: errorMessage });
    } else {
      clearError();
    }
  };

  function handleChange(
    selection: SelectOption | Options<SelectOption> | null,
    action: ActionMeta<SelectOption>
  ): void {
    clearError();

    switch (action.action) {
      case 'clear': {
        if (isMulti) {
          setValue(name, []);
        } else {
          setValue(name, undefined);
        }
        break;
      }

      case 'select-option': {
        if (action.option) {
          if (isMulti) {
            const newValue = uniqBy((currentValue ?? []).concat(action.option), 'value');
            setValue(name, newValue);
          } else {
            setValue(name, action.option);
          }
        } else if (!isMulti) {
          setValue(name, selection);
        }

        break;
      }

      case 'remove-value': {
        if (isMulti) {
          const newValue = pull(currentValue, action.removedValue);
          setValue(name, newValue);
        } else {
          setValue(name, undefined);
        }

        break;
      }

      case 'pop-value': {
        if (isMulti) {
          const newValue = currentValue.slice(0, currentValue.length - 1);
          setValue(name, newValue);
        } else {
          setValue(name, undefined);
        }

        break;
      }

      default: {
        break;
      }
    }

    onChange(selection, action);
    validateField();
  }

  function handleFocus(): void {
    loadOptions('').then((loadedOptions) => setOptions(loadedOptions));
  }

  function handleInputChange(newValue: string): string {
    validateField();

    setSearchInput(newValue);
    return newValue;
  }

  useEffect(() => {
    register(name);
  });

  function handleBlur(): void {
    validateField();
  }

  const error = get(errors, name);

  return (
    <Container className={className}>
      <Async<SelectOption, typeof isMulti>
        backspaceRemovesValue
        cacheOptions
        defaultOptions={options}
        inputValue={searchInput}
        isClearable
        isDisabled={isDisabled}
        isMulti={isMulti}
        loadOptions={loadOptions}
        onChange={handleChange}
        onFocus={handleFocus}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        options={options}
        styles={AsyncSelectInputStyles}
        value={currentValue}
        classNamePrefix="AsyncSelectInputStyle"
        menuPortalTarget={document.body}
        placeholder={placeholder}
      />
      {displayError && error?.message && <InputError error={error.message} />}
    </Container>
  );
};

export default AsyncSelectInput;
