import * as yup from 'yup';

import {
  UserFormInputsNames,
  UserFormMembershipType,
  UserFormMembershipTypeEnum,
} from '../@types/UserFormInputs';

import BASE_USER_VALIDATION_SCHEMA from './baseUserValidationSchema';
import { REQUIRED_PASSWORD_VALIDATION_SCHEMA } from './passwordValidationSchema';
import { Role } from '../../../__generated__/graphql';
import SELECT_OPTION from '../../../constants/yupValidations/selectOption';

const membershipSchema = yup.object().shape({
  map: yup.lazy(() =>
    yup.object().shape({
      option: yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
      shouldCreate: yup.boolean(),
      shouldDelete: yup.boolean(),
      role: yup.mixed<Role>().oneOf(Object.values(Role)),
      type: yup.mixed<UserFormMembershipType>().oneOf(Object.values(UserFormMembershipTypeEnum)),
    })
  ),
  values: yup.array(SELECT_OPTION),
});

const USER_VALIDATION_SCHEMA = BASE_USER_VALIDATION_SCHEMA.concat(
  REQUIRED_PASSWORD_VALIDATION_SCHEMA
).concat(
  yup.object().shape({
    [UserFormInputsNames.locationsMembership]: membershipSchema,
    [UserFormInputsNames.groupsMembership]: membershipSchema,
  })
);

export default USER_VALIDATION_SCHEMA;
