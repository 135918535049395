import { ActiveMembership } from '../../redux/reducers/@types/MeReduxState';
import { Maybe } from '../../__generated__/graphql';
import { MembershipAccessLevelEnum } from '../membership/types';
import { getMembershipAccessLevel } from '../membership/membershipUtils';

export interface ObjectMembership {
  tenantId: string;
  locationId?: Maybe<string>;
}
export interface ValidateAccessByMembershipProps {
  membership: ActiveMembership;
  objectMembership: ObjectMembership;
  validateLocation: boolean;
}

export const validateAccessByMembership = ({
  membership,
  objectMembership,
  validateLocation,
}: ValidateAccessByMembershipProps): void => {
  if (membership.tenant.id !== objectMembership.tenantId) {
    throw new Error('Invalid membership');
  }

  if (validateLocation && objectMembership.locationId) {
    const membershipAccessLevel = getMembershipAccessLevel(membership);

    if (membershipAccessLevel === MembershipAccessLevelEnum.location) {
      if (membership.location?.id !== objectMembership.locationId) {
        throw new Error('Invalid membership');
      }
    } else if (membershipAccessLevel === MembershipAccessLevelEnum.locationGroup) {
      const locationsIds = membership.locationGroup?.locationIds ?? [];
      const membershipFound = locationsIds.indexOf(objectMembership.locationId) > -1;

      if (!membershipFound) {
        throw new Error('Invalid membership');
      }
    }
  }
};
