import { LocationIcon, LocationInfo, LocationsGroupData } from '../LocationsGroupsTreeStyles';

import { Link } from 'react-router-dom';
import { LocationType } from '../../../hooks/useLocationGroupsTree';
import React from 'react';
import TableRow from '../../../../../../../components/DataTable/components/TableRow';
import links from '../../../../../../../util/links';

type LocationComponentProps = {
  location: LocationType;
  level: number;
};

export const LocationComponent: React.FC<LocationComponentProps> = ({
  level,
  location,
}: LocationComponentProps) => {
  return (
    <TableRow>
      <LocationsGroupData level={level}>
        <Link to={`${links.paths.LOCATIONS}/${location.id}`}>
          <LocationInfo>
            <LocationIcon />
            {location.name}
          </LocationInfo>
        </Link>
      </LocationsGroupData>
    </TableRow>
  );
};
