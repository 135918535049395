import 'styled-components/macro';
import Checkmark from './components/Checkmark';
import { Colors } from '../../theme/colors';
import Container from '../Container';
import HiddenCheckbox from './components/HiddenCheckbox';
import React from 'react';
import StyledCheckbox from './components/StyledCheckbox';
import TextContent from '../TextContent';
import Theme from '../../theme';

export enum CheckboxModel {
  TABLE_DARK = 'checkbox__table_dark',
  TABLE_BLUE = 'checkbox__table_blue',
}

export const CHECKBOX_CSS_PROPERTY_MAP = {
  [CheckboxModel.TABLE_DARK]: {
    checked: {
      background: Colors.slateBlue,
      border: '0',
      color: Theme.colors.white.hexCode,
    },
    unchecked: {
      background: 'transparent',
      border: `2px solid ${Theme.colors.borderGrey.hexCode}`,
      color: Theme.colors.white.hexCode,
    },
  },
};

export interface CheckboxProps {
  background?: string;
  checked: boolean;
  disabled?: boolean;
  fill?: string;
  label?: string;
  model: keyof typeof CHECKBOX_CSS_PROPERTY_MAP;
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  stroke?: string;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  checked,
  disabled = false,
  label,
  model,
  onClick,
  ...props
}: CheckboxProps) => {
  return (
    <Container
      css={`
        align-content: center;
        align-items: center;
        grid-auto-flow: column;
        grid-column-gap: 10px;
        grid-template-columns: max-content 1fr;
      `}
    >
      <Container background="transparent" className={model}>
        <HiddenCheckbox checked={checked} readOnly type="checkbox" />
        <StyledCheckbox
          checked={checked}
          disabled={disabled}
          model={model}
          onClick={onClick}
          {...props}
        >
          <Checkmark />
        </StyledCheckbox>
      </Container>
      {Boolean(label) && <TextContent>{label}</TextContent>}
    </Container>
  );
};

export default Checkbox;
