import { gql } from '../../../../../__generated__/gql';

export const GET_LOCATION_GROUP_NODES = gql(`
  query GetLocationGroupNodes($tenantId: String!) {
    getLocationGroupNodes(tenantId: $tenantId) {
      id
      locations {
        id
        name
      }
      name
      parentGroupIds
    }
  }
`);
