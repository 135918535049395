import { gql } from '../../../__generated__/gql';

export const GET_LOCATION_GROUP = gql(`
  query GetLocationGroup($locationGroupId: String!) {
    getLocationGroup(locationGroupId: $locationGroupId) {
      id
      name
      groups {
        id
        name
      }
      locations {
        id
        name
      }
    }
  }
`);
