/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { GroupBase, StylesConfig } from 'react-select';

import Container from '../../../../Container';
import { MembershipSelectorOptionType } from '../types';
import Theme from '../../../../../theme';
import { membershipGroupsLabel } from '../functions/generateMembershipOptions';
import styled from 'styled-components';

export const MembershipContainer = styled(Container)`
  grid-column: 2;
  justify-self: end;
`;

export const getComponentsStyles = (): Partial<
  StylesConfig<MembershipSelectorOptionType, false, GroupBase<MembershipSelectorOptionType>>
> => {
  return {
    container: (baseStyles) => {
      return {
        ...baseStyles,
        minWidth: '320px',
        width: 'max-content',
      };
    },
    control: (baseStyles) => {
      return {
        ...baseStyles,
        padding: '0px 12px 0px 16px',
      };
    },
    menu: (baseStyles) => {
      return {
        ...baseStyles,
        maxWidth: '600px',
        minWidth: '400px',
        width: 'max-content',
        right: 0,
        borderRadius: '12px',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.10)',
      };
    },
    menuList: (baseStyles) => ({
      ...baseStyles,
      width: '100%',
      maxHeight: '90vh',
      display: 'flex',
      flexWrap: 'wrap',
    }),
    group: (_, state) => {
      const groupLabel = state.label?.toString() ?? '';

      const isTenantRow = groupLabel === membershipGroupsLabel.tenant;

      return {
        padding: '12px',
        paddingTop: 0,
        flex: `1 1 ${isTenantRow ? '100%' : '50%'}`,
      };
    },
    groupHeading: () => ({
      textTransform: 'uppercase',
      lineHeight: 'normal',
      letterSpacing: '0.5px',
      fontWeight: 600,
      color: '#000',
      fontSize: Theme.fontSizes.label,
      padding: '12px',
    }),
    option: () => ({
      padding: 0,
    }),
  };
};
