import { ActionMeta, Options } from 'react-select';
import {
  UserFormInputs,
  UserFormInputsNames,
  UserFormMembershipType,
} from '../@types/UserFormInputs';
import UserFormMembershipMap, { UserFormMembership } from '../@types/UserFormMembershipMap';

import { Role } from '../../../__generated__/graphql';
import SelectOption from '../../../@types/react/SelectOption';
import { useFormContext } from 'react-hook-form';

function isSelectOption(option: SelectOption | Options<SelectOption>): option is SelectOption {
  return Boolean((option as SelectOption).value);
}

type UseAdjustMembershipsMapType = {
  adjustMembershipRole: (option: SelectOption | Options<SelectOption> | null) => void;
  adjustMembershipsMap: (
    option: SelectOption | Options<SelectOption> | null,
    action: ActionMeta<SelectOption>
  ) => void;
};

type UseAdjustMembershipsMapProps = {
  fieldName: UserFormMembershipType;
};

export default function useAdjustMembershipsMap({
  fieldName,
}: UseAdjustMembershipsMapProps): UseAdjustMembershipsMapType {
  const { getValues, setValue: setFormValue } = useFormContext<UserFormInputs>();
  const membershipsMappingFieldName = `${fieldName}.${UserFormInputsNames.map}`;

  const setValue = (entityId: string, newValue: UserFormMembership): void => {
    const currentMap: UserFormMembershipMap = getValues(membershipsMappingFieldName);

    setFormValue(membershipsMappingFieldName, {
      ...currentMap,
      [entityId]: newValue,
    });
  };

  const adjustMembershipRole = (option: SelectOption | Options<SelectOption> | null): void => {
    if (option && isSelectOption(option)) {
      const membershipsMap: UserFormMembershipMap = getValues(membershipsMappingFieldName);
      const [role, entityId] = option.value.split('.');
      const currentMembership = membershipsMap[entityId];

      setValue(entityId, {
        ...currentMembership,
        ...(currentMembership.membershipId && { shouldUpdate: true }),
        role: role as Role,
      });
    }
  };

  const adjustMembershipsMap = (
    option: SelectOption | Options<SelectOption> | null,
    action: ActionMeta<SelectOption>
  ): void => {
    const currentMap: UserFormMembershipMap = getValues(membershipsMappingFieldName);

    if (action.action === 'select-option') {
      const currentOption = currentMap[action.option?.value ?? ''];

      if (currentOption && currentOption.membershipId) {
        setValue(currentOption.option.value, {
          ...currentOption,
          shouldDelete: false,
        });
      } else if (currentOption) {
        setValue(currentOption.option.value, {
          ...currentOption,
          shouldCreate: true,
        });
      } else if (action.option?.value) {
        setValue(action.option.value, {
          option: action.option,
          shouldCreate: true,
          shouldDelete: false,
          shouldUpdate: false,
          role: Role.User,
          type: fieldName,
        });
      }
    } else if (action.action === 'remove-value') {
      const currentOption = currentMap[action.removedValue.value];

      if (!currentOption.shouldCreate) {
        setValue(currentOption.option.value, {
          ...currentOption,
          shouldDelete: true,
        });
      } else {
        setValue(currentOption.option.value, {
          ...currentOption,
          shouldCreate: false,
        });
      }
    } else if (action.action === 'pop-value') {
      const { removedValue } = action;

      if (removedValue) {
        const currentOption = currentMap[removedValue.value];

        if (!currentOption.shouldCreate) {
          setValue(removedValue.value, {
            ...currentOption,
            shouldDelete: true,
          });
        } else {
          setValue(removedValue.value, {
            ...currentOption,
            shouldCreate: false,
          });
        }
      }
    }
  };

  return {
    adjustMembershipRole,
    adjustMembershipsMap,
  };
}
