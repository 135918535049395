import 'styled-components/macro';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import BatteryIcon from '../../assets/icons/battery';
import Button from '../Button';
import ButtonModel from '../Button/enums/ButtonModel.enum';
import Container from '../Container';
import HouseIcon from '../../assets/icons/house';
import { ReactComponent as Logo } from '../../assets/logo/virdee_logo.svg';
import LogoContainerStyle from './styles/LogoContainer.style';
import LogoutContainerStyle from './styles/LogoutContainer.style';
import LogoutIcon from '../../assets/icons/logout';
import MarkerIcon from '../../assets/icons/LocationIcon';
import { MdAssignment } from 'react-icons/md';
import NavContainerStyle from './styles/NavContainer.style';
import NavigationButton from './components/NavigationButton';
import React from 'react';
import { RootState } from '../../redux/reducers';
import ShareIcon from '../../assets/icons/share';
import SidebarContainerStyle from './styles/SidebarContainer.style';
import TenantIcon from '../../assets/icons/TenantIcon';
import UserIcon from '../../assets/icons/user';
import WarningIcon from '../../assets/icons/warning';
import { determineNavButtonType } from './functions';
import { isFullAccessMembership } from '../../util/membership/membershipUtils';
import links from '../../util/links';
import { logout } from '../../redux/actions/me.actions';
import useEndCall from '../../hooks/useEndCall';

const Sidebar: React.FunctionComponent<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeMembership, id: userId } = useSelector((state: RootState) => state.me);
  const activeCall = useSelector((state: RootState) => state.remoteAssistance.activeCall);
  const { pathname } = location;

  const { endCall } = useEndCall();

  function handleLogout(): void {
    if (activeCall?.callRecordId) {
      endCall({ callRecordId: activeCall.callRecordId, userId: userId! });
    }

    history.replace({ ...history.location, state: { avoidRedirectAfterLogin: true } });
    dispatch(logout());
  }

  function navigate(e: React.SyntheticEvent<HTMLButtonElement>): void {
    const path = e.currentTarget.getAttribute('data-path');

    if (path) {
      history.push(path);
    }
  }

  return (
    <Container alignContent="start" as="nav" css={SidebarContainerStyle} justify="center">
      <Container css={LogoContainerStyle}>
        <Logo />
      </Container>
      <Container alignContent="center" css={NavContainerStyle} justify="center" rowGap="30px">
        <NavigationButton
          data-path={links.paths.HOME}
          disabled={pathname === links.paths.HOME}
          labelText="Home"
          model={determineNavButtonType(links.paths.HOME, pathname)}
          onClick={navigate}
        >
          <HouseIcon />
        </NavigationButton>
        <NavigationButton
          data-path={links.paths.REMOTE_ASSISTANCE}
          disabled={pathname === links.paths.REMOTE_ASSISTANCE}
          labelText="Assistance"
          model={determineNavButtonType(links.paths.REMOTE_ASSISTANCE, pathname)}
          onClick={navigate}
        >
          <WarningIcon />
        </NavigationButton>
        <NavigationButton
          data-path={links.paths.ACCESS_GRANTS}
          disabled={pathname === links.paths.ACCESS_GRANTS}
          labelText="Access"
          model={determineNavButtonType(links.paths.ACCESS_GRANTS, pathname)}
          onClick={navigate}
        >
          <BatteryIcon />
        </NavigationButton>
        {!activeMembership.location?.id && (
          <NavigationButton
            data-path={links.paths.LOCATIONS}
            disabled={pathname === links.paths.LOCATIONS}
            labelText="Locations"
            model={determineNavButtonType(links.paths.LOCATIONS, pathname)}
            onClick={navigate}
          >
            <MarkerIcon />
          </NavigationButton>
        )}
        {Boolean(activeMembership.location?.id) && (
          <NavigationButton
            data-path={`${links.paths.LOCATIONS}/${activeMembership.location?.id}`}
            disabled={pathname === `${links.paths.LOCATIONS}/${activeMembership.location?.id}`}
            labelText={activeMembership.location?.name ?? 'Location'}
            model={determineNavButtonType(
              `${links.paths.LOCATIONS}/${activeMembership.location?.id}`,
              pathname
            )}
            onClick={navigate}
          >
            <MarkerIcon />
          </NavigationButton>
        )}
        <NavigationButton
          data-path={links.paths.REPORTING}
          disabled={pathname === links.paths.REPORTING}
          labelText="Reporting"
          model={determineNavButtonType(links.paths.REPORTING, pathname)}
          onClick={navigate}
        >
          <MdAssignment />
        </NavigationButton>
        <NavigationButton
          data-path={links.paths.KIOSKS}
          disabled={pathname === links.paths.KIOSKS}
          labelText="Kiosks"
          model={determineNavButtonType(links.paths.KIOSKS, pathname)}
          onClick={navigate}
        >
          <ShareIcon />
        </NavigationButton>
        <NavigationButton
          data-path={links.paths.USERS}
          disabled={pathname === links.paths.USERS}
          labelText="Users"
          model={determineNavButtonType(links.paths.USERS, pathname)}
          onClick={navigate}
        >
          <UserIcon />
        </NavigationButton>
        {isFullAccessMembership(activeMembership) && (
          <NavigationButton
            data-path={`${links.paths.TENANT}/${activeMembership.tenantId}`}
            disabled={pathname === `${links.paths.TENANT}/${activeMembership.tenantId}`}
            labelText={activeMembership.tenant.name ?? 'Tenant'}
            model={determineNavButtonType(
              `${links.paths.TENANT}/${activeMembership.tenantId}`,
              pathname
            )}
            onClick={navigate}
          >
            <TenantIcon />
          </NavigationButton>
        )}
      </Container>
      <Container css={LogoutContainerStyle} justify="center">
        <Button model={ButtonModel.PASSIVE_ICON_BUTTON} onClick={handleLogout}>
          <LogoutIcon />
        </Button>
      </Container>
    </Container>
  );
};

export default Sidebar;
