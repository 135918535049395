import useLoadLocationOptions, {
  UseLoadLocationsOptionsType,
} from '../../../hooks/useLoadLocationOptions';

import { LocationsGroupChildrenTypeEnum } from '../constants/locationsGroupFormInputs';
import { LocationsGroupFormInputsType } from '../@types/LocationsGroupFormInputsType';
import SelectOption from '../../../@types/react/SelectOption';
import { useFormContext } from 'react-hook-form';

export const useLoadNotSelectedLocationsOptions = (): UseLoadLocationsOptionsType => {
  const { getValues } = useFormContext<LocationsGroupFormInputsType>();

  const selectedLocations = getValues(
    `${LocationsGroupChildrenTypeEnum.locations}.values`
  ) as SelectOption[];
  const selectedLocationsIds = selectedLocations?.map((l) => l.value) ?? [];

  return useLoadLocationOptions({ locationsFilteredOut: selectedLocationsIds });
};
