import { ActionMeta, Options } from 'react-select';

import FormResourceMap from '../../@types/react/FormResourceMap';
import SelectOption from '../../@types/react/SelectOption';
import { useFormContext } from 'react-hook-form';

export default function useAdjustResourcesMap(
  mapName?: string
): {
  adjustResourcesMap: (
    option: SelectOption | Options<SelectOption> | null,
    action: ActionMeta<SelectOption>
  ) => void;
} {
  const { getValues, setValue } = useFormContext();

  return {
    adjustResourcesMap(
      option: SelectOption | Options<SelectOption> | null,
      action: ActionMeta<SelectOption>
    ): void {
      const MAP_NAME = mapName || 'resources.map';

      const currentMap: FormResourceMap = getValues(MAP_NAME);

      if (action.action === 'select-option') {
        const currentOption = currentMap[action.option?.value ?? ''];

        if (currentOption) {
          setValue(MAP_NAME, {
            ...currentMap,
            [currentOption.option.value]: {
              ...currentOption,
              shouldDisconnect: false,
            },
          });
        } else if (action.option?.value) {
          setValue(MAP_NAME, {
            ...currentMap,
            [action.option.value]: {
              isConnected: false,
              option: action.option,
              shouldConnect: true,
              shouldDisconnect: false,
            },
          });
        }
      } else if (action.action === 'remove-value') {
        const currentOption = currentMap[action.removedValue.value];

        if (currentOption.isConnected) {
          setValue(MAP_NAME, {
            ...currentMap,
            [currentOption.option.value]: {
              ...currentOption,
              shouldConnect: false,
              shouldDisconnect: true,
            },
          });
        } else {
          setValue(MAP_NAME, {
            ...currentMap,
            [currentOption.option.value]: {
              ...currentOption,
              shouldConnect: false,
            },
          });
        }
      } else if (action.action === 'pop-value') {
        const VALUES_NAME = 'resources.values';
        const values = getValues(VALUES_NAME);
        const lastValue = values[values.length - 1];
        const currentOption = currentMap[lastValue.value];

        if (currentOption.isConnected) {
          setValue(MAP_NAME, {
            ...currentMap,
            [lastValue.value]: {
              ...currentOption,
              shouldConnect: false,
              shouldDisconnect: true,
            },
          });
        } else {
          setValue(MAP_NAME, {
            ...currentMap,
            [lastValue.value]: {
              ...currentOption,
              shouldConnect: false,
            },
          });
        }
      }
    },
  };
}
